import React, { useState } from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import { ReactComponent as ColorDotIcon } from "../../assets/images/traffic-light-dot.svg";
import ReactCardFlip from "react-card-flip";
import numeral from "numeral";

const TrafficLight = ({
  position,
  expectedTotalUnitsSold,
  expectedTotalUnitsSoldStr,
  totalUnitsSold,
  totalUnitsSoldStr,
  expectedTotalSales,
  expectedTotalSalesStr,
  totalSales,
  totalSalesStr,
  expectedTotalBenefits,
  totalBenefits,
  colorBenefits,
  colorSales,
  colorUnits,
}) => {
  const theme = useTheme();
  const [isFlipped, setIsFlipped] = useState(false);
  const colors = {
    // red: "#fa0909",
    red: theme.palette.alerts.error.main,
    // green: "#00a87c",
    green: theme.palette.alerts.good.main,
    // yellow: "#e6a800",
    yellow: theme.palette.alerts.warning.main,
  };

  const CardFront = () => {
    const theme = useTheme();
    return (
      <div style={{ padding: 0, boxShadow: "none" }}>
        <Grid onClick={() => setIsFlipped(!isFlipped)} container>
          <Grid
            item
            xs={12}
            sx={{
              marginInline: "1rem",
              marginBlockStart: "0.5rem",
              display: "flex",
              borderRadius: "0.5rem",
              width: "350px",
              paddingInlineEnd: "0.5rem",
              paddingInlineStart: "0.2rem",
              backgroundColor: theme.palette.background.paper,
              height: "3rem",
              boxShadow: "0 4px 4px 0 rgba(0,0,0,.2)",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                paddingInlineStart: "1rem",
              }}
              item
              xs={5}
            >
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                  color: theme.palette.title.main,
                  // color: theme.palette.divider.main,
                }}
              >
                {position}
              </Typography>
            </Grid>

            <>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                xs={7 / 3}
              >
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    color: colors[colorUnits],
                  }}
                >
                  <ColorDotIcon style={{ fill: colors[colorUnits] }} />
                </Typography>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                item
                xs={7 / 3}
              >
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    color: colors[colorSales],
                  }}
                >
                  <ColorDotIcon style={{ fill: colors[colorSales] }} />
                </Typography>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                item
                xs={7 / 3}
              >
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    color: colors[colorBenefits],
                  }}
                >
                  <ColorDotIcon style={{ fill: colors[colorBenefits] }} />
                </Typography>
              </Grid>
            </>
          </Grid>
        </Grid>
      </div>
    );
  };
  const CardBack = () => {
    const theme = useTheme();
    return (
      <div style={{ padding: 0, boxShadow: "none" }}>
        <Grid onClick={() => setIsFlipped(!isFlipped)} container>
          <Grid
            item
            xs={12}
            sx={{
              marginInline: "1rem",
              marginBlockStart: "0.5rem",
              borderRadius: "0.5rem",
              display: "flex",
              width: "350px",
              paddingInlineEnd: "0.5rem",
              paddingInlineStart: "0.2rem",
              backgroundColor: theme.palette.background.paper,
              height: "3rem",
              boxShadow: "0 4px 4px 0 rgba(0,0,0,.2)",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                paddingInlineStart: "1rem",
              }}
              item
              xs={5}
            >
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                  color: theme.palette.title.main,
                }}
              >
                {position}
              </Typography>
            </Grid>

            <>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                xs={7 / 3}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    color: colors[colorUnits],
                  }}
                >
                  {`${totalUnitsSoldStr}/${expectedTotalUnitsSoldStr}`}
                </Typography>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                item
                xs={7 / 3}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    color: colors[colorSales],
                  }}
                >
                  {`${totalSalesStr}/${expectedTotalSalesStr} €`}
                </Typography>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                item
                xs={7 / 3}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    color: colors[colorBenefits],
                  }}
                >
                  {`${numeral(totalBenefits)
                    .format(totalBenefits % 1 === 0 ? "0a" : "0.[0]a")
                    .replace(".", ",")}/${numeral(expectedTotalBenefits)
                    .format(expectedTotalBenefits % 1 === 0 ? "0a" : "0.[0]a")
                    .replace(".", ",")} %`}
                </Typography>
              </Grid>
            </>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
      <CardFront />
      <CardBack />
    </ReactCardFlip>
  );
};

export default TrafficLight;
