import React from "react";
import { Grid, Paper, Typography, useTheme } from "@mui/material";
import { ReactComponent as TrendLineChartIcon } from "../../assets/images/trend-line-chart.svg";
import { ReactComponent as ArrowBigGreen } from "../../assets/images/arrow-green-big.svg";
import { ReactComponent as ArrowBigRed } from "../../assets/images/arrow-red-big.svg";
import { ReactComponent as ExperimentsIcon } from "../../assets/images/experiments-icon.svg";
import { ReactComponent as ShoppingCartIcon } from "../../assets/images/shopping-cart-icon.svg";
import FormatPeriod from "../../utils/formatPeriod";
import numeral from "numeral";

//numeral library locale configuration
numeral.register("locale", "es", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "k",
    million: "M",
    billion: "b",
    trillion: "t",
  },
  ordinal: function (number) {
    return number === 1 ? "er" : "º";
  },
  currency: {
    symbol: "€",
  },
});

numeral.locale("es");
//

const BigCard = ({
  metricsData,
  bigCardData,
  period,
  setPeriod,
  logo,
  experimentsPage,
  sprintProgress,
  slideIndex,
}) => {
  const theme = useTheme();

  const slideIndexToCurrencyContent = {
    0: metricsData?.currency?.symbol,
    1: "u",
    2: metricsData?.currency?.symbol,
  };

  // ?? (nullish coalescing operator ECMAScript 2020)
  const currencyContent = slideIndexToCurrencyContent[slideIndex] ?? null;

  const percentage = Math.round(
    parseFloat(metricsData?.metrics?.[slideIndex]?.differenceStr)
  );

  return (
    <Paper
      elevation={5}
      sx={{
        display: "flex",
        borderRadius: "10px",
        minWidth: "275px",
        maxWidth: "500px",
        marginInline: "1rem",
        marginBlockStart: "1rem",
        marginBlockEnd: "2rem",
      }}
    >
      <Grid container>
        <Grid
          container
          sx={{
            backgroundColor: experimentsPage ? "#00A87C" : "#663B73",
            borderRadius: "10px 10px 0px 0px",
            padding: "0.5rem",
            display: "flex",
          }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#FCFCF7",
                fontSize: "24px",
              }}
            >
              {bigCardData.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: "normal",
                color: "#A9A9A9",
                fontSize: "18px",
              }}
            >
              {bigCardData.subtitle}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            padding: "0.5rem 2rem",
            display: "flex",
            marginBlockEnd: "2rem",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              height: "3rem",
            }}
          >
            <Grid
              item
              xs={2}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {logo === "trendlinechart" && (
                <TrendLineChartIcon
                  fill={theme.palette.icons.main}
                  style={{ height: "32px" }}
                />
              )}
              {logo === "shopcart" && (
                <ShoppingCartIcon
                  fill={theme.palette.icons.main}
                  sx={{ fontSize: "32px" }}
                />
              )}
              {logo === "experiment" && (
                <ExperimentsIcon
                  fill={theme.palette.icons.main}
                  style={{ width: "23px" }}
                />
              )}
            </Grid>
            <Grid
              item
              xs={8}
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "255px",
                boxSizing: "border-box",
              }}
            >
              <Typography noWrap sx={{ fontSize: "1.25rem" }}>
                {bigCardData.headline}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              display: "flex",
              height: "6rem",
            }}
          >
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {metricsData?.metrics?.[slideIndex]?.difference < 0 ? (
                <ArrowBigRed />
              ) : (
                <ArrowBigGreen />
              )}
            </Grid>
            <Grid
              item
              xs={8}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <Typography
                variant="h3"
                noWrap
                sx={{
                  overflow: "hidden",
                  lineHeight: "3rem",
                }}
              >
                {numeral(metricsData?.metrics?.[slideIndex]?.total).format(
                  metricsData?.metrics?.[slideIndex]?.total >= 10000
                    ? metricsData?.metrics?.[slideIndex]?.total % 1 === 0
                      ? "0a"
                      : "0.[0]a"
                    : null
                )}
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.8rem",
                  lineHeight: "1.8rem",
                  marginTop: "1rem",
                }}
              >
                {currencyContent}
              </Typography>
            </Grid>
            {!experimentsPage && (
              <Grid
                item
                xs={2}
                sx={{
                  color: "#fff",
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    backgroundColor: `${
                      metricsData?.metrics?.[slideIndex]?.difference < 0
                        ? "#FD3F03"
                        : "#2CAF5F"
                    }`,
                    borderRadius: "3px",
                    padding: "5px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {`${numeral(percentage).format("0a")}%`}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            minHeight: "35px",
            backgroundColor: experimentsPage ? "#00A87C" : "#663B73",
            borderRadius: "0px 0px 10px 10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {metricsData?.period?.current?.startDate && (
            <Typography color="#A9A9A9">
              <FormatPeriod
                period={period}
                startDate={metricsData.period.current.startDate}
                endDate={metricsData.period.current.endDate}
              />
            </Typography>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BigCard;
