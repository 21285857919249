import { Grid, Paper } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import TrelloSection from "./trello-section/trello-section.component";
import { filterByDateRange } from "../../../../utils/filterByDateRange";

const ExperimentsManagementBoard = ({
  runningExperiments,
  finishedExperiments,
  pendingExperiments,
  incomingExperiments,
  dateFilterValues,
}) => {
  const [allExperiments, setAllExperiments] = useState({
    running: runningExperiments,
    finished: finishedExperiments,
    pending: pendingExperiments,
    incoming: incomingExperiments,
  });

  const filteredExperiments = useMemo(() => {
    const [startDate, endDate] = dateFilterValues.map((date) => date?.toDate());
    return {
      running: filterByDateRange(allExperiments.running, startDate, endDate),
      finished: filterByDateRange(allExperiments.finished, startDate, endDate),
      pending: filterByDateRange(allExperiments.pending, startDate, endDate),
      incoming: filterByDateRange(allExperiments.incoming, startDate, endDate),
    };
  }, [allExperiments, dateFilterValues]);

  useEffect(() => {
    setAllExperiments({
      running: runningExperiments,
      finished: finishedExperiments,
      pending: pendingExperiments,
      incoming: incomingExperiments,
    });
  }, [
    runningExperiments,
    finishedExperiments,
    pendingExperiments,
    incomingExperiments,
  ]);

  return (
    <Grid
      container
      sx={{
        marginBlock: "1rem",
        justifyContent: "center",
        minHeight: "65vh",
        paddingInline: "1rem",
      }}
    >
      <Grid
        component={Paper}
        elevation={2}
        item
        xs={12}
        sx={{ display: "flex", padding: "1rem", gap: "1rem" }}
      >
        <TrelloSection
          experimentData={filteredExperiments.pending}
          title="Pendientes"
        />
        <TrelloSection
          experimentData={filteredExperiments.running}
          title="Iniciados"
        />
        <TrelloSection
          experimentData={filteredExperiments.finished}
          title="Finalizados"
        />
        <TrelloSection
          experimentData={filteredExperiments.incoming}
          title="Futuros"
        />
      </Grid>
    </Grid>
  );
};

export default ExperimentsManagementBoard;
