import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import React from "react";
import { useNavigate } from "react-router-dom";

const AccordionItem = ({
  element,
  index,
  activeOptionId,
  activeSubsectionId,
  expanded,
  handleChange,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <ListItem
      // onClick={() => navigate(element.url)}
      sx={{
        ":hover": { backgroundColor: theme.palette.action.hover },
        backgroundColor:
          index === activeOptionId
            ? // ? "rgba(0, 0, 0, 0.1)"
              theme.palette.action.selected
            : "transparent",
      }}
      disablePadding
    >
      <Accordion
        sx={{
          backgroundColor: "transparent",
          width: "100%",
        }}
        elevation={0}
        disableGutters
        expanded={expanded === `panel${index}`}
        onChange={handleChange(`panel${index}`)}
      >
        <AccordionSummary
          sx={{
            padding: "0 !important",
            "& .MuiAccordionSummary-content": {
              margin: "0 !important",
            },
          }}
          expandIcon={<ExpandMoreIcon />}
        >
          <ListItemButton
            sx={{
              ":hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <ListItemIcon>{element.icon}</ListItemIcon>
            <ListItemText primary={element.label} />
          </ListItemButton>
        </AccordionSummary>

        <AccordionDetails sx={{ padding: 0 }}>
          {element?.childs.map((child, index) => {
            return (
              <ListItem
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(child.url);
                }}
                sx={{
                  ":hover": { backgroundColor: theme.palette.action.hover },
                  backgroundColor:
                    index === activeSubsectionId
                      ? // ? "rgba(0, 0, 0, 0.1)"
                        theme.palette.action.selected
                      : "transparent",
                }}
                key={`child-${child.title}`}
                disablePadding
                component="div"
              >
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: 0 }}>
                    <ArrowRightIcon
                      sx={{
                        fill: theme.palette.divider.main,
                        transitionDuration: "0ms",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ paddingInlineStart: "2rem" }}
                    primary={child.title}
                    primaryTypographyProps={{ fontSize: "0.9rem" }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </ListItem>
  );
};

export default AccordionItem;
