import { useTheme } from "@mui/material";
import React from "react";

export default function PercentageRender({ value }) {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "70%",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          backgroundColor: value[0] < "50.00" ? "#DA3518AA" : "#44CD32AA",
          borderRadius: "1rem",
          display: "flex",
          width: "auto",
          justifyContent: "center",
          alignItems: "center",
          paddingInline: "0.5rem",
          color: theme.palette.table.text,
        }}
      >
        {value}
      </div>
    </div>
  );
}
