import { useTheme } from "@mui/material";
import React from "react";

export default function ExperimentTypeRender({ value }) {
  let labelColor = {
    "Promo Prec. Unit.": "#9ACD32AA",
    "Promo Comb. Combi.": "#87CFEBAA",
    "Reemplazo Prod.": "#FF6347AA",
    "Promo Comb. Simpl.": "#FFA500AA",
    "Ajuste Planograma": "#FFD700AA",
    "Baja Prec. Suger.": "#6A55D3AA",
    "Alza Prec. Suger.": "#ECCFA9AA",
  };

  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "70%",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          backgroundColor: labelColor[value],
          borderRadius: "1rem",
          display: "flex",
          width: "auto",
          justifyContent: "center",
          alignItems: "center",
          paddingInline: "0.5rem",
          color: theme.palette.table.text,
        }}
      >
        {value}
      </div>
    </div>
  );
}
