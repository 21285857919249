import React, { useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "./table-styles.css"; // Optional Theme applied to the grid
import { Grid, Paper, useTheme } from "@mui/material";

import { tableFilterDefaultParams } from "../quartile-table/table-filters";
import { fakeRowData } from "./fakeRowData";
import { fakeRowData2 } from "./fakeRowData";
import { formatDates } from "../../../../utils/formatDate";

// eslint-disable-next-line
function createRowData(experimentsData, userInfo, finishedExperiments) {
  let experimentTypes = {
    promo_precio_unitario: "Promo Prec. Unit.",
    promo_combo_simple: "Promo Comb. Simpl.",
    promo_combo_combinado: "Promo Comb. Combi.",
    reemplazo_producto: "Reemplazo Prod.",
    ajuste_planograma: "Ajuste Planograma",
    baja_precio_sugerido: "Baja Prec. Suger.",
    alza_precio_sugerido: "Alza Prec. Suger.",
  };

  let experimentStatus = {
    running: "Iniciado",
    pending: "Pendiente",
    finished: "Finalizado",
  };

  let result = [];

  let allExperiments = experimentsData?.concat(finishedExperiments);
  // eslint-disable-next-line
  allExperiments?.map((data) => {
    let object = {
      "Id Experiment": data?.id,
      "Client Id": userInfo?.customer_name,
      "Experiment Status": experimentStatus[data?.status?.status],
      "Experiment Type": experimentTypes[data.experimentType.name],
      // "Store Id": `${data?.store?.id} - ${data?.store?.alias}`,
      "Store Id": `${data?.store?.alias}`,
      // "Old Product Id": `${data?.product?.id} - ${data?.product?.name}`,
      "Old Product Id": `${data?.product?.name}`,
      "New Product Id": data?.experimentType?.newProduct
        ? `${data?.experimentType?.newProduct?.name}`
        : data?.experimentType?.firstProduct &&
          data?.experimentType?.secondProduct
        ? `${data?.experimentType?.firstProduct?.name} + ${data?.experimentType?.secondProduct?.name}`
        : "N/A",
      Period: formatDates(data?.period?.start, data?.period?.end),
      "P-1 Units": data?.problem?.unitsSold,
      "P Units": data?.status?.unitsSold,
      "Δ Un": data?.status?.unitsSold - data?.problem?.unitsSold,
      "Δ Un(%)": `${parseFloat(
        ((data?.status?.unitsSold - data?.problem?.unitsSold) /
          data?.problem?.unitsSold) *
          100
      ).toFixed(0)}%`,
      "P-1 Total": `${parseFloat(
        data?.problem?.unitsSold * data?.status?.avgPrice
      )
        .toFixed(2)
        .replace(".", ",")}€`,
      "P Total": `${parseFloat(data?.status?.totalSold)
        .toFixed(2)
        .replace(".", ",")}€`,
      "Δ €": `${parseFloat(
        data?.status?.totalSold -
          data?.problem?.unitsSold * data?.status?.avgPrice
      )
        .toFixed(2)
        .replace(".", ",")}€`,
      "Δ €(%)": `${parseFloat(
        ((data?.status?.totalSold -
          data?.problem?.unitsSold * data?.status?.avgPrice) /
          (data?.problem?.unitsSold * data?.status?.avgPrice)) *
          100
      ).toFixed(0)}%`,
    };
    result.push(object);
  });

  return result;
}

const ReportingBusinessImpactTopTenTableView = ({
  experimentsData,
  userInfo,
  finishedExperimentsData,
  headerTitle,
  col1,
  col2,
  col3,
  col4,
}) => {
  const gridRef = useRef();

  // eslint-disable-next-line
  const [rowData, setRowData] = useState([]);

  // useEffect(
  //   () => {
  //     setRowData(
  //       createRowData(experimentsData, userInfo, finishedExperimentsData)
  //     );
  //   },
  //   // eslint-disable-next-line
  //   [experimentsData, finishedExperimentsData]
  // );

  function generateMonthlyColumnDefs() {
    // Nombres de los meses en español
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    // Define los grupos para el primer y segundo semestre
    const firstSemester = months.slice(0, 6); // Primer semestre
    const secondSemester = months.slice(6); // Segundo semestre

    return [
      {
        headerName: "Primer Semestre",
        // headerClass: "header-center",
        children: [
          {
            columnGroupShow: "closed",
            field: "total",
            headerName: "Ene - Jun",
          },
          ...firstSemester.map((month) => {
            const formattedMonth = month.slice(0, 3); // Usar las primeras tres letras del mes
            return {
              columnGroupShow: "open", // Mostrar solo cuando el grupo está abierto
              headerName: `${formattedMonth}`,
              headerClass: "header-center",
              children: [
                {
                  field: `${formattedMonth}_${col1}`,
                  headerName: col1,
                  valueFormatter: (params) => params.value,
                },
                {
                  field: `${formattedMonth}_${col2}`,
                  headerName: col2,
                  valueFormatter: (params) => params.value,
                },
                {
                  field: `${formattedMonth}_${col3}`,
                  headerName: col3,
                  valueFormatter: (params) => params.value,
                },
                {
                  field: `${formattedMonth}_${col4}`,
                  headerName: col4,
                  valueFormatter: (params) => params.value,
                },
              ],
            };
          }),
        ],
      },
      {
        headerName: "Segundo Semestre",
        // headerClass: "header-center",
        children: [
          {
            columnGroupShow: "closed",
            field: "total",
            headerName: "Jul - Dic",
          },
          ...secondSemester.map((month) => {
            const formattedMonth = month.slice(0, 3); // Usar las primeras tres letras del mes
            return {
              columnGroupShow: "open", // Mostrar solo cuando el grupo está abierto
              headerName: `${formattedMonth}`,
              headerClass: "header-center",
              children: [
                {
                  field: `${formattedMonth}_${col1}`,
                  headerName: col1,
                  valueFormatter: (params) => params.value,
                },
                {
                  field: `${formattedMonth}_${col2}`,
                  headerName: col2,
                  valueFormatter: (params) => params.value,
                },
                {
                  field: `${formattedMonth}_${col3}`,
                  headerName: col3,
                  valueFormatter: (params) => params.value,
                },
                {
                  field: `${formattedMonth}_${col4}`,
                  headerName: col4,
                  valueFormatter: (params) => params.value,
                },
              ],
            };
          }),
        ],
      },
    ];
  }

  // eslint-disable-next-line
  const [colDefs, setColDefs] = useState([
    {
      headerName: headerTitle,
      field: "id",
      minWidth: 200,
      maxWidth: 300,
      headerClass: "header-center",
      // Asegurarse de que no se aplica ningún formateo numérico
      valueFormatter: (params) => params.value,
    },
    ...generateMonthlyColumnDefs(),
  ]);

  const theme = useTheme();
  const themeMode = theme.palette.mode;

  return (
    <Grid
      container
      sx={{
        marginBlock: "1rem",
        display: "flex",
        justifyContent: "center",
        paddingInline: "1rem",
      }}
    >
      <Grid
        component={Paper}
        elevation={2}
        item
        xs={12}
        className={
          themeMode === "dark" ? "ag-theme-alpine-dark" : "ag-theme-alpine"
        }
      >
        {/* <TableHeader
          colDefs={colDefs}
          setColDefs={setColDefs}
          gridRef={gridRef}
          fileName="reporting-business-impact-quartile-table"
        /> */}
        <AgGridReact
          ref={gridRef}
          // pagination={pagination}
          // paginationPageSize={paginationPageSize}
          // paginationPageSizeSelector={paginationPageSizeSelector}
          rowData={col1 === "Δ€" ? fakeRowData2 : fakeRowData}
          columnDefs={colDefs}
          defaultColDef={{
            flex: 1,
            filterParams: tableFilterDefaultParams,
          }}
          suppressMenuHide={true}
          groupDisplayType="multipleColumns"
          domLayout="autoHeight"
        />
      </Grid>
    </Grid>
  );
};

export default ReportingBusinessImpactTopTenTableView;
