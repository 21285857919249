export function filterByDateRange(data, startDate, endDate) {
  // Función para ajustar la hora, minutos y segundos de una fecha a 00:00:00
  const startOfDay = (date) => {
    const newDate = new Date(date);
    newDate.setUTCHours(0, 0, 0, 0); // Establece hora, minutos, segundos y milisegundos a 00
    return newDate;
  };

  // Ajustar startDate y endDate a 00:00:00
  const validStartDate = startDate
    ? startOfDay(new Date(startDate))
    : new Date(-8640000000000000); // Fecha mínima válida en JS
  const validEndDate = endDate ? startOfDay(new Date(endDate)) : null; // `null` si no se proporciona endDate

  // Función para comprobar si una fecha está dentro de un rango
  const isDateInRange = (date, start, end) => {
    return date >= start && (end === null || date <= end);
  };

  // Filtrar los objetos en base al rango de fechas
  return data?.filter((item) => {
    const periodStart = new Date(item?.period?.start);
    const periodEnd = new Date(item?.period?.end);

    // Comprobar si el período del objeto está dentro del rango de fechas
    return (
      isDateInRange(periodStart, validStartDate, validEndDate) &&
      isDateInRange(periodEnd, validStartDate, validEndDate)
    );
  });
}

export const weekdays = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];

export const months = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];
