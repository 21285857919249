import { Box } from "@mui/material";
import AppRoutes from "./AppRoutes";
import React from "react";
import { useDarkMode } from "./context/darkModeContext";
import { ThemeProvider } from "@mui/material/styles";
import darkTheme from "./themes/darkTheme";
import lightTheme from "./themes/lightTheme";

const App = () => {
  const { darkMode } = useDarkMode();
  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Box
        sx={{
          padding: 0,
          boxSizing: "border-box",
        }}
      >
        <AppRoutes />
      </Box>
    </ThemeProvider>
  );
};

export default App;
