import React, { useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "./table-styles.css"; // Optional Theme applied to the grid
import { Grid, Paper, useTheme } from "@mui/material";

import { tableFilterDefaultParams } from "../quartile-table/table-filters";
import { formatDates } from "../../../../utils/formatDate";
// eslint-disable-next-line
function createRowData(experimentsData, userInfo, finishedExperiments) {
  let experimentTypes = {
    promo_precio_unitario: "Promo Prec. Unit.",
    promo_combo_simple: "Promo Comb. Simpl.",
    promo_combo_combinado: "Promo Comb. Combi.",
    reemplazo_producto: "Reemplazo Prod.",
    ajuste_planograma: "Ajuste Planograma",
    baja_precio_sugerido: "Baja Prec. Suger.",
    alza_precio_sugerido: "Alza Prec. Suger.",
  };

  let experimentStatus = {
    running: "Iniciado",
    pending: "Pendiente",
    finished: "Finalizado",
  };

  let result = [];

  let allExperiments = experimentsData?.concat(finishedExperiments);
  // eslint-disable-next-line
  allExperiments?.map((data) => {
    let object = {
      "Id Experiment": data?.id,
      "Client Id": userInfo?.customer_name,
      "Experiment Status": experimentStatus[data?.status?.status],
      "Experiment Type": experimentTypes[data.experimentType.name],
      // "Store Id": `${data?.store?.id} - ${data?.store?.alias}`,
      "Store Id": `${data?.store?.alias}`,
      // "Old Product Id": `${data?.product?.id} - ${data?.product?.name}`,
      "Old Product Id": `${data?.product?.name}`,
      "New Product Id": data?.experimentType?.newProduct
        ? `${data?.experimentType?.newProduct?.name}`
        : data?.experimentType?.firstProduct &&
          data?.experimentType?.secondProduct
        ? `${data?.experimentType?.firstProduct?.name} + ${data?.experimentType?.secondProduct?.name}`
        : "N/A",
      Period: formatDates(data?.period?.start, data?.period?.end),
      "P-1 Units": data?.problem?.unitsSold,
      "P Units": data?.status?.unitsSold,
      "Δ Un": data?.status?.unitsSold - data?.problem?.unitsSold,
      "Δ Un(%)": `${parseFloat(
        ((data?.status?.unitsSold - data?.problem?.unitsSold) /
          data?.problem?.unitsSold) *
          100
      ).toFixed(0)}%`,
      "P-1 Total": `${parseFloat(
        data?.problem?.unitsSold * data?.status?.avgPrice
      )
        .toFixed(2)
        .replace(".", ",")}€`,
      "P Total": `${parseFloat(data?.status?.totalSold)
        .toFixed(2)
        .replace(".", ",")}€`,
      "Δ €": `${parseFloat(
        data?.status?.totalSold -
          data?.problem?.unitsSold * data?.status?.avgPrice
      )
        .toFixed(2)
        .replace(".", ",")}€`,
      "Δ €(%)": `${parseFloat(
        ((data?.status?.totalSold -
          data?.problem?.unitsSold * data?.status?.avgPrice) /
          (data?.problem?.unitsSold * data?.status?.avgPrice)) *
          100
      ).toFixed(0)}%`,
    };
    result.push(object);
  });

  return result;
}

const ReportingBusinessImpactQuartileTableView = ({
  experimentsData,
  userInfo,
  finishedExperimentsData,
}) => {
  const gridRef = useRef();

  // eslint-disable-next-line
  const [rowData, setRowData] = useState([]);

  // useEffect(
  //   () => {
  //     setRowData(
  //       createRowData(experimentsData, userInfo, finishedExperimentsData)
  //     );
  //   },
  //   // eslint-disable-next-line
  //   [experimentsData, finishedExperimentsData]
  // );

  const fakeRowData = [
    {
      id: 2024,
      "YTD_€": "10596€",
      YTD_U: 1846,
      // "YTD_ΔPY€": 10,
      // "Q1_€": 300,
      Q1_U: 2179,
      // "Q1_ΔPY€": 3,
      "Q2_€": "6596€",
      Q2_U: 1540,
      // "Q2_ΔPY€": 4,
      "Q3_€": "2800€",
      Q3_U: 2100,
      // "Q3_ΔPY€": 2,
      "Q4_€": "1200€",
      Q4_U: 800,
      // "Q4_ΔPY€": 1,
    },
    {
      id: 2023,
      "YTD_€": "11136€",
      YTD_U: 1431,
      // "YTD_ΔPY€": 20,
      // "Q1_€": 300,
      Q1_U: 2179,
      // "Q1_ΔPY€": 6,
      "Q2_€": "6786€",
      Q2_U: 1820,
      // "Q2_ΔPY€": 8,
      "Q3_€": "2950€",
      Q3_U: 2400,
      // "Q3_ΔPY€": 4,
      "Q4_€": "1400€",
      Q4_U: 1500,
      // "Q4_ΔPY€": 2,
    },
    {
      id: "Δ YoY",
      "YTD_€": "-4.8%",
      YTD_U: "29.0%",
      // "YTD_ΔPY€": 15,
      "Q1_€": "0.0%",
      Q1_U: "0.0%",
      // "Q1_ΔPY€": 4.5,
      "Q2_€": "-2.8%",
      Q2_U: "-15.4%",
      // "Q2_ΔPY€": 6,
      "Q3_€": "-5.1%",
      Q3_U: "-12.5%",
      // "Q3_ΔPY€": 3,
      "Q4_€": "-14.3%",
      Q4_U: "-46.7%",
      // "Q4_ΔPY€": 1.5,
    },
  ];

  // eslint-disable-next-line
  const [colDefs, setColDefs] = useState([
    {
      headerName: "Lanzadera Com Izq",
      field: "id",
      minWidth: 200,
      headerClass: "header-center",
      // Asegurarse de que no se aplica ningún formateo numérico
      valueFormatter: (params) => params.value,
    },
    {
      headerName: "YTD",
      headerClass: "header-center",
      children: [
        {
          field: "YTD_€",
          headerName: "€",
          // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "YTD_U",
          headerName: "U",
          // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        { field: "YTD_ΔPY€", headerName: "ΔPY€" },
      ],
    },
    {
      headerName: "Q1",
      headerClass: "header-center",
      children: [
        {
          field: "Q1_€",
          headerName: "€",
          // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "Q1_U",
          headerName: "U",
          // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        { field: "Q1_ΔPY€", headerName: "ΔPY€" },
      ],
    },
    {
      headerName: "Q2",
      headerClass: "header-center",
      children: [
        {
          field: "Q2_€",
          headerName: "€",
          // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "Q2_U",
          headerName: "U",
          // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        { field: "Q2_ΔPY€", headerName: "ΔPY€" },
      ],
    },
    {
      headerName: "Q3",
      headerClass: "header-center",
      children: [
        {
          field: "Q3_€",
          headerName: "€",
          // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "Q3_U",
          headerName: "U",
          // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        { field: "Q3_ΔPY€", headerName: "ΔPY€" },
      ],
    },
    {
      headerName: "Q4",
      headerClass: "header-center",
      children: [
        {
          field: "Q4_€",
          headerName: "€",
          // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "Q4_U",
          headerName: "U",
          // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        { field: "Q4_ΔPY€", headerName: "ΔPY€" },
      ],
    },
  ]);

  const theme = useTheme();
  const themeMode = theme.palette.mode;

  return (
    <Grid
      container
      sx={{
        marginBlock: "1rem",
        display: "flex",
        justifyContent: "center",
        paddingInline: "1rem",
      }}
    >
      <Grid
        component={Paper}
        elevation={2}
        item
        xs={12}
        className={
          themeMode === "dark" ? "ag-theme-alpine-dark" : "ag-theme-alpine"
        }
      >
        {/* <TableHeader
          colDefs={colDefs}
          setColDefs={setColDefs}
          gridRef={gridRef}
          fileName="reporting-business-impact-quartile-table"
        /> */}
        <AgGridReact
          ref={gridRef}
          // pagination={pagination}
          // paginationPageSize={paginationPageSize}
          // paginationPageSizeSelector={paginationPageSizeSelector}
          rowData={fakeRowData}
          columnDefs={colDefs}
          defaultColDef={{
            flex: 1,
            filterParams: tableFilterDefaultParams,
          }}
          suppressMenuHide={true}
          domLayout="autoHeight"
        />
      </Grid>
    </Grid>
  );
};

export default ReportingBusinessImpactQuartileTableView;
