export const customSelectStyles = (color, backgroundColor, borderColor) => ({
  minWidth: "85px",
  width: "9rem",
  height: "1.5rem",
  fontSize: "0.9rem",
  fontWeight: "bold",
  textAlign: "center",
  color: color,
  backgroundColor: backgroundColor,

  ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
    {
      padding: 0,
    },
  ".css-10har2n-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-10har2n-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-10har2n-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
    { padding: 0 },
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: borderColor,
  },
  ".css-qiwgdb.MuiSelect-select": {
    textOverflow: "clip",
  },
});

export default customSelectStyles;
