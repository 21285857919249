import React, { useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "./table-styles.css"; // Optional Theme applied to the grid
import { Grid, Paper, useTheme } from "@mui/material";

import { tableFilterDefaultParams } from "../quartile-table/table-filters";
import { formatDates } from "../../../../utils/formatDate";

// eslint-disable-next-line
function createRowData(experimentsData, userInfo, finishedExperiments) {
  let experimentTypes = {
    promo_precio_unitario: "Promo Prec. Unit.",
    promo_combo_simple: "Promo Comb. Simpl.",
    promo_combo_combinado: "Promo Comb. Combi.",
    reemplazo_producto: "Reemplazo Prod.",
    ajuste_planograma: "Ajuste Planograma",
    baja_precio_sugerido: "Baja Prec. Suger.",
    alza_precio_sugerido: "Alza Prec. Suger.",
  };

  let experimentStatus = {
    running: "Iniciado",
    pending: "Pendiente",
    finished: "Finalizado",
  };

  let result = [];

  let allExperiments = experimentsData?.concat(finishedExperiments);
  // eslint-disable-next-line
  allExperiments?.map((data) => {
    let object = {
      "Id Experiment": data?.id,
      "Client Id": userInfo?.customer_name,
      "Experiment Status": experimentStatus[data?.status?.status],
      "Experiment Type": experimentTypes[data.experimentType.name],
      // "Store Id": `${data?.store?.id} - ${data?.store?.alias}`,
      "Store Id": `${data?.store?.alias}`,
      // "Old Product Id": `${data?.product?.id} - ${data?.product?.name}`,
      "Old Product Id": `${data?.product?.name}`,
      "New Product Id": data?.experimentType?.newProduct
        ? `${data?.experimentType?.newProduct?.name}`
        : data?.experimentType?.firstProduct &&
          data?.experimentType?.secondProduct
        ? `${data?.experimentType?.firstProduct?.name} + ${data?.experimentType?.secondProduct?.name}`
        : "N/A",
      Period: formatDates(data?.period?.start, data?.period?.end),
      "P-1 Units": data?.problem?.unitsSold,
      "P Units": data?.status?.unitsSold,
      "Δ Un": data?.status?.unitsSold - data?.problem?.unitsSold,
      "Δ Un(%)": `${parseFloat(
        ((data?.status?.unitsSold - data?.problem?.unitsSold) /
          data?.problem?.unitsSold) *
          100
      ).toFixed(0)}%`,
      "P-1 Total": `${parseFloat(
        data?.problem?.unitsSold * data?.status?.avgPrice
      )
        .toFixed(2)
        .replace(".", ",")}€`,
      "P Total": `${parseFloat(data?.status?.totalSold)
        .toFixed(2)
        .replace(".", ",")}€`,
      "Δ €": `${parseFloat(
        data?.status?.totalSold -
          data?.problem?.unitsSold * data?.status?.avgPrice
      )
        .toFixed(2)
        .replace(".", ",")}€`,
      "Δ €(%)": `${parseFloat(
        ((data?.status?.totalSold -
          data?.problem?.unitsSold * data?.status?.avgPrice) /
          (data?.problem?.unitsSold * data?.status?.avgPrice)) *
          100
      ).toFixed(0)}%`,
    };
    result.push(object);
  });

  return result;
}

const ReportingBusinessImpactMonthsTableView = ({
  experimentsData,
  userInfo,
  finishedExperimentsData,
}) => {
  const gridRef = useRef();

  // eslint-disable-next-line
  const [rowData, setRowData] = useState([]);

  // useEffect(
  //   () => {
  //     setRowData(
  //       createRowData(experimentsData, userInfo, finishedExperimentsData)
  //     );
  //   },
  //   // eslint-disable-next-line
  //   [experimentsData, finishedExperimentsData]
  // );

  const fakeRowData = [
    {
      id: 2024,
      "Ene_€": "10596€",
      Ene_U: 1846,
      "Ene_ΔPY€": 10,
      "Feb_€": "3000€",
      Feb_U: 2200,
      "Feb_ΔPY€": 5,
      "Mar_€": "4000€",
      Mar_U: 2100,
      "Mar_ΔPY€": 7,
      "Abr_€": "5000€",
      Abr_U: 2000,
      "Abr_ΔPY€": 6,
      "May_€": "6000€",
      May_U: 1900,
      "May_ΔPY€": 8,
      "Jun_€": "7000€",
      Jun_U: 1800,
      "Jun_ΔPY€": 9,
      "Jul_€": "8000€",
      Jul_U: 1700,
      "Jul_ΔPY€": 11,
      "Ago_€": "9000€",
      Ago_U: 1600,
      "Ago_ΔPY€": 12,
      "Sep_€": "10000€",
      Sep_U: 1500,
      "Sep_ΔPY€": 13,
      "Oct_€": "11000€",
      Oct_U: 1400,
      "Oct_ΔPY€": 14,
      "Nov_€": "12000€",
      Nov_U: 1300,
      "Nov_ΔPY€": 15,
      "Dic_€": "13000€",
      Dic_U: 1200,
      "Dic_ΔPY€": 16,
    },
    {
      id: 2023,
      "Ene_€": "9500€",
      Ene_U: 1700,
      "Ene_ΔPY€": 9,
      "Feb_€": "2800€",
      Feb_U: 2100,
      "Feb_ΔPY€": 4,
      "Mar_€": "3900€",
      Mar_U: 2000,
      "Mar_ΔPY€": 6,
      "Abr_€": "4900€",
      Abr_U: 1900,
      "Abr_ΔPY€": 5,
      "May_€": "5900€",
      May_U: 1800,
      "May_ΔPY€": 7,
      "Jun_€": "6900€",
      Jun_U: 1700,
      "Jun_ΔPY€": 8,
      "Jul_€": "8000€",
      Jul_U: 1600,
      "Jul_ΔPY€": 10,
      "Ago_€": "9100€",
      Ago_U: 1500,
      "Ago_ΔPY€": 11,
      "Sep_€": "10200€",
      Sep_U: 1400,
      "Sep_ΔPY€": 12,
      "Oct_€": "11300€",
      Oct_U: 1300,
      "Oct_ΔPY€": 13,
      "Nov_€": "12400€",
      Nov_U: 1200,
      "Nov_ΔPY€": 14,
      "Dic_€": "13500€",
      Dic_U: 1100,
      "Dic_ΔPY€": 15,
    },
    {
      id: "Δ YoY",
      "Ene_€": "-4.7%",
      Ene_U: "8.0%",
      "Ene_ΔPY€": 5,
      "Feb_€": "-6.7%",
      Feb_U: "-1.0%",
      "Feb_ΔPY€": 4,
      "Mar_€": "-2.5%",
      Mar_U: "-5.0%",
      "Mar_ΔPY€": 2,
      "Abr_€": "-3.0%",
      Abr_U: "-5.3%",
      "Abr_ΔPY€": 3,
      "May_€": "-1.7%",
      May_U: "-5.6%",
      "May_ΔPY€": 4,
      "Jun_€": "-3.0%",
      Jun_U: "-6.0%",
      "Jun_ΔPY€": 5,
      "Jul_€": "-2.5%",
      Jul_U: "-4.5%",
      "Jul_ΔPY€": 6,
      "Ago_€": "-2.2%",
      Ago_U: "-4.0%",
      "Ago_ΔPY€": 7,
      "Sep_€": "-1.8%",
      Sep_U: "-3.8%",
      "Sep_ΔPY€": 8,
      "Oct_€": "-2.0%",
      Oct_U: "-3.5%",
      "Oct_ΔPY€": 9,
      "Nov_€": "-1.5%",
      Nov_U: "-3.2%",
      "Nov_ΔPY€": 10,
      "Dic_€": "-2.8%",
      Dic_U: "-3.5%",
      "Dic_ΔPY€": 11,
    },
  ];

  function generateMonthlyColumnDefs() {
    // Nombres de los meses en español
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    // Define los grupos para el primer y segundo semestre
    const firstSemester = months.slice(0, 6); // Primer semestre
    const secondSemester = months.slice(6); // Segundo semestre

    return [
      {
        headerName: "Primer Semestre",
        // headerClass: "header-center",
        children: [
          {
            columnGroupShow: "closed",
            field: "total",
            headerName: "Ene - Jun",
          },
          ...firstSemester.map((month) => {
            const formattedMonth = month.slice(0, 3); // Usar las primeras tres letras del mes
            return {
              columnGroupShow: "open", // Mostrar solo cuando el grupo está abierto
              headerName: `${formattedMonth}`,
              headerClass: "header-center",
              children: [
                {
                  field: `${formattedMonth}_€`,
                  headerName: "€",
                  valueFormatter: (params) => params.value,
                },
                {
                  field: `${formattedMonth}_U`,
                  headerName: "U",
                  valueFormatter: (params) => params.value,
                },
                {
                  field: `${formattedMonth}_ΔPY€`,
                  headerName: "ΔPY€",
                  valueFormatter: (params) => params.value,
                },
              ],
            };
          }),
        ],
      },
      {
        headerName: "Segundo Semestre",
        // headerClass: "header-center",
        children: [
          {
            columnGroupShow: "closed",
            field: "total",
            headerName: "Jul - Dic",
          },
          ...secondSemester.map((month) => {
            const formattedMonth = month.slice(0, 3); // Usar las primeras tres letras del mes
            return {
              columnGroupShow: "open", // Mostrar solo cuando el grupo está abierto
              headerName: `${formattedMonth}`,
              headerClass: "header-center",
              children: [
                {
                  field: `${formattedMonth}_€`,
                  headerName: "€",
                  valueFormatter: (params) => params.value,
                },
                {
                  field: `${formattedMonth}_U`,
                  headerName: "U",
                  valueFormatter: (params) => params.value,
                },
                {
                  field: `${formattedMonth}_ΔPY€`,
                  headerName: "ΔPY€",
                  valueFormatter: (params) => params.value,
                },
              ],
            };
          }),
        ],
      },
    ];
  }

  // eslint-disable-next-line
  const [colDefs, setColDefs] = useState([
    {
      headerName: "Lanzadera Com Izq",
      field: "id",
      minWidth: 200,
      maxWidth: 200,
      headerClass: "header-center",
      // Asegurarse de que no se aplica ningún formateo numérico
      valueFormatter: (params) => params.value,
    },
    ...generateMonthlyColumnDefs(),
  ]);

  const theme = useTheme();
  const themeMode = theme.palette.mode;

  return (
    <Grid
      container
      sx={{
        marginBlock: "1rem",
        display: "flex",
        justifyContent: "center",
        paddingInline: "1rem",
      }}
    >
      <Grid
        component={Paper}
        elevation={2}
        item
        xs={12}
        className={
          themeMode === "dark" ? "ag-theme-alpine-dark" : "ag-theme-alpine"
        }
      >
        {/* <TableHeader
          colDefs={colDefs}
          setColDefs={setColDefs}
          gridRef={gridRef}
          fileName="reporting-business-impact-quartile-table"
        /> */}
        <AgGridReact
          ref={gridRef}
          // pagination={pagination}
          // paginationPageSize={paginationPageSize}
          // paginationPageSizeSelector={paginationPageSizeSelector}
          rowData={fakeRowData}
          columnDefs={colDefs}
          defaultColDef={{
            flex: 1,
            filterParams: tableFilterDefaultParams,
          }}
          suppressMenuHide={true}
          groupDisplayType="multipleColumns"
          domLayout="autoHeight"
        />
      </Grid>
    </Grid>
  );
};

export default ReportingBusinessImpactMonthsTableView;
