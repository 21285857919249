import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as AlertsIcon } from "../../assets/images/notifications-icon.svg";
import { ReactComponent as HomeIcon } from "../../assets/images/home-icon.svg";
import { ReactComponent as ExperimentsIcon } from "../../assets/images/experiments-icon.svg";
import { ReactComponent as VendingMachineIcon } from "../../assets/images/vending-icon.svg";
import { ReactComponent as InsightsOutlinedIcon } from "../../assets/images/insights-icon.svg";
import { ReactComponent as ShoppingCartOutlinedIcon } from "../../assets/images/shopping-cart-icon.svg";
// eslint-disable-next-line
import { ReactComponent as ClipboardIcon } from "../../assets/images/clipboard-icon.svg";
import {
  Grid,
  // eslint-disable-next-line
  Divider,
  Drawer,
  IconButton,
  List,
  // eslint-disable-next-line
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeMenu, openMenu } from "../../store/features/menuDrawer/menuSlice";
import AccordionItem from "./accordion-item/accordion-item.component";
import SingleItem from "./single-item/single-item.component";

const DrawerMenu = ({ activeOptionId, activeSubsectionId }) => {
  const isMenuOpen = useSelector((state) => state.menu.menuOpen);
  const dispatch = useDispatch();
  const theme = useTheme();

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Drawer
        sx={{
          // width: "240px",
          flexShrink: 0,
          "& .MuiDivider-root": {
            color: theme.palette.divider.main,
          },
          "& .MuiDrawer-paper": {
            width: "300px",
            boxSizing: "border-box",
            minHeight: "100%",
            position: "fixed",
            top: "9.5rem",
            left: "50px",
            zIndex: 1000,
          },
        }}
        variant="persistent"
        anchor="left"
        open={isMenuOpen}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            dispatch(closeMenu());
          }
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          {/* <Typography
            sx={{
              fontWeight: "bold",
              color: theme.palette.title.main,
              flexGrow: 1,
              marginInline: "2rem",
            }}
          >
            OPERATOR APP
          </Typography> */}
          <IconButton onClick={() => dispatch(closeMenu())}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        {/* <Divider /> */}
        <List>
          {[
            {
              label: "Portfolio",
              icon: <HomeIcon fill={theme.palette.icons.main} />,
              url: "/",
              childs: [],
            },
            {
              label: "Máquinas",
              icon: (
                <VendingMachineIcon
                  fill={theme.palette.icons.main}
                  height="26px"
                />
              ),
              url: "/machines",
              childs: [],
            },
            {
              label: "Experimentos",
              icon: (
                <ExperimentsIcon
                  fill={theme.palette.icons.main}
                  style={{ width: "25px", height: "35px" }}
                />
              ),
              url: "/experiments",
              childs: [
                {
                  title: "Mis Experimentos",
                  url: "/experiments",
                },
                {
                  title: "Management Experimentos",
                  url: "/experiments/management",
                },
                {
                  title: "Performance Experimentos",
                  url: "/experiments/performance",
                },
                // { title: "Insights Experimentos", url: "/" },
              ],
            },

            {
              label: "Notificaciones",
              icon: <AlertsIcon fill={theme.palette.icons.main} width="25px" />,
              url: "/alerts",
              childs: [],
            },
            {
              label: "Insights",
              icon: (
                <InsightsOutlinedIcon
                  sx={{
                    fill: theme.palette.icons.main,
                  }}
                  fill={theme.palette.icons.main}
                  fontSize="large"
                />
              ),
              url: "/insights",
              childs: [],
            },
            {
              label: "Lista de Compras",
              icon: (
                <ShoppingCartOutlinedIcon
                  sx={{
                    height: "35px",
                    width: "30px",
                  }}
                  fill={theme.palette.icons.main}
                />
              ),
              url: "/sales",
              childs: [],
            },
            // {
            //   label: "Informes",
            //   icon: (
            //     <ClipboardIcon
            //       fill={theme.palette.icons.main}
            //       style={{ width: "25px", height: "35px" }}
            //     />
            //   ),
            //   url: "/reporting",
            //   childs: [
            //     {
            //       title: "Impacto de Negocio",
            //       url: "/reporting/business-impact",
            //     },
            //     {
            //       title: "Rentabilidad",
            //       url: "/reporting/profitability",
            //     },
            //   ],
            // },
          ].map((element, index) =>
            element.childs.length !== 0 ? (
              <AccordionItem
                key={`parent-${element.label}`}
                element={element}
                index={index}
                activeOptionId={activeOptionId}
                activeSubsectionId={activeSubsectionId}
                expanded={expanded}
                handleChange={handleChange}
              />
            ) : (
              <SingleItem
                key={`parent-${element.label}`}
                element={element}
                index={index}
                activeOptionId={activeOptionId}
              />
            )
          )}
        </List>
        {/* <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
      </Drawer>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: theme.palette.filters.main,
          paddingBlockStart: "3rem",
          position: "fixed",
          top: "7rem",
          left: 0,
          zIndex: 1000,
          height: "100%",
          width: "50px",
        }}
      >
        <MenuIcon
          sx={{
            ":hover": { backgroundColor: "#E0DEDE", borderRadius: "5px" },
            color: theme.palette.divider.main,
          }}
          onClick={() => {
            dispatch(isMenuOpen ? closeMenu() : openMenu());
          }}
        />
      </Grid>
    </>
  );
};

export default DrawerMenu;
