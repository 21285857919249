export function formatDates(startDate, endDate) {
  // Función auxiliar para formatear una fecha
  function formatDate(date) {
    if (!date || date.length < 10) {
      // Verifica si la fecha es válida
      return "-/-";
    }
    return (
      date.substr(8, 2) + "/" + date.substr(5, 2) + "/" + date.substr(2, 2)
    );
  }

  var startFormatted = formatDate(startDate);
  var endFormatted = formatDate(endDate);

  var dateString = startFormatted + " al " + endFormatted;

  return dateString;
}
