export let createDatePickerStyles = (
  primaryColor,
  darkerColor,
  backgroundColor,
  inputColor,
  inputBackgroundColor
) => {
  return {
    ".rmdp-header-values": {
      color: primaryColor,
      fontWeight: "bold",
    },
    ".rmdp-arrow": {
      border: `solid ${primaryColor}`,
      borderWidth: "0 2px 2px 0",
    },
    ".rmdp-arrow-container:hover": {
      backgroundColor: primaryColor,
    },
    ".rmdp-week-day": {
      color: primaryColor,
    },
    ".rmdp-ym .rmdp-day span": {
      color: primaryColor,
    },
    ".rmdp-day.rmdp-today span": {
      backgroundColor: darkerColor,
      color: "#fff",
    },

    ".rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover": {
      backgroundColor: primaryColor,
      color: "#fff",
    },

    ".rmdp-day.rmdp-selected span:not(.highlight)": {
      backgroundColor: primaryColor,
      color: "#fff",
    },
    ".rmdp-range": {
      backgroundColor: primaryColor,
    },
    ".rmdp-range-hover": {
      backgroundColor: primaryColor,
    },
    ".rmdp-calendar , .rmdp-month-picker , .rmdp-year-picker": {
      backgroundColor: backgroundColor,
    },
    ".rmdp-input , .rmdp-input::placeholder": {
      color: inputColor,
      fontSize: "0.7rem",
      fontWeight: 600,
      backgroundColor: inputBackgroundColor,
      border: "none",
      height: "1.5rem",
      width: "9rem",
    },
  };
};
