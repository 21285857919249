import { Grid, Typography, useTheme } from "@mui/material";
import { CalendarMonthOutlined as CalendarAlertIcon } from "@mui/icons-material";
import { ReactComponent as VendingMachineIcon } from "../../../../../../assets/images/vending-icon.svg";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import React from "react";

function cleanString(text) {
  var pattern = /\*/g;
  var cleanedText = text.replace(pattern, "");
  return cleanedText;
}

const capitalizeWords = (str) => {
  const lowerCaseStr = str.toLowerCase();
  const words = lowerCaseStr.split(" ");
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords.join(" ");
};
const TrelloCardProblemComponent = ({ experimentData }) => {
  const theme = useTheme();
  return (
    <>
      <Grid container sx={{ display: "flex" }}>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          xs={1}
        >
          <WarningRoundedIcon
            fill={theme.palette.mode === "dark" ? "#fff" : "#000"}
          />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          item
          xs={11}
        >
          <Typography
            sx={{
              fontSize: "0.7rem",
              lineHeight: "0.7rem",
              fontWeight: "bold",
              paddingInlineStart: "0.2rem",
            }}
          >
            Baja Rotación de{" "}
            <span style={{ color: theme.palette.alerts.error.main }}>
              {capitalizeWords(experimentData?.product?.name)}
            </span>
            .
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ display: "flex" }}>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          xs={1}
        >
          <CalendarAlertIcon
            fill={theme.palette.mode === "dark" ? "#fff" : "#000"}
          />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          item
          xs={11}
        >
          <Typography
            sx={{
              fontSize: "0.7rem",
              lineHeight: "0.7rem",
              fontWeight: "bold",
              paddingInlineStart: "0.2rem",
            }}
          >
            Ventas: {experimentData?.problem?.unitsSold} Unidades.
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ display: "flex" }}>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          xs={1}
        >
          <VendingMachineIcon
            fill={theme.palette.mode === "dark" ? "#fff" : "#000"}
            style={{ width: "1.3rem", height: "1.3rem" }}
          />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            paddingInlineStart: "0.2rem",
          }}
          item
          xs={11}
        >
          <Typography
            sx={{
              fontSize: "0.7rem",
              lineHeight: "0.7rem",
              fontWeight: "bold",
            }}
          >
            Ubicación: Máquina {cleanString(experimentData?.store?.alias)}.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default TrelloCardProblemComponent;
