import { Grid, Paper, Typography } from "@mui/material";
import React from "react";

const IncrementalBenefits = ({
  totalIncrementalProfit,
  incrementalProfitPercentage,
  previousAndActualPeriodMetrics,
}) => {
  return (
    <Grid
      item
      xs={4}
      sx={{
        display: "flex",
        justifyContent: "center",
        minWidth: "275px",
        maxWidth: "500px",
        padding: "0.5rem",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          borderRadius: "1rem",
          padding: "0.5rem",
          height: "15rem",
          minWidth: "275px",
          maxWidth: "500px",
        }}
      >
        <Grid container sx={{ height: "100%" }}>
          <Grid
            item
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
            xs={12}
          >
            <Typography
              sx={{
                fontSize: "1.2rem",
                textAlign: "start",
                lineHeight: "1.2rem",
              }}
            >
              <span style={{ fontWeight: "bold", color: "#00b383" }}>
                Beneficios
              </span>{" "}
              Incrementales
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              marginBlock: "0.5rem",
            }}
          >
            <Typography>
              <span
                style={{
                  fontSize: "1.5rem",
                  lineHeight: "1.5rem",
                  fontWeight: "bold",
                  color: "#00A87C",
                }}
              >
                {/* {totalIncrementalProfit >= 0 ? "+" : "-"}
                {numeral(totalIncrementalProfit).format(
                  totalIncrementalProfit >= 10000
                    ? totalIncrementalProfit % 1 === 0
                      ? "0a"
                      : "0.[0]a"
                    : null
                )} */}
                Próximamente...
              </span>
              <span style={{ fontSize: "1.5rem", lineHeight: "1.5rem" }}>
                {/* € */}
              </span>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#00A87C",
                borderRadius: "2px",
                color: "#fff",
                fontSize: "1.1rem",
                lineHeight: "1.1rem",
                padding: "2px",
                fontWeight: "bold",
              }}
            >
              {incrementalProfitPercentage >= 0 ? "+" : "-"}
              {/* {numeral(incrementalProfitPercentage).format(
                incrementalProfitPercentage >= 10000
                  ? incrementalProfitPercentage % 1 === 0
                    ? "0a"
                    : "0.[0]a"
                  : null
              )} */}
              100%
            </Typography>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              width: "100%",
              marginBlockStart: "0.8rem",
              // backgroundColor: "#A6A6A6",
              //   borderRadius: "0.3rem",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                width: "100%",
                height: "1rem",
                "& > div": {
                  //   border: "1px dashed  #b3ffeb",
                },
              }}
            >
              <Grid
                item
                xs={5}
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></Grid>
              <Grid
                item
                xs={3.5}
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    lineHeight: "1rem",
                    // fontWeight: "bold",
                    color: "#A6A6A6",
                  }}
                >
                  P-1
                </Typography>
              </Grid>
              <Grid
                item
                xs={3.5}
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    lineHeight: "1rem",
                    // fontWeight: "bold",
                    color: "#A6A6A6",
                  }}
                >
                  P
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                width: "100%",
                height: "1rem",
                "& > div": {
                  //   border: "1px solid #fff",
                },
              }}
            >
              <Grid
                item
                xs={5}
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    lineHeight: "1rem",
                    // fontWeight: "bold",
                    color: "#00B383",
                  }}
                >
                  Beneficios
                </Typography>
              </Grid>
              <Grid
                item
                xs={3.5}
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    lineHeight: "1rem",
                    // fontWeight: "bold",
                    color: "#00B383",
                  }}
                >
                  {/* {numeral(
                    previousAndActualPeriodMetrics.totalPreviousPeriodRevenue
                  ).format(
                    previousAndActualPeriodMetrics.totalPreviousPeriodRevenue >=
                      10000
                      ? previousAndActualPeriodMetrics.totalPreviousPeriodRevenue %
                          1 ===
                        0
                        ? "0a"
                        : "0.[0]a"
                      : null
                  )} */}
                  €
                </Typography>
              </Grid>
              <Grid
                item
                xs={3.5}
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1rem",
                    lineHeight: "1rem",
                    // fontWeight: "bold",
                    color: "#00B383",
                  }}
                >
                  {/* {numeral(
                    previousAndActualPeriodMetrics.totalCurrentPeriodRevenue
                  ).format(
                    previousAndActualPeriodMetrics.totalCurrentPeriodRevenue >=
                      10000
                      ? previousAndActualPeriodMetrics.totalCurrentPeriodRevenue %
                          1 ===
                        0
                        ? "0a"
                        : "0.[0]a"
                      : null
                  )} */}
                  €
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default IncrementalBenefits;
