import React from "react";
import { useEffect, useState } from "react";
import Header from "../../../components/header/header.component";
// import BigCard from "../../../components/big-card/big-card.component";
// import Section from "../../../components/section/section.component";
import axios from "axios";
import { Box, Grid, useTheme } from "@mui/material";
import Filters from "../../../components/filters/filters.component";
import { getUserInfo } from "../../../services/userInfo.service";
import DrawerMenu from "../../../components/drawer/drawer.component";
// import RunningExperimentsCard from "../../../components/experiments/running-experiments-card/running-experiments-card.component";
// import ExperimentsSection from "../../../components/experiments/experiments-section/experiments-section.component";
// import PendingExperimentsCard from "../../../components/experiments/pending-experiments-card/pending-experiments-card.component";
// import BigCardComingSoon from "../../../components/big-card/big-card-coming-soon.component";
import { closeMenu } from "../../../store/features/menuDrawer/menuSlice";
import { useDispatch } from "react-redux";
import "./react-tabs.css";
import ReportingProfitabilityCategoryTableView from "../../../components/reporting/profitability/category-table/table-view.component";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "./react-tabs.css";
import ReportingProfitabilityProductTableView from "../../../components/reporting/profitability/product-table/table-view.component";
import ReportingProfitabilityPriceTableView from "../../../components/reporting/profitability/price-table/table-view.component";

const MyReportingProfitability = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const loginToken = localStorage.getItem("access_token");
  const userInfo = getUserInfo();
  // eslint-disable-next-line
  const [metricsData, setMetricsData] = useState({});
  // eslint-disable-next-line
  const [finishedExperiments, setFinishedExperiments] = useState([]);
  // eslint-disable-next-line
  const [runningExperiments, setRunningExperiments] = useState([]);
  // eslint-disable-next-line
  const [pendingExperiments, setPendingExperiments] = useState([]);
  // eslint-disable-next-line
  const [incomingExperiments, setIncomingExperiments] = useState([]);
  // eslint-disable-next-line
  const [subtitle, setSubtitle] = useState("");

  const [filters, setFilters] = useState({
    groups: "default", //label: "Grupo"
    clusters: "default", //label: "Posición"
    locations: "default", //label: "Ubicación"
    categories: "default", //label: "Tipo"
    machines: "default", //label: "Máquinas"
  });

  const fetchMetrics = async () => {
    if (filters.groups !== "default") {
      try {
        if (userInfo) {
          const { data } = await axios.get(
            `https://data.myma.space/api/experiments?clientId=${userInfo.customer_id}&group=${filters.groups}&period=w&storeClusterId=${filters.clusters}&location=${filters.locations}&category=${filters.categories}&storeId=${filters.machines}`
          );
          setMetricsData(data);
          setPendingExperiments(
            data?.experiments?.filter(
              (element) =>
                element?.status?.status === "pending" &&
                element.incoming === false
            )
          );
          setRunningExperiments(
            data?.experiments?.filter(
              (element) =>
                element?.status?.status === "running" &&
                element.incoming === false
            )
          );
          setIncomingExperiments(
            data?.experiments?.filter((element) => element?.incoming)
          );
        }
      } catch (error) {
        console.error("Error fetching metrics:", error);
      }
    }
  };
  const fetchFinishedExperimentsdMetrics = async () => {
    if (filters.groups !== "default") {
      try {
        if (userInfo) {
          const { data } = await axios.get(
            `https://data.myma.space/api/experiments?clientId=${userInfo.customer_id}&group=${filters.groups}&period=w&storeClusterId=${filters.clusters}&location=${filters.locations}&category=${filters.categories}&storeId=${filters.machines}&status=finished`
          );
          // setMetricsData(data);
          setFinishedExperiments(
            data?.experiments?.filter(
              (element) =>
                element?.status?.status === "finished" &&
                element.incoming === false
            )
          );
        }
      } catch (error) {
        console.error("Error fetching finished experiments metrics:", error);
      }
    }
  };

  useEffect(
    () => {
      // Verify if access token isn't null
      if (loginToken !== null) {
        // Verify if axios headers are setted.
        if (axios.defaults.headers.common["Authorization"]) {
          fetchMetrics();
          fetchFinishedExperimentsdMetrics();
        } else {
          // If axios headers aren't setted, wait untill they are.
          const interval = setInterval(() => {
            if (axios.defaults.headers.common["Authorization"]) {
              clearInterval(interval);
              fetchMetrics();
              fetchFinishedExperimentsdMetrics();
            }
          }, 100); // Verification interval.
        }
      }
    },
    // eslint-disable-next-line
    [filters, loginToken]
  );

  return (
    <>
      <Header
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ fontWeight: "normal" }}></div>
            <div style={{ fontWeight: "bold" }}>&nbsp;Rentabilidad</div>
          </div>
        }
        avatar="FB"
      />
      <Box>
        <Grid
          sx={{
            height: "100%",
            paddingBlockStart: "7rem",
            paddingInlineStart: "50px",
          }}
          container
        >
          <Filters
            disableFirstMachineSelected
            setBigCardSubtitle={setSubtitle}
            filters={filters}
            setFilters={setFilters}
          />
          <DrawerMenu activeOptionId={6} activeSubsectionId={1} />

          <Grid
            onClick={() => dispatch(closeMenu())}
            container
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              paddingBlockStart: "40px",
            }}
          >
            <Tabs
              selectedTabClassName={
                theme.palette.mode === "dark"
                  ? "react-tabs__tab--selected-dark"
                  : "react-tabs__tab--selected-light"
              }
              style={{ minWidth: "100%" }}
            >
              <TabList style={{ display: "flex", justifyContent: "center" }}>
                <Tab>Categoría</Tab>
                <Tab>Producto</Tab>
                <Tab>Precio</Tab>
              </TabList>

              <TabPanel>
                <ReportingProfitabilityCategoryTableView
                  experimentsData={metricsData?.experiments}
                  userInfo={userInfo}
                  finishedExperimentsData={finishedExperiments}
                />
              </TabPanel>
              <TabPanel>
                <ReportingProfitabilityProductTableView
                  experimentsData={metricsData?.experiments}
                  userInfo={userInfo}
                  finishedExperimentsData={finishedExperiments}
                />
              </TabPanel>
              <TabPanel>
                <ReportingProfitabilityPriceTableView
                  experimentsData={metricsData?.experiments}
                  userInfo={userInfo}
                  finishedExperimentsData={finishedExperiments}
                />
              </TabPanel>
            </Tabs>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MyReportingProfitability;
