import React, { useState } from "react";
import {
  AppBar,
  Avatar,
  Grid,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { ReactComponent as MymaLogo } from "../../assets/images/myma-icon.svg";
import { useNavigate } from "react-router-dom";
import LogoutDialog from "./logout-dialog.component";
// import { ReactComponent as AlertsIcon } from "../../assets/images/notifications-icon.svg";
// import { ReactComponent as UnseenAlertsIcon } from "../../assets/images/notifications-new-icon.svg";
import { useDarkMode } from "../../context/darkModeContext";
import { DarkModeToggleButton } from "./darkModeToggle.component";

const HeaderContainer = styled(AppBar)(({ theme }) => ({
  width: "100%",
  height: "7rem",
  backgroundColor: theme.palette.header.main,
  padding: "15px 0px",
  display: "flex",
  position: "fixed",
  zIndex: 1000,
  backgroundImage: "none",
}));

function getInitials(str) {
  if (!str) {
    return "OP";
  }
  let words = str.split(" ");
  let initials = [];
  for (let word of words) {
    let initial = word.charAt(0).toUpperCase();
    initials.push(initial);
  }
  return initials.join("");
}

const Header = ({ title, longTitle, disableCart }) => {
  const theme = useTheme();
  const { toggleDarkMode, darkMode } = useDarkMode();
  const name = JSON.parse(localStorage.getItem("userInfo"))?.name;
  const customerName = JSON.parse(
    localStorage.getItem("userInfo")
  )?.customer_name;
  const avatar = getInitials(name);
  const navigate = useNavigate();
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  // const [notifications, setNotifications] = useState(false);
  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("userInfo");
    navigate("/login");
  };

  return (
    <HeaderContainer>
      <Grid
        container
        sx={{
          display: "flex",
          height: "100%",
          justifyContent: "space-between",
          paddingInline: "0.5rem",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginInline: "2.2rem",
            }}
          >
            <DarkModeToggleButton
              checked={!darkMode}
              onChange={toggleDarkMode}
            />
          </Grid>
        </Grid>
        <Grid
          item
          sx={{ display: "flex", justifyContent: "space-around" }}
          xs={12}
        >
          <Grid
            item
            xs={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MymaLogo fill="#EA572E" />
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              fontSize="1.2rem"
              lineHeight="1.25rem"
              textAlign={"center"}
              color={theme.palette.header.title}
              sx={{ width: "100%", fontWeight: 800 }}
            >
              {customerName}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant={longTitle ? "h6" : "h5"}
              textAlign={"center"}
              color={theme.palette.header.title}
              sx={{ width: "100%", fontWeight: 800 }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            {/* <Grid
              item
              sx={{
                width: "40px",
                height: "40px",
                border: "1px solid #FFF",
                borderRadius: "50%",
                backgroundColor: "#D9D9D9",
                padding: "0.3rem",
                opacity: "85%",
              }}
            >
              {!notifications ? (
                <AlertsIcon
                  onClick={() => setNotifications(!notifications)}
                  style={{
                    marginTop: "2px",
                    width: "100%",
                    fill: "#000",
                  }}
                />
              ) : (
                <UnseenAlertsIcon
                  onClick={() => setNotifications(!notifications)}
                  style={{
                    marginTop: "2px",
                    width: "100%",
                    fill: "#000",
                  }}
                />
              )}
            </Grid> */}
            <Avatar
              onClick={() => setOpenLogoutDialog(true)}
              sx={{
                border: "1px solid #FFF",
                backgroundColor: "#D9D9D9",
                color: "#000",
                fontWeight: "bold",
                opacity: "85%",
                cursor: "pointer",
              }}
            >
              {avatar ?? "OP"}
            </Avatar>
          </Grid>
        </Grid>
      </Grid>
      <LogoutDialog
        open={openLogoutDialog}
        handleClose={() => setOpenLogoutDialog(false)}
        handleLogout={handleLogout}
      />
    </HeaderContainer>
  );
};

export default Header;
