import React from "react";
import { Grid, Paper, Typography, useTheme } from "@mui/material";

import FormatPeriod from "../../utils/formatPeriod";

const BigCardComingSoon = ({
  metricsData,
  bigCardData,
  period,
  experimentsPage,
}) => {
  const theme = useTheme();

  return (
    <Paper
      elevation={5}
      sx={{
        display: "flex",
        borderRadius: "10px",
        minWidth: "275px",
        maxWidth: "500px",
        marginInline: "1rem",
        marginBlockStart: "1rem",
        marginBlockEnd: "2rem",
      }}
    >
      <Grid container>
        <Grid
          container
          sx={{
            backgroundColor: experimentsPage ? "#00A87C" : "#663B73",
            borderRadius: "10px 10px 0px 0px",
            padding: "0.5rem",
            display: "flex",
          }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#FCFCF7",
                fontSize: "24px",
              }}
            >
              {bigCardData.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: "normal",
                color: "#A9A9A9",
                fontSize: "18px",
              }}
            >
              {bigCardData.subtitle}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            padding: "0.5rem 2rem",
            display: "flex",
            marginBlockEnd: "2rem",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "9rem",
            }}
          >
            <Typography
              variant="h4"
              color={theme.palette.mode === "dark" ? "#fff" : "#000"}
            >
              Próximamente...
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            minHeight: "35px",
            backgroundColor: experimentsPage ? "#00A87C" : "#663B73",
            borderRadius: "0px 0px 10px 10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {metricsData?.period?.current?.startDate && (
            <Typography color="#A9A9A9">
              <FormatPeriod
                period={period}
                startDate={metricsData.period.current.startDate}
                endDate={metricsData.period.current.endDate}
              />
            </Typography>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BigCardComingSoon;
