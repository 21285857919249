export function generateFakeRowData(numCategories) {
  const fakeRowData = [];

  // Generar la fila TOTAL
  const totalRow = {
    id: "TOTAL",
    ACT_U: getRandomInt(1000, 5000),
    ACT_PRICE: getRandomInt(5000, 15000),
    ACT_COST: getRandomInt(5000, 10000),
    ACT_MG$: getRandomInt(0, 5000),
    "ACT_MG%": getRandomFloat(25, 100) + "%",
    ACT_SHARE: getRandomFloat(5, 15) + "%",
    M1_U: getRandomInt(1000, 5000),
    M1_PRICE: getRandomInt(200, 500),
    M1_COST: getRandomInt(200, 500),
    M1_MG$: getRandomInt(0, 200),
    "M1_MG%": getRandomFloat(25, 100) + "%",
    M1_SHARE: getRandomFloat(0, 10) + "%",
    Q_U: getRandomInt(1000, 5000),
    Q_PRICE: getRandomInt(2000, 8000),
    Q_COST: getRandomInt(1500, 6000),
    Q_MG$: getRandomInt(0, 2000),
    "Q_MG%": getRandomFloat(25, 100) + "%",
    Q_SHARE: getRandomFloat(5, 15) + "%",
    Y_U: getRandomInt(1000, 5000),
    Y_PRICE: getRandomInt(1000, 5000),
    Y_COST: getRandomInt(100, 1000),
    Y_MG$: getRandomInt(0, 1000),
    "Y_MG%": getRandomFloat(25, 100) + "%",
    Y_SHARE: getRandomFloat(0.5, 5) + "%",
  };
  fakeRowData.push(totalRow);

  // Generar filas de CATEG
  for (let i = 1; i <= numCategories; i++) {
    const categoryRow = {
      id: `CATEG ${i}`,
      ACT_U: getRandomInt(500, 3000),
      ACT_PRICE: getRandomInt(8000, 15000),
      ACT_COST: getRandomInt(5000, 10000),
      ACT_MG$: getRandomInt(1000, 5000),
      "ACT_MG%": getRandomFloat(25, 100) + "%",
      ACT_SHARE: getRandomFloat(5, 15) + "%",
      M1_U: getRandomInt(500, 3000),
      M1_PRICE: getRandomInt(200, 500),
      M1_COST: getRandomInt(200, 500),
      M1_MG$: getRandomInt(0, 1000),
      "M1_MG%": getRandomFloat(25, 100) + "%",
      M1_SHARE: getRandomFloat(0, 10) + "%",
      Q_U: getRandomInt(500, 3000),
      Q_PRICE: getRandomInt(2000, 8000),
      Q_COST: getRandomInt(1500, 6000),
      Q_MG$: getRandomInt(0, 2000),
      "Q_MG%": getRandomFloat(25, 100) + "%",
      Q_SHARE: getRandomFloat(5, 15) + "%",
      Y_U: getRandomInt(500, 3000),
      Y_PRICE: getRandomInt(1000, 5000),
      Y_COST: getRandomInt(100, 1000),
      Y_MG$: getRandomInt(0, 1000),
      "Y_MG%": getRandomFloat(25, 100) + "%",
      Y_SHARE: getRandomFloat(0.5, 5) + "%",
    };
    fakeRowData.push(categoryRow);
  }

  return fakeRowData;
}

// Función auxiliar para obtener un entero aleatorio en un rango
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Función auxiliar para obtener un número de punto flotante aleatorio en un rango
function getRandomFloat(min, max) {
  return (Math.random() * (max - min) + min).toFixed(2);
}
