import React from "react";
import { useEffect, useState } from "react";
import Header from "../../components/header/header.component";
import BigCard from "../../components/big-card/big-card.component";
import Section from "../../components/section/section.component";
import Metrics from "../../components/metrics/metrics.component";
import axios from "axios";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import Filters from "../../components/filters/filters.component";

import {
  bigCardFirstSlider,
  bigCardSecondSlider,
  bigCardThirdSlider,
} from "../../utils/fakeData";
import TrafficLight from "../../components/traffic-light/traffic-light.component";
import { getUserInfo } from "../../services/userInfo.service";
import "swiper/css";
import DrawerMenu from "../../components/drawer/drawer.component";
import FormatPeriod from "../../utils/formatPeriod";
import { useDispatch } from "react-redux";
import { closeMenu } from "../../store/features/menuDrawer/menuSlice";

const HomeBody = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const loginToken = localStorage.getItem("access_token");
  const userInfo = getUserInfo();

  const [metricsData, setMetricsData] = useState({});

  const [period, setPeriod] = useState("w");

  const [subtitle, setSubtitle] = useState("");

  const [filters, setFilters] = useState({
    groups: "default", //label: "Grupo"
    clusters: "default", //label: "Posición"
    locations: "default", //label: "Ubicación"
    categories: "default", //label: "Tipo"
    machines: "default", //label: "Máquinas"
  });

  const fetchMetrics = async () => {
    try {
      if (userInfo) {
        const { data } = await axios.get(
          `https://data.myma.space/api/metrics?clientId=${userInfo.customer_id}&group=${filters.groups}&period=${period}&storeClusterId=${filters.clusters}&location=${filters.locations}&category=${filters.categories}`
        );
        setMetricsData(data);
      }
    } catch (error) {
      // Error handler
      console.error("Error 500 en la solicitud de métricas:", error);
    }
  };

  useEffect(
    () => {
      // Verify if access token isn't null
      if (loginToken !== null) {
        // Verify if axios headers are setted.
        if (axios.defaults.headers.common["Authorization"]) {
          fetchMetrics();
        } else {
          // If axios headers aren't setted, wait untill they are.
          const interval = setInterval(() => {
            if (axios.defaults.headers.common["Authorization"]) {
              clearInterval(interval);
              fetchMetrics();
            }
          }, 100); // Verification interval.
        }
      }
    },
    // eslint-disable-next-line
    [period, filters, loginToken]
  );

  useEffect(() => {
    // Filter properties which aren't "default" or "all"
    const filteredProperties = Object.entries(filters)
      .filter(
        ([key, value]) =>
          value !== "default" && value !== "all" && key !== "machines"
      )
      .map(([key, value]) => value);

    // Build subtitle with filtered properties
    const newSubtitle = filteredProperties.join(" ");

    // Update subtitle state
    setSubtitle(newSubtitle || null); // If newSubtitle is empty , set it to null
  }, [filters]);

  return (
    <>
      <Header
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ fontWeight: "normal" }}>Mi</div>
            <div style={{ fontWeight: "bold" }}>&nbsp;Portfolio</div>
          </div>
        }
        avatar="FB"
      />
      <Box>
        <Grid
          sx={{
            height: "100%",
            paddingBlockStart: "7rem",
            paddingInlineStart: "50px",
          }}
          container
        >
          <Filters
            filters={filters}
            setFilters={setFilters}
            machinesSelectorDisabled
            period={period}
            setPeriod={setPeriod}
          />
          <DrawerMenu activeOptionId={0} />
          <Grid
            container
            sx={{
              paddingBlockStart: "2.5rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: theme.palette.purple.main,
                color: "#fff",
                padding: "2px 10px",
                borderRadius: "5px",
                marginBlockStart: "0.5rem",
              }}
            >
              <Typography>
                <FormatPeriod
                  period={period}
                  startDate={metricsData?.period?.current?.startDate}
                  endDate={metricsData?.period?.current?.endDate}
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid
            onClick={() => dispatch(closeMenu())}
            container
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <BigCard
                bigCardData={{
                  ...bigCardFirstSlider,
                  subtitle: subtitle,
                }}
                metricsData={metricsData}
                logo="trendlinechart"
                slideIndex="0"
              />
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {" "}
              <BigCard
                bigCardData={{ ...bigCardSecondSlider, subtitle: subtitle }}
                metricsData={metricsData}
                logo="shopcart"
                slideIndex="1"
              />
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {" "}
              <BigCard
                bigCardData={{ ...bigCardThirdSlider, subtitle: subtitle }}
                metricsData={metricsData}
                logo="trendlinechart"
                slideIndex="2"
              />
            </Grid>
            <Grid container sx={{ marginBlockStart: "1rem" }}>
              <Grid item xs={6}>
                <Section title="KPIS Relevantes" />
                <div style={{ marginBlock: "0.5rem" }}>
                  <Metrics slideIndex="0" metricsData={metricsData} />
                  <Metrics slideIndex="1" metricsData={metricsData} />
                </div>
              </Grid>
              <Grid item xs={6}>
                <Section title="Rendimiento Semanal" header="b" />
                <div style={{ marginBlockEnd: "0.5rem" }}>
                  {metricsData?.positions?.map((element, id) => {
                    return <TrafficLight key={id} {...element} />;
                  })}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
const Home = () => {
  return (
    <>
      <HomeBody />
    </>
  );
};

export default Home;
