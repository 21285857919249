import React from "react";
import { useEffect, useState } from "react";
import Header from "../../components/header/header.component";
import BigCard from "../../components/big-card/big-card.component";
import Section from "../../components/section/section.component";
import Metrics from "../../components/metrics/metrics.component";
import axios from "axios";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import Filters from "../../components/filters/filters.component";
import {
  mediumCardDataLoserOfMonth,
  mediumCardDataNegative,
  mediumCardDataPositive,
  mediumCardDataWinnerOfMonth,
} from "../../utils/fakeData";
import {
  bigCardFirstSlider,
  bigCardSecondSlider,
  bigCardThirdSlider,
} from "../../utils/fakeData";
import MediumCard from "../../components/medium-card/medium-card.component";

import { getUserInfo } from "../../services/userInfo.service";
import "swiper/css";
import DrawerMenu from "../../components/drawer/drawer.component";
import FormatPeriod from "../../utils/formatPeriod";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import AlertSmallCard from "../../components/alerts-small-card/alerts-small-card.component";
import { closeMenu } from "../../store/features/menuDrawer/menuSlice";
import { useDispatch } from "react-redux";

const MyMachines = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const loginToken = localStorage.getItem("access_token");
  const userInfo = getUserInfo();

  const [metricsData, setMetricsData] = useState({});

  const [period, setPeriod] = useState("w");

  const [subtitle, setSubtitle] = useState("");

  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [alertIdToDelete, setAlertIdToDelete] = useState(null);

  const [filters, setFilters] = useState({
    groups: "default", //label: "Grupo"
    clusters: "default", //label: "Posición"
    locations: "default", //label: "Ubicación"
    categories: "default", //label: "Tipo"
    machines: "default", //label: "Máquinas"
  });

  const fetchMetrics = async () => {
    if (filters.machines !== "default") {
      try {
        if (userInfo) {
          const { data } = await axios.get(
            `https://data.myma.space/api/machines/${filters.machines}?clientId=${userInfo.customer_id}&period=${period}&storeClusterId=${filters.clusters}&location=${filters.locations}&category=${filters.categories}`
          );
          setMetricsData(data);
        }
      } catch (error) {
        // Error handler
        console.error(
          "Error 500 en la solicitud de métricas de máquinas:",
          error
        );
      }
    }
  };

  useEffect(
    () => {
      // Verify if access token isn't null
      if (loginToken !== null) {
        // Verify if axios headers are setted.
        if (axios.defaults.headers.common["Authorization"]) {
          fetchMetrics();
        } else {
          // If axios headers aren't setted, wait untill they are.
          const interval = setInterval(() => {
            if (axios.defaults.headers.common["Authorization"]) {
              clearInterval(interval);
              fetchMetrics();
            }
          }, 100); // Verification interval.
        }
      }
    },
    // eslint-disable-next-line
    [period, filters, loginToken]
  );

  const handleDeleteAlert = (alertId) => {
    const apiUrl = `https://data.myma.space/api/notifications/${alertId}/archive?clientId=${userInfo.customer_id}`;

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (userInfo) {
      axios
        .post(apiUrl, {}, config)
        .then((response) => {
          // console.log("Alert archived successfully:", response.data);
          setConfirmDeleteDialogOpen(false);
          fetchMetrics();
        })
        .catch((error) => {
          // console.error("Error archieving alert:", error);
        });
    }
  };

  const sliderSettings = {
    spaceBetween: -15,
    slidesPerView: 1.06,
  };
  const alertSliderSettings = {
    spaceBetween: -15,
    slidesPerView: Math.min(metricsData?.notifications?.length || 1.06, 3.06),
  };

  return (
    <>
      <Header
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ fontWeight: "normal" }}>Mis</div>
            <div style={{ fontWeight: "bold" }}>&nbsp;Máquinas</div>
          </div>
        }
        avatar="FB"
      />
      <Box>
        <Grid
          sx={{
            height: "100%",
            paddingBlockStart: "7rem",
            paddingInlineStart: "50px",
          }}
          container
        >
          <Dialog
            open={confirmDeleteDialogOpen}
            onClose={() => setConfirmDeleteDialogOpen(false)}
          >
            <DialogTitle>Confirmar eliminación</DialogTitle>
            <DialogContent>
              <DialogContentText>
                ¿Estás seguro de que deseas eliminar ésta notificación?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setConfirmDeleteDialogOpen(false)}
                style={{ color: theme.palette.divider.main }}
              >
                Cancelar
              </Button>
              <Button
                onClick={async () => {
                  handleDeleteAlert(alertIdToDelete);
                }}
                style={{ color: theme.palette.divider.main }}
              >
                Eliminar
              </Button>
            </DialogActions>
          </Dialog>
          <Filters
            setBigCardSubtitle={setSubtitle}
            filters={filters}
            setFilters={setFilters}
            period={period}
            setPeriod={setPeriod}
          />
          <DrawerMenu activeOptionId={1} />
          <Grid
            container
            sx={{
              paddingBlockStart: "2.5rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: theme.palette.purple.main,
                color: "#fff",
                padding: "2px 10px",
                borderRadius: "5px",
                marginBlockStart: "0.5rem",
              }}
            >
              <Typography>
                <FormatPeriod
                  period={period}
                  startDate={metricsData?.period?.current?.startDate}
                  endDate={metricsData?.period?.current?.endDate}
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid
            onClick={() => dispatch(closeMenu())}
            container
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <BigCard
                bigCardData={{
                  ...bigCardFirstSlider,
                  subtitle: subtitle,
                }}
                metricsData={metricsData}
                logo="trendlinechart"
                slideIndex="0"
              />
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {" "}
              <BigCard
                bigCardData={{ ...bigCardSecondSlider, subtitle: subtitle }}
                metricsData={metricsData}
                logo="shopcart"
                slideIndex="1"
              />
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {" "}
              <BigCard
                bigCardData={{ ...bigCardThirdSlider, subtitle: subtitle }}
                metricsData={metricsData}
                logo="trendlinechart"
                slideIndex="2"
              />
            </Grid>
            <Grid container sx={{ marginBlockStart: "1rem" }}>
              <Grid item xs={6}>
                <Section title="KPIS Relevantes" />
                <div style={{ marginBlock: "0.5rem" }}>
                  <Metrics slideIndex="0" metricsData={metricsData} />
                  <Metrics slideIndex="1" metricsData={metricsData} />
                </div>
              </Grid>
              <Grid item xs={6}>
                <Section title="Insights" />
                <div
                  style={{
                    marginBlock: "0.5rem",
                  }}
                >
                  <Swiper {...sliderSettings}>
                    <SwiperSlide>
                      <MediumCard
                        variant={"a"}
                        data={{
                          ...mediumCardDataPositive,
                          list: metricsData?.insights?.[0]?.insights,
                        }}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <MediumCard
                        variant={"d"}
                        data={{
                          ...mediumCardDataWinnerOfMonth,
                          product:
                            metricsData?.insights?.[0]?.insights?.[0]?.name,
                          total:
                            metricsData?.insights?.[0]?.insights?.[0]?.total,
                        }}
                      />
                    </SwiperSlide>
                  </Swiper>
                  <Swiper {...sliderSettings}>
                    <SwiperSlide>
                      <MediumCard
                        variant={"a"}
                        data={{
                          ...mediumCardDataNegative,
                          list: metricsData?.insights?.[1]?.insights,
                        }}
                      />
                    </SwiperSlide>

                    <SwiperSlide>
                      <MediumCard
                        variant={"d"}
                        data={{
                          ...mediumCardDataLoserOfMonth,
                          product:
                            metricsData?.insights?.[1]?.insights?.[2]?.name,
                          total:
                            metricsData?.insights?.[1]?.insights?.[2]?.total,
                        }}
                      />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </Grid>
            </Grid>
            <Grid container sx={{ marginBlockStart: "1rem" }}>
              <Grid item xs={12}>
                {metricsData?.notifications?.length !== 0 && (
                  <Section title="Notificaciones" />
                )}
                <Swiper {...alertSliderSettings}>
                  {metricsData?.notifications
                    ?.slice()
                    .reverse()
                    .map((element) => {
                      if (!element.archived) {
                        return (
                          <SwiperSlide key={element.id}>
                            <AlertSmallCard
                              setConfirmDeleteDialogOpen={
                                setConfirmDeleteDialogOpen
                              }
                              setAlertIdToDelete={setAlertIdToDelete}
                              {...element}
                            />
                          </SwiperSlide>
                        );
                      } else {
                        return null;
                      }
                    })}
                </Swiper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MyMachines;
