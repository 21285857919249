import { Card, Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import TrelloCardActionComponent from "./card-action/trello-card-action.component";
import TrelloCardProblemComponent from "./card-problem/trello-card-problem.componen";
import { experimentsEquivalency } from "../../../experimentsEquivalency";
const formatNumber = (num) => {
  return `[${num?.toString().padStart(4, "0")}]`;
};
const TrelloCard = ({ experimentData }) => {
  const theme = useTheme();
  return (
    <Card
      variant="elevation"
      sx={{
        backgroundColor: theme.palette.background.paper,
        padding: "0.7rem",
        marginBlockStart: "0.7rem",
        minHeight: "10rem",
      }}
    >
      <Grid
        item
        sx={{
          display: "flex",
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
          EXPERIMENTO
        </Typography>
        &nbsp;
        <Typography
          variant="body2"
          sx={{ color: "#EA572E", fontWeight: "bold" }}
        >
          {formatNumber(experimentData?.id)}
        </Typography>
        <Typography
          sx={{
            border: "1px solid #EA572E",
            color: theme.palette.label.title.main,
            backgroundColor: theme.palette.label.background.main,
            borderRadius: "5px",
            padding: "2px 7px",
            fontSize: "0.5rem",
            marginInlineStart: "0.5rem",
            marginBlock: "0.1rem 0.5rem",
            lineHeight: "0.5rem",
          }}
        >
          {
            experimentsEquivalency?.[experimentData?.experimentType?.name]
              ?.shortLabel
          }
        </Typography>
      </Grid>
      <Grid item>
        <Tabs
          selectedTabClassName={
            theme.palette.mode === "dark"
              ? "react-tabs__tab--selected-dark"
              : "react-tabs__tab--selected-light"
          }
          style={{ minWidth: "100%" }}
        >
          <TabList
            style={{
              display: "flex",
              justifyContent: "start",
              height: "1.5rem",
            }}
          >
            <Tab
              style={{
                padding: 0,
                paddingInline: "0.2rem",
                fontSize: "0.8rem",
              }}
            >
              Problema
            </Tab>
            <Tab
              style={{
                padding: 0,
                paddingInline: "0.2rem",
                fontSize: "0.8rem",
              }}
            >
              Acción
            </Tab>
          </TabList>

          <TabPanel>
            <TrelloCardProblemComponent experimentData={experimentData} />
          </TabPanel>

          <TabPanel>
            <TrelloCardActionComponent experimentData={experimentData} />
          </TabPanel>
        </Tabs>
      </Grid>
    </Card>
  );
};

export default TrelloCard;
