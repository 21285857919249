import { useTheme } from "@mui/material";
import React from "react";

export default function ProblemTypeRender({ value }) {
  let labelColor = {
    "Baja Rotac. sin Desc.": "#FF5733AA",
    "Baja Rotac. con Desc.": "#FFB6C1AA",
    "Menos de 5 Ventas": "#FF3333AA",
    "Bajo Stock": "#FF8C6AAA",
    "Precio Sugerido Erroneo": "#FFA07AAA",
  };

  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "70%",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          backgroundColor: labelColor[value],
          borderRadius: "1rem",
          display: "flex",
          width: "auto",
          justifyContent: "center",
          alignItems: "center",
          paddingInline: "0.5rem",
          color: theme.palette.table.text,
        }}
      >
        {value}
      </div>
    </div>
  );
}
