export const customSelectStyles = (color, backgroundColor, borderColor) => ({
  minWidth: "85px",
  width: "9rem",
  height: "1.5rem",
  fontSize: "0.6rem",
  fontWeight: "bold",
  textAlign: "center",
  color: color,
  backgroundColor: backgroundColor,

  ".MuiSelect-select": {
    padding: "0 !important",
  },
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: borderColor,
  },
  ".css-qiwgdb.MuiSelect-select": {
    textOverflow: "clip",
  },
});

export default customSelectStyles;
