import { Grid, Paper, Typography, useTheme } from "@mui/material";
import React from "react";

const MetricItem = ({ value, label, symbol }) => {
  const theme = useTheme();
  return (
    <Grid
      item
      sx={{
        display: "flex",
      }}
      xs={12}
    >
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          // border: "2px solid #727272",
          borderRadius: "1rem",
          alignItems: "center",
          justifyContent: "center",
          paddingInline: "1rem",
          minHeight: "100%",
          minWidth: "100%",
        }}
      >
        <Grid item xs={12} sx={{ display: "flex" }}>
          <Typography
            sx={{
              fontSize: "2rem",
              lineHeight: "2rem",
              fontWeight: "bold",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              marginTop: "1.5rem",
            }}
          >
            {`${value}`}
          </Typography>
          {symbol && (
            <Typography
              sx={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                marginTop: "2rem",
                display: "flex",
              }}
            >
              {`${symbol}`}
            </Typography>
          )}
        </Grid>
        <Grid item sx={{ display: "flex", alignItems: "center" }} xs={12}>
          <Typography
            sx={{
              fontSize: "1rem",
              textAlign: "center",
              lineHeight: "1rem",
              color: theme.palette.divider.main,
            }}
          >
            {label}
          </Typography>
        </Grid>
      </Paper>
    </Grid>
  );
};

const Metrics = ({ metricsData, slideIndex }) => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          marginInline: "1rem",
          marginBlockEnd: "1rem",
          display: "flex",
          height: "8rem",
          paddingBlock: "0rem",
          gap: "1rem",
          boxSizing: "content-box",
        }}
      >
        <MetricItem
          symbol={slideIndex === "0" && metricsData?.currency?.symbol}
          value={
            slideIndex === "0"
              ? parseFloat(metricsData?.kpis?.[0]?.value)
                  .toFixed(2)
                  .replace(".", ",")
              : Math.round(metricsData?.kpis?.[3]?.value)
          }
          label={
            slideIndex === "0"
              ? "Ticket Promedio"
              : "Ordenes Promedio x Cliente"
          }
        />
        <MetricItem
          symbol={slideIndex === "0" && metricsData?.currency?.symbol}
          value={
            slideIndex === "0"
              ? Math.round(metricsData?.kpis?.[1]?.value)
              : Math.round(metricsData?.kpis?.[4]?.value)
          }
          label={
            slideIndex === "0"
              ? "Ingresos Medios x Tienda"
              : "Productos x Orden"
          }
        />
        <MetricItem
          symbol={slideIndex === "0" && metricsData?.currency?.symbol}
          value={
            slideIndex === "0"
              ? Math.round(metricsData?.kpis?.[2]?.value)
              : Math.round(metricsData?.kpis?.[5]?.value)
          }
          label={
            slideIndex === "0"
              ? "Ingresos Medios x Cliente"
              : " Clientes Únicos"
          }
        />
      </Grid>
    </Grid>
  );
};

export default Metrics;
