export function generateFakeRowData(numProducts, numStores) {
  const fakeRowData = [];

  // Generar filas de productos
  for (let i = 1; i <= numProducts; i++) {
    const categoryRow = {
      id: `Producto ${i}`,
      "share%": getRandomFloat(25, 100) + "%",
    };

    // Generar datos para cada tienda
    for (let j = 1; j <= numStores; j++) {
      categoryRow[`store${j}`] = getRandomInt(8000, 15000);
    }

    fakeRowData.push(categoryRow);
  }

  return fakeRowData;
}

// Función auxiliar para obtener un entero aleatorio en un rango
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Función auxiliar para obtener un número de punto flotante aleatorio en un rango
function getRandomFloat(min, max) {
  return (Math.random() * (max - min) + min).toFixed(2);
}
