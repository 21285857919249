import { Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { ReactComponent as ExperimentsIcon } from "../../../../../../assets/images/experiments-icon.svg";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { experimentsEquivalency } from "../../../../experimentsEquivalency";

// const formatDatesRange = (dateFrom, dateTo) => {
//   const formatDate = (dateString) => {
//     if (!dateString) return "-/-";
//     const date = new Date(dateString);
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     return `${day}/${month}`;
//   };

//   const formattedFrom = formatDate(dateFrom);
//   const formattedTo = formatDate(dateTo);

//   return `Inicio: ${formattedFrom} Fin: ${formattedTo}.`;
// };

const formatDatesRange = (dateFrom, dateTo) => {
  const formatDate = (dateString) => {
    if (!dateString) return "-/-";
    // Extraer el día y el mes del formato 'YYYY-MM-DDTHH:MM:SS.SSSZ'
    // eslint-disable-next-line
    const [year, month, day] = dateString.split("T")[0].split("-");
    return `${day}/${month}`;
  };

  const formattedFrom = formatDate(dateFrom);
  const formattedTo = formatDate(dateTo);

  return `Inicio: ${formattedFrom} Fin: ${formattedTo}.`;
};

const TrelloCardActionComponent = ({ experimentData }) => {
  const theme = useTheme();
  return (
    <>
      <Grid item sx={{ height: 3 / 8, display: "flex" }}>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          xs={1}
        >
          <ExperimentsIcon
            fill={theme.palette.mode === "dark" ? "#fff" : "#000"}
            style={{ width: "1.3rem" }}
          />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            marginInlineStart: "0.2rem",
          }}
          item
          xs={11}
        >
          <Typography
            sx={{
              fontSize: "0.68rem",
              lineHeight: "0.7rem",
            }}
          >
            {experimentsEquivalency[
              experimentData?.experimentType?.name
            ]?.actionDescription({
              productName: experimentData?.product?.name,
              productIdealStock: experimentData?.product?.idealStock,
              newProductName: experimentData?.experimentType?.newProduct?.name,
              newProductIdealStock:
                experimentData?.experimentType?.newProduct?.idealStock,
              discountPercentage:
                experimentData?.experimentType?.discountPercent,
              price: experimentData?.experimentType?.price,
              newPrice: experimentData?.experimentType?.newPrice,
              firstProduct: experimentData?.experimentType?.firstProduct?.name,
              secondProduct:
                experimentData?.experimentType?.secondProduct?.name,
              comboPrice: experimentData?.status?.avgPrice,
              RColor: theme.palette.alerts.error.main,
              GColor: theme.palette.alerts.good.main,
              BColor: theme.palette.alerts.info.main,
            })}
          </Typography>
        </Grid>
      </Grid>
      <Grid item sx={{ height: 3 / 8, display: "flex" }}>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          xs={1}
        >
          <CalendarMonthOutlinedIcon
            fill={theme.palette.mode === "dark" ? "#fff" : "#000"}
            fontSize="medium"
          />
        </Grid>
        <Grid sx={{ display: "flex", alignItems: "center" }} item xs={11}>
          <Typography
            sx={{
              fontSize: "0.7rem",
              lineHeight: "0.7rem",
              fontWeight: "bold",
              marginInlineStart: "0.2rem",
            }}
          >
            {formatDatesRange(
              experimentData?.period?.start,
              experimentData?.period?.end
            )}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default TrelloCardActionComponent;
