import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  Popper,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { ReactComponent as MoreIcon } from "../../../src/assets/images/vert-dots-icon.svg";
import { ReactComponent as DownloadIcon } from "../../../src/assets/images/cloud-download-icon.svg";
import { ReactComponent as FilterIcon } from "../../../src/assets/images/filter-icon.svg";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import DatePicker from "react-multi-date-picker";
import { months, weekdays } from "../../utils/filterByDateRange";
import { createDatePickerStyles } from "./datepicker-styles";

ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

const TableHeader = ({
  colDefs,
  setColDefs,
  title,
  gridRef,
  fileName,
  dateFilterValues,
  setDateFilterValues,
}) => {
  const theme = useTheme();
  const [columnAnchorEl, setColumnAnchorEl] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const handleColumnClick = (event) => {
    setColumnAnchorEl(columnAnchorEl ? null : event.currentTarget);
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(filterAnchorEl ? null : event.currentTarget);
  };

  const openColumnPopper = Boolean(columnAnchorEl);
  const openFilterPopper = Boolean(filterAnchorEl);
  const handleColumnToggle = (field) => {
    const updatedCols = colDefs.map((col) =>
      col.field === field ? { ...col, hide: !col.hide } : col
    );
    setColDefs(updatedCols);
  };

  const onBtnExport = useCallback(
    () => {
      gridRef.current.api.exportDataAsCsv({
        suppressQuotes: true,
        columnSeparator: ";",
        fileName: `${fileName}.csv`,
      });
    },
    // eslint-disable-next-line
    []
  );
  return (
    <Grid
      container
      sx={{
        padding: "16px 24px",
        height: "4rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {/* Column Popper Start */}
      <Popper
        open={openColumnPopper}
        anchorEl={columnAnchorEl}
        placement="bottom-start"
        sx={{ zIndex: 100 }}
        disablePortal
      >
        <ClickAwayListener onClickAway={() => setColumnAnchorEl(null)}>
          <Grid
            container
            sx={{
              bgcolor: "background.paper",
              border: "1px solid #ccc",
              padding: "0.9rem",
              minWidth: "260px",
              maxWidth: "450px",
              display: "flex",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                padding: "8px 12px",
                marginBlockEnd: "4px",
                height: "2.2rem",
              }}
            >
              <Typography fontSize="1rem">Columnas:</Typography>
            </Grid>
            {colDefs.map((column) => (
              <Grid
                key={column.field}
                item
                xs={6}
                sx={{
                  padding: "4px 12px",
                  height: "34px",
                  display: "flex",
                  gap: 1,
                }}
              >
                <Switch
                  sx={{
                    "& .MuiSwitch-switchBase": {
                      "&.Mui-checked": {
                        "+ .MuiSwitch-track": {
                          backgroundColor: theme.palette.purple.main,
                        },
                        ".MuiSwitch-thumb": {
                          backgroundColor: theme.palette.purple.main,
                        },
                      },
                    },
                  }}
                  size="small"
                  checked={!column.hide}
                  onChange={() => handleColumnToggle(column.field)}
                />
                <Typography>{column.field}</Typography>
              </Grid>
            ))}
          </Grid>
        </ClickAwayListener>
      </Popper>
      {/* Column Popper End  */}

      {/* Filter Popper Start */}
      <Popper
        open={openFilterPopper}
        anchorEl={filterAnchorEl}
        placement="bottom-end"
        sx={{ zIndex: 100 }}
        disablePortal
      >
        <ClickAwayListener onClickAway={() => setFilterAnchorEl(null)}>
          <Grid
            container
            sx={{
              bgcolor: "background.paper",
              border: "1px solid #ccc",
              padding: "0.9rem",
              minWidth: "260px",
              maxWidth: "450px",
              display: "flex",
            }}
          >
            <Grid
              item
              xs={12}
              sx={createDatePickerStyles(
                theme.palette.divider.main,
                theme.palette.primary.dark,
                theme.palette.filters.main
              )}
            >
              <DatePicker
                value={dateFilterValues}
                onChange={setDateFilterValues}
                range
                rangeHover
                style={{ textAlign: "center" }}
                weekDays={weekdays}
                months={months}
                showOtherDays
                placeholder="Click para Filtrar"
                editable={false}
              />
              <Button
                sx={{ color: theme.palette.divider.main }}
                onClick={() => setDateFilterValues([])}
              >
                Limpiar Filtros
              </Button>
            </Grid>
          </Grid>
        </ClickAwayListener>
      </Popper>
      {/* Filter Popper End */}

      <Grid item sx={{ fontSize: "1.3rem" }}>
        {title}
      </Grid>
      <Grid
        item
        sx={{
          flexGrow: 0.9,
          display: "flex",
          justifyContent: "end",
          fontSize: "0.8rem",
          alignItems: "center",
          maxHeight: "100%",
          gap: "0.5rem",
        }}
      >
        {dateFilterValues && (
          <Tooltip
            sx={{ cursor: "pointer" }}
            onClick={handleFilterClick}
            title="Filtrar experimentos por Rango de Fechas"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: theme.palette.text.primary,
              }}
            >
              <IconButton sx={{ padding: 0.8 }}>
                <FilterIcon fill={theme.palette.divider.main} />
              </IconButton>
            </Box>
          </Tooltip>
        )}

        <Tooltip
          sx={{ cursor: "pointer" }}
          onClick={onBtnExport}
          title="Exportar formato CSV"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              color: theme.palette.text.primary,
            }}
          >
            <IconButton sx={{ padding: 0.8 }}>
              <DownloadIcon fill={theme.palette.divider.main} />
            </IconButton>
          </Box>
        </Tooltip>
        <Tooltip onClick={handleColumnClick} title="Elige que columnas mostrar">
          <IconButton sx={{ padding: 0 }}>
            <MoreIcon fill={theme.palette.divider.main} />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default TableHeader;
