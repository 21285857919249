export const statusColumnFilterParams = {
  filterOptions: [
    {
      displayKey: "empty",
      displayName: "Elige una opción",
      predicate: (_, cellValue) => true, // Retorna true para todas las filas
      numberOfInputs: 0,
    },
    {
      displayKey: "running", // Esta es la clave interna para la opción "contains"
      displayName: "Iniciado", // Nuevo nombre a mostrar para "contains"
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "Iniciado",
      numberOfInputs: 0,
    },
    {
      displayKey: "pending", // Esta es la clave interna para la opción "contains"
      displayName: "Pendiente", // Nuevo nombre a mostrar para "contains"
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "Pendiente",
      numberOfInputs: 0,
    },
    {
      displayKey: "finished", // Esta es la clave interna para la opción "contains"
      displayName: "Finalizado", // Nuevo nombre a mostrar para "contains"
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "Finalizado",
      numberOfInputs: 0,
    },
  ],
};
export const experimentTypeColumnFilterParams = {
  filterOptions: [
    {
      displayKey: "empty",
      displayName: "Elige una opción",
      predicate: (_, cellValue) => true, // Retorna true para todas las filas
      numberOfInputs: 0,
    },
    {
      displayKey: "promo_precio_unitario", // Esta es la clave interna para la opción "contains"
      displayName: "Promo Prec. Unit.", // Nuevo nombre a mostrar para "contains"
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "Promo Prec. Unit.",
      numberOfInputs: 0,
    },
    {
      displayKey: "promo_combo_simple", // Esta es la clave interna para la opción "contains"
      displayName: "Promo Comb. Simpl.", // Nuevo nombre a mostrar para "contains"
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "Promo Comb. Simpl.",
      numberOfInputs: 0,
    },
    {
      displayKey: "promo_combo_combinado", // Esta es la clave interna para la opción "contains"
      displayName: "Promo Comb. Combi.", // Nuevo nombre a mostrar para "contains"
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "Promo Comb. Combi.",
      numberOfInputs: 0,
    },
    {
      displayKey: "reemplazo_producto", // Esta es la clave interna para la opción "contains"
      displayName: "Reemplazo Prod.", // Nuevo nombre a mostrar para "contains"
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "Reemplazo Prod.",
      numberOfInputs: 0,
    },
    {
      displayKey: "ajuste_planograma", // Esta es la clave interna para la opción "contains"
      displayName: "Ajuste Planograma", // Nuevo nombre a mostrar para "contains"
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "Ajuste Planograma",
      numberOfInputs: 0,
    },
    {
      displayKey: "baja_precio_sugerido", // Esta es la clave interna para la opción "contains"
      displayName: "Baja Prec. Suger.", // Nuevo nombre a mostrar para "contains"
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "Baja Prec. Suger.",
      numberOfInputs: 0,
    },
    {
      displayKey: "alza_precio_sugerido", // Esta es la clave interna para la opción "contains"
      displayName: "Alza Prec. Suger.", // Nuevo nombre a mostrar para "contains"
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "Alza Prec. Suger.",
      numberOfInputs: 0,
    },
  ],
};
export const problemCategoryColumnFilterParams = {
  filterOptions: [
    {
      displayKey: "empty",
      displayName: "Elige una opción",
      predicate: (_, cellValue) => true, // Retorna true para todas las filas
      numberOfInputs: 0,
    },
    {
      displayKey: "low_turnover_without_discounts", // Esta es la clave interna para la opción "contains"
      displayName: "Baja Rotac. sin Desc.", // Nuevo nombre a mostrar para "contains"
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "Baja Rotac. sin Desc.",
      numberOfInputs: 0,
    },
    {
      displayKey: "low_turnover_with_discounts", // Esta es la clave interna para la opción "contains"
      displayName: "Baja Rotac. con Desc.", // Nuevo nombre a mostrar para "contains"
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "Baja Rotac. con Desc.",
      numberOfInputs: 0,
    },
    {
      displayKey: "less_than_5_units_sold", // Esta es la clave interna para la opción "contains"
      displayName: "Menos de 5 Ventas", // Nuevo nombre a mostrar para "contains"
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "Menos de 5 Ventas",
      numberOfInputs: 0,
    },
    {
      displayKey: "low_stock", // Esta es la clave interna para la opción "contains"
      displayName: "Bajo Stock", // Nuevo nombre a mostrar para "contains"
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "Bajo Stock",
      numberOfInputs: 0,
    },
    {
      displayKey: "wrong_suggested_price", // Esta es la clave interna para la opción "contains"
      displayName: "Precio Sugerido Erroneo", // Nuevo nombre a mostrar para "contains"
      predicate: (_, cellValue) =>
        cellValue != null && cellValue === "Precio Sugerido Erroneo",
      numberOfInputs: 0,
    },
  ],
};

export const idFilterParams = {
  filterPlaceholder: "Filtro...",
  maxNumConditions: 1,
  filterOptions: [
    {
      displayKey: "contains", // Utilizamos "contains" para buscar parcialmente
      displayName: "ID N°", // Nuevo nombre a mostrar
      predicate: ([fv1], cellValue) =>
        cellValue != null && cellValue.toString().includes(fv1), // Convertimos cellValue a string antes de usar includes
      numberOfInputs: 1,
    },
  ],
};

export const tableFilterDefaultParams = {
  filterPlaceholder: "Filtro...",
  maxNumConditions: 1,
  filterOptions: [
    {
      displayKey: "contains", // Esta es la clave interna para la opción "contains"
      displayName: "Contiene", // Nuevo nombre a mostrar para "contains"
      predicate: ([fv1], cellValue) =>
        cellValue != null &&
        cellValue.toLowerCase().includes(fv1.toLowerCase()),
      numberOfInputs: 1,
    },
  ],
};
