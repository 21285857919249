import React from "react";
import { Button, useTheme } from "@mui/material";
import { ReactComponent as AtomIcon } from "../../../../assets/images/atom-icon.svg";

const InProgressButton = ({ onClick }) => {
  const theme = useTheme();
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      style={{ transitionDuration: "0ms" }}
      sx={{
        height: "2.5rem",
        width: "auto",
        paddingInline: "0.7rem",
        border: "1px solid #4AA57F",
        borderRadius: "1.2rem",
        display: "flex",
        textTransform: "none",
        fontSize: "0.85rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        color: theme.palette.inProgressButton.title.main,
        backgroundColor: theme.palette.inProgressButton.background.main,
        fontStyle: "italic",
        fontWeight: "bold",
        "&:hover": {
          backgroundColor: theme.palette.inProgressButton.background.main,
          color: theme.palette.inProgressButton.title.main,
          border: "1px solid #4AA57F",
        },
        "& .endIcon": {
          fill: "#fff",
        },
      }}
      endIcon={
        <AtomIcon
          style={{
            height: "20px",
            width: "20px",
            fill: theme.palette.inProgressButton.title.main,
          }}
        />
      }
    >
      En Progreso
    </Button>
  );
};

export default InProgressButton;
