import { Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import TrelloCard from "../trello-card/card.component";

const TrelloSection = ({ experimentData, title }) => {
  const theme = useTheme();
  return (
    <Grid
      item
      xs={3}
      sx={{ backgroundColor: theme.palette.table.primary, padding: "1rem" }}
    >
      <Grid container>
        <Typography variant="body1" fontWeight="bold" flexGrow={1}>
          {title}
        </Typography>
        <Typography variant="body1">{experimentData?.length}</Typography>
      </Grid>
      {experimentData?.length !== 0 ? (
        experimentData?.map((data) => {
          return <TrelloCard experimentData={data} key={data?.id} />;
        })
      ) : (
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBlockStart: "0.7rem",
          }}
        >
          <Typography>No Hay Experimentos {title}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default TrelloSection;
