// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { Grid, MenuItem, Select, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import customSelectStyles from "./customSelectStyles";
import axios from "axios";
import { getUserInfo } from "../../../services/userInfo.service";

export const CustomSelect = ({
  name,
  value,
  onChange,
  items,
  label,
  isMachines,
  disabled,
}) => {
  const theme = useTheme();
  return (
    <Select
      name={name}
      IconComponent={() => (
        <KeyboardArrowDownIcon sx={{ fontSize: "0.7rem", color: "#999" }} />
      )}
      MenuProps={{ sx: { width: "auto" } }}
      sx={customSelectStyles(
        theme.palette.title.main,
        theme.palette.background.default,
        theme.palette.divider.main
      )}
      value={value}
      onChange={onChange}
      disabled={disabled}
    >
      <MenuItem
        sx={{
          fontSize: "1rem",
          justifyContent: "center",
          color: theme.palette.divider.main,
        }}
        value="default"
      >
        {label}
      </MenuItem>
      {items?.map((element, i) => {
        if (element !== "default") {
          return (
            <MenuItem
              sx={{
                display: isMachines ? "flex" : "flex",
                justifyContent: "center",
              }}
              key={i}
              value={isMachines ? element.id : element}
            >
              {isMachines ? element.alias : element}
            </MenuItem>
          );
        }
        return null;
      })}
    </Select>
  );
};

const SalesFilters = ({
  setBigCardSubtitle,
  defaultMachine,
  machinesSelectorDisabled,
  filters,
  setFilters,
  productCategories,
}) => {
  const loginToken = localStorage.getItem("access_token");
  const userInfo = getUserInfo();
  const [dropdownData, setDropdownData] = useState({});

  //Logic to remove "all" element in dropdown options.
  function removeAllElements(obj) {
    for (let key in obj) {
      obj[key] = obj[key].filter((item) => item !== "all");
    }
    return obj;
  }

  //Logic to fetch dropdowns data
  useEffect(
    () => {
      const fetchDropdowns = async () => {
        try {
          if (userInfo) {
            const { data } = await axios.get(
              `https://data.myma.space/api/dd?clientId=${userInfo.customer_id}&group=${filters.groups}&storeClusterId=${filters.clusters}&location=${filters.locations}&category=${filters.categories}`
            );
            setDropdownData(removeAllElements(data));
            setDropdownData((prevData) => ({
              ...removeAllElements(data),
              productCategories: productCategories, // Suponiendo que 'productCategories' es una propiedad de 'data'
            }));
            if (dropdownData && setBigCardSubtitle) {
              let result = dropdownData?.machines?.filter((element) => {
                return element.id === filters.machines;
              });
              setBigCardSubtitle(result?.[0]?.alias);
            }
          }
        } catch (error) {
          // Error handler
          console.error("Error 500 en la solicitud de dropdowns:", error);
        }
      };

      // Verify if access token isn't null
      if (loginToken !== null) {
        // Verify if axios headers are setted.
        if (axios.defaults.headers.common["Authorization"]) {
          fetchDropdowns();
        } else {
          // If axios headers aren't setted, wait untill they are.
          const interval = setInterval(() => {
            if (axios.defaults.headers.common["Authorization"]) {
              clearInterval(interval);
              fetchDropdowns();
            }
          }, 100); // Verification interval.
        }
      }
    },
    // eslint-disable-next-line
    [filters, loginToken, productCategories]
  );

  const handleFilters = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  //First part of the logic to check if the current productCategories exists, if not , sets productCategories to the "default" value.
  useEffect(
    () => {
      if (
        filters.productCategories !== "default" &&
        !dropdownData.productCategories.includes(filters.productCategories)
      ) {
        setFilters({ ...filters, productCategories: "default" });
      }
    },
    // eslint-disable-next-line
    [filters.productCategories, dropdownData.productCategories]
  );

  //Logic to select the first machine in the list when the prop "defaultMachine" is passed in the filter component.

  useEffect(
    () => {
      if (dropdownData && filters.machines === "default" && defaultMachine) {
        const defaultMachineId = dropdownData?.machines?.[0]?.id;
        if (defaultMachineId) {
          setFilters({ ...filters, machines: defaultMachineId });
        }
      }
    },
    // eslint-disable-next-line
    [filters, dropdownData, defaultMachine]
  );

  return (
    <Grid
      container
      sx={{
        display: "flex",
        maxWidth: "552px",
        backgroundColor: "#F8F8F8",
        alignContent: "center",
        paddingInline: "1rem",
        height: "2.5rem",
        // position: "fixed",
        zIndex: "1",
      }}
    >
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        xs={3}
      >
        <CustomSelect
          name="groups"
          value={filters.groups}
          onChange={handleFilters}
          items={dropdownData?.groups}
          label="Grupo"
        />
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        xs={3}
      >
        <CustomSelect
          name="clusters"
          value={filters.clusters}
          onChange={handleFilters}
          items={dropdownData?.clusters}
          label="Posición"
        />
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        xs={3}
      >
        <CustomSelect
          name="productCategories"
          // Second part of the logic to handle if the current productCategories selection is available in the dropdownData options.
          value={
            dropdownData?.productCategories?.includes(
              filters?.productCategories
            )
              ? filters?.productCategories
              : "default"
          }
          onChange={handleFilters}
          items={dropdownData?.productCategories}
          label="Categorías"
        />
      </Grid>
      {/* <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        xs={3}
      >
        <CustomSelect
          name="categories"
          value={filters.categories}
          onChange={handleFilters}
          items={dropdownData?.categories}
          label="Tipo"
        />
      </Grid> */}
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        xs={3}
      >
        {machinesSelectorDisabled ? null : (
          <CustomSelect
            name="machines"
            value={filters.machines}
            onChange={handleFilters}
            items={dropdownData?.machines}
            label="Máquinas"
            isMachines={true}
            disabled={machinesSelectorDisabled}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default SalesFilters;
