import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
  typography: {
    fontFamily: ` "MuseoSans", sans-serif`,
    fontSize: 14,
    color: "#000",
  },
  palette: {
    mode: "light",
    background: {
      default: "#fff",
      paper: "#fff",
    },
    table: {
      primary: "#F2F2F2",
      secondary: "#fff",
      cell: "#8C8C8C8C",
      text: "#FFF",
    },
    alerts: {
      info: { main: "#446BB2" },
      warning: { main: "#FFD702" },
      error: { main: "#FA0909" },
      good: { main: "#00A87C" },
    },
    label: {
      background: {
        main: "transparent",
      },
      title: {
        main: "#EA572E",
      },
    },
    inProgressButton: {
      background: { main: "transparent" },
      title: { main: "#4AA57F" },
    },
    filters: {
      main: "#F8F8F8",
    },
    divider: { main: "#663B73" },
    header: {
      main: "#663B73",
      title: "#fff",
    },
    icons: { main: "#663B73" },
    title: { main: "#663B73" },
    fill: { main: "#494c7d" },
    primary: {
      main: "#663B73",
      dark: "#4A295B",
    },
    secondary: {
      main: "#494c7d",
      light: "#4E2C61",
    },
    beige: {
      main: "#EBE9DA",
    },
    purple: {
      main: "#663B73",
    },
    white: {
      main: "#FFFFFF",
    },
    green: {
      main: "#00A87C",
    },
    pink: {
      main: "#E7255F",
    },
    grey: {
      main: "#5A5956",
    },
    red: {
      main: "#E21B2B",
    },
    black: {
      main: "#000000",
    },
    onboarding_bg: {
      main: "#EBE9DA",
    },
    buttonGrey: { main: "#707070" },
  },
});

export default lightTheme;
