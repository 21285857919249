import React from "react";
import { useEffect, useState } from "react";
import Header from "../../components/header/header.component";
import Section from "../../components/section/section.component";
import axios from "axios";
import { Box, Grid, useTheme } from "@mui/material";
import Filters from "../../components/filters/filters.component";
import { getUserInfo } from "../../services/userInfo.service";
import DrawerMenu from "../../components/drawer/drawer.component";
import moment from "moment";
import {
  calculateIncrementalPercentages,
  calculateIncrements,
  calculatePreviousAndActualPeriodMetrics,
  filterExperimentsByDateRange,
} from "../../components/insights/metricsCalculations";
import MetricsImpact from "../../components/insights/metrics-impact/metrics-impact.component";
import { useDispatch } from "react-redux";
import { closeMenu } from "../../store/features/menuDrawer/menuSlice";

const MyInsights = () => {
  const dispatch = useDispatch();
  const loginToken = localStorage.getItem("access_token");
  const userInfo = getUserInfo();
  const theme = useTheme();

  const currentDate = moment().format("YYYY-MM-DD");
  const firstDayOfMonth = moment().startOf("month").format("YYYY-MM-DD");
  const [dateFrom, setDateFrom] = useState(firstDayOfMonth);
  const [dateTo, setDateTo] = useState(currentDate);

  const [finishedExperiments, setFinishedExperiments] = useState([]);
  const [filteredExperiments, setFilteredExperiments] = useState([]);
  const [totalIncrementalUnits, setTotalIncrementalUnits] = useState("");
  const [totalIncrementalProfit, setTotalIncrementalProfit] = useState("");
  const [incrementalUnitsPercentage, setIncrementalUnitsPercentage] =
    useState("");
  const [incrementalProfitPercentage, setIncrementalProfitPercentage] =
    useState("");
  const [previousAndActualPeriodMetrics, setpreviousAndActualPeriodMetrics] =
    useState({});

  // const [subtitle, setSubtitle] = useState("");

  const [filters, setFilters] = useState({
    groups: "default", //label: "Grupo"
    clusters: "default", //label: "Posición"
    locations: "default", //label: "Ubicación"
    categories: "default", //label: "Tipo"
    machines: "default", //label: "Máquinas"
  });

  const fetchFinishedExperiments = async () => {
    try {
      if (userInfo) {
        const { data } = await axios.get(
          `https://data.myma.space/api/experiments?clientId=${userInfo.customer_id}&group=${filters.groups}&storeClusterId=${filters.clusters}&category=${filters.categories}&storeId=${filters.machines}&status=finished`
        );

        setFinishedExperiments(data.experiments);
      }
    } catch (error) {
      console.error("Error fetching alerts:", error);
    }
  };

  useEffect(
    () => {
      // Verify if access token isn't null
      if (loginToken !== null) {
        // Verify if axios headers are setted.
        if (axios.defaults.headers.common["Authorization"]) {
          fetchFinishedExperiments();
        } else {
          // If axios headers aren't setted, wait untill they are.
          const interval = setInterval(() => {
            if (axios.defaults.headers.common["Authorization"]) {
              clearInterval(interval);
              fetchFinishedExperiments();
            }
          }, 100); // Verification interval.
        }
      }
    },
    // eslint-disable-next-line
    [filters, loginToken]
  );

  useEffect(() => {
    if (finishedExperiments) {
      setFilteredExperiments(
        filterExperimentsByDateRange(finishedExperiments, dateFrom, dateTo)
      );
    }
  }, [finishedExperiments, dateFrom, dateTo]);
  useEffect(() => {
    if (filteredExperiments) {
      let { incrementalUnits, incrementalRevenue } =
        calculateIncrements(filteredExperiments);

      let { averageUnitsPercentage, averageRevenuePercentage } =
        calculateIncrementalPercentages(filteredExperiments);

      setTotalIncrementalUnits(incrementalUnits);
      setTotalIncrementalProfit(incrementalRevenue);
      setIncrementalUnitsPercentage(averageUnitsPercentage);
      setIncrementalProfitPercentage(averageRevenuePercentage);
      setpreviousAndActualPeriodMetrics(
        calculatePreviousAndActualPeriodMetrics(filteredExperiments)
      );
    }
  }, [filteredExperiments]);

  return (
    <>
      <Header
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ fontWeight: "normal" }}>Mis</div>
            <div style={{ fontWeight: "bold" }}>&nbsp;Insights</div>
          </div>
        }
        avatar="FB"
      />
      <Box>
        <Grid
          sx={{
            height: "100%",
            paddingBlockStart: "7rem",
            paddingInlineStart: "50px",
          }}
          container
        >
          <Filters
            disableFirstMachineSelected
            // setBigCardSubtitle={setSubtitle}
            filters={filters}
            setFilters={setFilters}
          />
          <DrawerMenu activeOptionId={4} />
          {/* <Grid
            container
            sx={{
              paddingBlockStart: "2.5rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: theme.palette.purple.main,
                color: "#fff",
                padding: "2px 10px",
                borderRadius: "5px",
                marginBlockStart: "0.5rem",
              }}
            >
              <Typography>
                <FormatPeriod
                  period={period}
                  startDate={metricsData?.period?.current?.startDate}
                  endDate={metricsData?.period?.current?.endDate}
                />
              </Typography>
            </Grid>
          </Grid> */}
          <Grid
            onClick={() => dispatch(closeMenu())}
            container
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              paddingBlockStart: "2.5rem",
            }}
          >
            <Grid
              item
              sx={{
                gap: "1rem",
                display: "flex",
                justifyContent: "center",
                backgroundColor: theme.palette.filters.main,
                paddingBlockEnd: "0.5rem",
              }}
              xs={12}
            >
              <label
                style={{ color: theme.palette.title.main }}
                htmlFor="startDate"
              >
                Desde:
              </label>
              <input
                style={{
                  border: theme.palette.divider.main,
                  color: theme.palette.title.main,
                  backgroundColor: theme.palette.background.default,
                  outline: "none",
                  background: "none",
                  borderRadius: "0.5rem",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                type="date"
                max={currentDate}
                name="startDate"
                id="startDate"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
              />
              <label
                style={{ color: theme.palette.title.main }}
                htmlFor="endDate"
              >
                Hasta:
              </label>
              <input
                style={{
                  border: theme.palette.divider.main,
                  color: theme.palette.title.main,
                  backgroundColor: theme.palette.background.default,
                  outline: "none",
                  background: "none",
                  borderRadius: "0.5rem",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                max={currentDate}
                type="date"
                name="endDate"
                id="endDate"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Section title="Resumen Métricas de Impacto" />
              <MetricsImpact
                previousAndActualPeriodMetrics={previousAndActualPeriodMetrics}
                incrementalUnitsPercentage={incrementalUnitsPercentage}
                incrementalProfitPercentage={incrementalProfitPercentage}
                totalIncrementalProfit={totalIncrementalProfit}
                totalIncrementalUnits={totalIncrementalUnits}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MyInsights;
