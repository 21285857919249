import React, { useState, useRef } from "react";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "./table-styles.css"; // Optional Theme applied to the grid
import { Grid, Paper, useTheme } from "@mui/material";

import { tableFilterDefaultParams } from "./table-filters";
import { generateFakeRowData } from "./fakeRowData";
import PercentageRender from "./cell-renders/percentage.component";
import TableHeader from "./table-header/table-header.component";
import { formatDates } from "../../../../utils/formatDate";
// eslint-disable-next-line
function createRowData(experimentsData, userInfo, finishedExperiments) {
  let experimentTypes = {
    promo_precio_unitario: "Promo Prec. Unit.",
    promo_combo_simple: "Promo Comb. Simpl.",
    promo_combo_combinado: "Promo Comb. Combi.",
    reemplazo_producto: "Reemplazo Prod.",
    ajuste_planograma: "Ajuste Planograma",
    baja_precio_sugerido: "Baja Prec. Suger.",
    alza_precio_sugerido: "Alza Prec. Suger.",
  };

  let experimentStatus = {
    running: "Iniciado",
    pending: "Pendiente",
    finished: "Finalizado",
  };

  let result = [];

  let allExperiments = experimentsData?.concat(finishedExperiments);
  // eslint-disable-next-line
  allExperiments?.map((data) => {
    let object = {
      "Id Experiment": data?.id,
      "Client Id": userInfo?.customer_name,
      "Experiment Status": experimentStatus[data?.status?.status],
      "Experiment Type": experimentTypes[data.experimentType.name],
      // "Store Id": `${data?.store?.id} - ${data?.store?.alias}`,
      "Store Id": `${data?.store?.alias}`,
      // "Old Product Id": `${data?.product?.id} - ${data?.product?.name}`,
      "Old Product Id": `${data?.product?.name}`,
      "New Product Id": data?.experimentType?.newProduct
        ? `${data?.experimentType?.newProduct?.name}`
        : data?.experimentType?.firstProduct &&
          data?.experimentType?.secondProduct
        ? `${data?.experimentType?.firstProduct?.name} + ${data?.experimentType?.secondProduct?.name}`
        : "N/A",
      Period: formatDates(data?.period?.start, data?.period?.end),
      "P-1 Units": data?.problem?.unitsSold,
      "P Units": data?.status?.unitsSold,
      "Δ Un": data?.status?.unitsSold - data?.problem?.unitsSold,
      "Δ Un(%)": `${parseFloat(
        ((data?.status?.unitsSold - data?.problem?.unitsSold) /
          data?.problem?.unitsSold) *
          100
      ).toFixed(0)}%`,
      "P-1 Total": `${parseFloat(
        data?.problem?.unitsSold * data?.status?.avgPrice
      )
        .toFixed(2)
        .replace(".", ",")}€`,
      "P Total": `${parseFloat(data?.status?.totalSold)
        .toFixed(2)
        .replace(".", ",")}€`,
      "Δ €": `${parseFloat(
        data?.status?.totalSold -
          data?.problem?.unitsSold * data?.status?.avgPrice
      )
        .toFixed(2)
        .replace(".", ",")}€`,
      "Δ €(%)": `${parseFloat(
        ((data?.status?.totalSold -
          data?.problem?.unitsSold * data?.status?.avgPrice) /
          (data?.problem?.unitsSold * data?.status?.avgPrice)) *
          100
      ).toFixed(0)}%`,
    };
    result.push(object);
  });

  return result;
}

const ReportingProfitabilityProductTableView = ({
  experimentsData,
  userInfo,
  finishedExperimentsData,
}) => {
  const gridRef = useRef();

  // eslint-disable-next-line
  const [rowData, setRowData] = useState([]);

  // useEffect(
  //   () => {
  //     setRowData(
  //       createRowData(experimentsData, userInfo, finishedExperimentsData)
  //     );
  //   },
  //   // eslint-disable-next-line
  //   [experimentsData, finishedExperimentsData]
  // );

  // eslint-disable-next-line
  const [colDefs, setColDefs] = useState([
    {
      headerName: "CURRENT YEAR",
      headerClass: "header-center",
      children: [
        {
          field: "id",
          // field: "category",
          headerName: "PRODUCTO",
          minWidth: 200,
          filter: true,
        },
      ],
      // Asegurarse de que no se aplica ningún formateo numérico
      valueFormatter: (params) => params.value,
    },
    {
      headerName: "ACTUAL",
      headerClass: "header-center",
      children: [
        {
          field: "ACT_U",
          headerName: "U",
          // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "ACT_PRICE",
          headerName: "PRECIO",
          // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "ACT_COST",
          headerName: "COSTO", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "ACT_MG$",
          headerName: "MG $", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "ACT_MG%",
          headerName: "MG %", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
          cellRenderer: PercentageRender,
        },
        {
          field: "ACT_SHARE",
          headerName: "SHARE %", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "ACT_SHARECG",
          headerName: "SHARE CG %", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "ACT_VARPR",
          headerName: "VAR PR", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "ACT_VARCOST",
          headerName: "VAR COST", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "ACT_VARMG",
          headerName: "VAR MG", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
      ],
    },
    {
      headerName: "M-1",
      headerClass: "header-center",
      children: [
        {
          field: "M1_U",
          headerName: "U",
          // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "M1_PRICE",
          headerName: "PRECIO",
          // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "M1_COST",
          headerName: "COSTO", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "M1_MG$",
          headerName: "MG $", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "M1_MG%",
          headerName: "MG %", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
          cellRenderer: PercentageRender,
        },
        {
          field: "M1_SHARE",
          headerName: "SHARE %", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "M1_SHARECG",
          headerName: "SHARE CG %", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "M1_VARPR",
          headerName: "VAR PR", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "M1_VARCOST",
          headerName: "VAR COST", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "M1_VARMG",
          headerName: "VAR MG", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
      ],
    },
    {
      headerName: "QUARTER",
      headerClass: "header-center",
      children: [
        {
          field: "Q_U",
          headerName: "U",
          // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "Q_PRICE",
          headerName: "PRECIO",
          // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "Q_COST",
          headerName: "COSTO", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "Q_MG$",
          headerName: "MG $", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "Q_MG%",
          headerName: "MG %", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
          cellRenderer: PercentageRender,
        },
        {
          field: "Q_SHARE",
          headerName: "SHARE %", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "Q_SHARECG",
          headerName: "SHARE CG %", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "Q_VARPR",
          headerName: "VAR PR", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "Q_VARCOST",
          headerName: "VAR COST", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "Q_VARMG",
          headerName: "VAR MG", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
      ],
    },
    {
      headerName: "YEAR",
      headerClass: "header-center",
      children: [
        {
          field: "Y_U",
          headerName: "U",
          // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "Y_PRICE",
          headerName: "PRECIO",
          // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "Y_COST",
          headerName: "COSTO", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "Y_MG$",
          headerName: "MG $", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "Y_MG%",
          headerName: "MG %", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
          cellRenderer: PercentageRender,
        },
        {
          field: "Y_SHARE",
          headerName: "SHARE %", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "Y_SHARECG",
          headerName: "SHARE CG %", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "Y_VARPR",
          headerName: "VAR PR", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "Y_VARCOST",
          headerName: "VAR COST", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
        {
          field: "Y_VARMG",
          headerName: "VAR MG", // Asegurarse de que no se aplica ningún formateo numérico
          valueFormatter: (params) => params.value,
        },
      ],
    },
  ]);

  const theme = useTheme();
  const themeMode = theme.palette.mode;

  return (
    <Grid
      container
      sx={{
        marginBlock: "1rem",
        display: "flex",
        justifyContent: "center",
        paddingInline: "1rem",
      }}
    >
      <Grid
        component={Paper}
        elevation={2}
        item
        xs={12}
        sx={{ height: "60vh" }}
        className={
          themeMode === "dark" ? "ag-theme-alpine-dark" : "ag-theme-alpine"
        }
      >
        <TableHeader
          colDefs={colDefs}
          setColDefs={setColDefs}
          gridRef={gridRef}
          fileName="reporting-profitability-product-table"
        />
        <AgGridReact
          ref={gridRef}
          // pagination={pagination}
          // paginationPageSize={paginationPageSize}
          // paginationPageSizeSelector={paginationPageSizeSelector}
          rowData={generateFakeRowData(20)}
          columnDefs={colDefs}
          defaultColDef={{
            flex: 1,
            filterParams: tableFilterDefaultParams,
          }}
          suppressMenuHide={true}
          // domLayout="autoHeight"
        />
      </Grid>
    </Grid>
  );
};

export default ReportingProfitabilityProductTableView;
