import { Grid } from "@mui/material";
import React from "react";
import IncrementalUnits from "./incremental-units.component";
import IncrementalProfit from "./incremental-profit.component";
import IncrementalBenefits from "./incremental-benefits.component";

const MetricsImpact = ({
  totalIncrementalUnits,
  totalIncrementalProfit,
  incrementalUnitsPercentage,
  incrementalProfitPercentage,
  previousAndActualPeriodMetrics,
}) => {
  return (
    <Grid
      container
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <IncrementalUnits
        incrementalUnitsPercentage={incrementalUnitsPercentage}
        previousAndActualPeriodMetrics={previousAndActualPeriodMetrics}
        totalIncrementalUnits={totalIncrementalUnits}
      />
      <IncrementalProfit
        incrementalProfitPercentage={incrementalProfitPercentage}
        previousAndActualPeriodMetrics={previousAndActualPeriodMetrics}
        totalIncrementalProfit={totalIncrementalProfit}
      />
      <IncrementalBenefits
        incrementalProfitPercentage={incrementalProfitPercentage}
        previousAndActualPeriodMetrics={previousAndActualPeriodMetrics}
        totalIncrementalProfit={totalIncrementalProfit}
      />
    </Grid>
  );
};

export default MetricsImpact;
