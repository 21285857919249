import React, { useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "./table-styles.css"; // Optional Theme applied to the grid
import { Grid, Paper, useTheme } from "@mui/material";
import StoreIdRender from "./cell-renders/store-id.component";
import ExperimentTypeRender from "./cell-renders/experiment-type.component";
import ProblemTypeRender from "./cell-renders/problem-type.component";

import {
  experimentTypeColumnFilterParams,
  idFilterParams,
  problemCategoryColumnFilterParams,
  statusColumnFilterParams,
  tableFilterDefaultParams,
} from "./table-filters";
import TableHeader from "../../../table-header/table-header.component";
import { formatDates } from "../../../../utils/formatDate";
import { filterByDateRange } from "../../../../utils/filterByDateRange";

function createRowData(
  experimentsData,
  userInfo,
  finishedExperiments,
  startDate,
  endDate
) {
  let experimentTypes = {
    promo_precio_unitario: "Promo Prec. Unit.",
    promo_combo_simple: "Promo Comb. Simpl.",
    promo_combo_combinado: "Promo Comb. Combi.",
    reemplazo_producto: "Reemplazo Prod.",
    ajuste_planograma: "Ajuste Planograma",
    baja_precio_sugerido: "Baja Prec. Suger.",
    alza_precio_sugerido: "Alza Prec. Suger.",
  };

  let problemTypes = {
    low_turnover_without_discounts: "Baja Rotac. sin Desc.",
    low_turnover_with_discounts: "Baja Rotac. con Desc.",
    less_than_5_units_sold: "Menos de 5 Ventas",
    low_stock: "Bajo Stock",
    wrong_suggested_price: "Precio Sugerido Erroneo",
  };

  let experimentStatus = {
    running: "Iniciado",
    pending: "Pendiente",
    finished: "Finalizado",
  };

  let result = [];

  let allExperiments = experimentsData?.concat(finishedExperiments);

  let filteredExperimentsByDate = filterByDateRange(
    allExperiments,
    startDate,
    endDate
  );

  // eslint-disable-next-line
  filteredExperimentsByDate?.map((data) => {
    let object = {
      "Id Experiment": data?.id,
      "Client Id": userInfo?.customer_name,
      "Experiment Name": "...",
      "Problem Category": problemTypes[data?.problem?.type],
      // "Store Id": `${data?.store?.id} - ${data?.store?.alias}`,
      "Store Id": `${data?.store?.alias}`,
      // "Old Product Id": `${data?.product?.id} - ${data?.product?.name}`,
      "Old Product Id": `${data?.product?.name}`,
      "New Product Id": data?.experimentType?.newProduct
        ? `${data?.experimentType?.newProduct?.name}`
        : data?.experimentType?.firstProduct &&
          data?.experimentType?.secondProduct
        ? `${data?.experimentType?.firstProduct?.name} + ${data?.experimentType?.secondProduct?.name}`
        : "N/A",
      Period: formatDates(data?.period?.start, data?.period?.end),
      "Experiment Type": experimentTypes[data.experimentType.name],
      "Experiment Status": experimentStatus[data?.status?.status],
    };
    result.push(object);
  });

  return result;
}

const ExperimentsManagementTableView = ({
  experimentsData,
  userInfo,
  finishedExperimentsData,
  dateFilterValues,
}) => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);

  useEffect(
    () => {
      setRowData(
        createRowData(
          experimentsData,
          userInfo,
          finishedExperimentsData,
          dateFilterValues[0]?.toDate(),
          dateFilterValues[1]?.toDate()
        )
      );
    },
    // eslint-disable-next-line
    [experimentsData, finishedExperimentsData,dateFilterValues]
  );

  // eslint-disable-next-line
  const [colDefs, setColDefs] = useState([
    {
      field: "Id Experiment",
      hide: false,
      maxWidth: 140,
      filter: true,
      filterParams: idFilterParams,
    },
    { field: "Client Id", hide: true },
    {
      field: "Problem Category",
      hide: false,
      cellRenderer: ProblemTypeRender,
      minWidth: 120,
      filter: true,
      filterParams: problemCategoryColumnFilterParams,
    },
    {
      field: "Store Id",
      hide: false,
      cellRenderer: StoreIdRender,
      minWidth: 120,
      filter: true,
    },
    {
      field: "Old Product Id",
      hide: false,
      filter: true,
      cellClass: "align-left",
    },
    {
      field: "New Product Id",
      hide: false,
      filter: true,
      cellClass: "align-left",
    },
    { field: "Period", hide: false, filter: true },
    {
      field: "Experiment Type",
      hide: false,

      cellRenderer: ExperimentTypeRender,
      minWidth: 120,
      filter: true,
      filterParams: experimentTypeColumnFilterParams,
    },
    {
      field: "Experiment Status",
      hide: false,
      filter: true,
      filterParams: statusColumnFilterParams,
    },
    { field: "Experiment Name", hide: true },
  ]);

  const pagination = true;
  const paginationPageSize = 15;
  const paginationPageSizeSelector = [15, 30, 50];
  const theme = useTheme();
  const themeMode = theme.palette.mode;

  return (
    <Grid
      container
      sx={{
        marginBlock: "1rem",
        display: "flex",
        justifyContent: "center",
        height: "60vh",
        paddingInline: "1rem",
      }}
    >
      <Grid
        component={Paper}
        elevation={2}
        item
        xs={12}
        className={
          themeMode === "dark" ? "ag-theme-alpine-dark" : "ag-theme-alpine"
        }
      >
        <TableHeader
          colDefs={colDefs}
          setColDefs={setColDefs}
          gridRef={gridRef}
          fileName="experiments_management_table"
        />
        <AgGridReact
          ref={gridRef}
          pagination={pagination}
          paginationPageSize={paginationPageSize}
          paginationPageSizeSelector={paginationPageSizeSelector}
          rowData={rowData}
          columnDefs={colDefs}
          defaultColDef={{ flex: 1, filterParams: tableFilterDefaultParams }}
          suppressMenuHide={true}
        />
      </Grid>
    </Grid>
  );
};

export default ExperimentsManagementTableView;
