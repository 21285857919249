import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
const alertsSourceEquivalency = {
  competencia: "notificaciones por Competencia",
  clima: "notificaciones por Clima",
  costos: "notificaciones por Costos",
  eventos: "notificaciones por Eventos",
  mercado: "notificaciones por Mercado",
  rotacion: "notificaciones por Rotación",
};
const EmptyAlerts = ({ source }) => {
  const theme = useTheme()
  return (
    <Grid
      container
      sx={{
        display: "flex",
        marginBlock: "0rem",
        justifyContent: "center",
      }}
    >
      <Grid
        item
        xs={8}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography variant="h5" sx={{ textAlign: "center", color: theme.palette.divider.main }}>
          <span>
            No tienes {alertsSourceEquivalency[source]} en éste momento.
          </span>
          {/* <br />
          <br />
          <span style={{ fontWeight: "bold" }}>
            Mantente atento para recibir actualizaciones sobre eventos
            importantes.
          </span> */}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <NotificationsActiveOutlinedIcon
          sx={{ fontSize: "10rem" }}
          style={{ fill: "#cccccc" }}
        />
      </Grid>
    </Grid>
  );
};

export default EmptyAlerts;
