import {
  Box,
  ClickAwayListener,
  Grid,
  IconButton,
  Popper,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { ReactComponent as MoreIcon } from "../../../../../../src/assets/images/vert-dots-icon.svg";
import { ReactComponent as DownloadIcon } from "../../../../../../src/assets/images/cloud-download-icon.svg";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { CsvExportModule } from "@ag-grid-community/csv-export";
ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

const TableHeader = ({ colDefs, setColDefs, title, gridRef, fileName }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleColumnToggle = (field) => {
    const updatedCols = colDefs.map((col) => {
      if (col.headerName === field) {
        // Si col.headerName coincide con field, actualizar col y sus children
        const updatedCol = {
          ...col,
          hide: !col.hide, // Invertir el estado hide
          children: col.children.map((child) => ({
            ...child,
            hide: !col.hide, // Invertir el estado hide para cada child
          })),
        };
        return updatedCol;
      } else {
        // Si col.headerName no coincide con field, devolver col sin modificaciones
        return col;
      }
    });

    setColDefs(updatedCols); // Actualizar el estado colDefs con las columnas actualizadas
  };

  const onBtnExport = useCallback(
    () => {
      gridRef.current.api.exportDataAsCsv({
        suppressQuotes: true,
        columnSeparator: ";",
        fileName: `${fileName}.csv`,
      });
    },
    // eslint-disable-next-line
    []
  );
  return (
    <Grid
      container
      sx={{
        padding: "16px 24px",
        height: "4rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        sx={{ zIndex: 100 }}
        disablePortal
      >
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <Grid
            container
            sx={{
              bgcolor: "background.paper",
              border: "1px solid #ccc",
              padding: "0.9rem",
              minWidth: "260px",
              maxWidth: "450px",
              display: "flex",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                padding: "8px 12px",
                marginBlockEnd: "4px",
                height: "2.2rem",
              }}
            >
              <Typography fontSize="1rem">Columnas:</Typography>
            </Grid>
            {colDefs.slice(1).map((column) => (
              <Grid
                key={column.headerName}
                item
                xs={6}
                sx={{
                  padding: "4px 12px",
                  height: "34px",
                  display: "flex",
                  gap: 1,
                }}
              >
                <Switch
                  sx={{
                    "& .MuiSwitch-switchBase": {
                      "&.Mui-checked": {
                        "+ .MuiSwitch-track": {
                          backgroundColor: theme.palette.purple.main,
                        },
                        ".MuiSwitch-thumb": {
                          backgroundColor: theme.palette.purple.main,
                        },
                      },
                    },
                  }}
                  size="small"
                  checked={!column.hide}
                  onChange={() => handleColumnToggle(column.headerName)}
                />
                <Typography>{column.headerName}</Typography>
              </Grid>
            ))}
          </Grid>
        </ClickAwayListener>
      </Popper>

      <Grid item sx={{ fontSize: "1.3rem" }}>
        {title}
      </Grid>
      <Grid
        item
        sx={{
          flexGrow: 0.9,
          display: "flex",
          justifyContent: "end",
          fontSize: "0.8rem",
          alignItems: "center",
          maxHeight: "100%",
        }}
      >
        <Tooltip
          sx={{ cursor: "pointer" }}
          onClick={onBtnExport}
          title="Exportar formato CSV"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              color: theme.palette.text.primary,
            }}
          >
            Descargar
            <IconButton sx={{ padding: 0.8 }}>
              <DownloadIcon fill={theme.palette.divider.main} />
            </IconButton>
          </Box>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip onClick={handleClick} title="Elige que columnas mostrar">
          <IconButton sx={{ padding: 0 }}>
            <MoreIcon fill={theme.palette.divider.main} />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default TableHeader;
