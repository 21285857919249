import { MenuItem, Select, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import customSelectStyles from "./customSelectStyles";

export const CustomSelect = ({
  name,
  value,
  onChange,
  items,
  label,
  disabled,
  multiple,
  isMachines,
}) => {
  const theme = useTheme();
  return (
    <Select
      name={name}
      IconComponent={() => (
        <KeyboardArrowDownIcon sx={{ fontSize: "0.7rem", color: "#999" }} />
      )}
      MenuProps={{ sx: { width: "auto" } }}
      sx={customSelectStyles(
        theme.palette.title.main,
        theme.palette.background.default,
        theme.palette.divider.main
      )}
      value={value}
      onChange={onChange}
      disabled={disabled}
      multiple={multiple}
      inputProps={{ MenuProps: { disableScrollLock: true } }}
    >
      <MenuItem
        sx={{
          fontSize: "1rem",
          justifyContent: "center",
          // color: "#663B73",
          color: theme.palette.divider.main,
        }}
        value="default"
      >
        {label}
      </MenuItem>
      {items?.map((element, i) => {
        if (element !== "default") {
          return (
            <MenuItem
              sx={{
                display: isMachines ? "flex" : "flex",
                justifyContent: "center",
              }}
              key={i}
              value={isMachines ? element.id : element}
            >
              {isMachines ? element.alias : element}
            </MenuItem>
          );
        }
        return null;
      })}
    </Select>
  );
};
