import { Container, Grid, Paper, Typography, useTheme } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCartOutlined";

import React from "react";
import { CustomSelect } from "../sales/sales-filter/sales-filter.component";

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

import es from "date-fns/locale/es";
import moment from "moment-timezone";

const MediumCardVariantF = ({
  list,
  date,
  setDate,
  filters,
  setFilters,
  productCategories,
}) => {
  const theme = useTheme();
  const handleFilters = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };
  // const formatDatesRange = (dateString) => {
  //   if (!dateString) return "-/-";

  //   const formatDate = (date) => {
  //     if (!date) return "-/-";
  //     // eslint-disable-next-line
  //     const [year, month, day] = date.split("-");
  //     return `${day}/${month}/${year}`;
  //   };

  //   const [datePart] = dateString.split("T");
  //   const formattedDate = formatDate(datePart);

  //   return formattedDate;
  // };

  return (
    <Grid container sx={{ display: "flex", justifyContent: "center" }}>
      <Grid
        item
        xs={10}
        sx={{
          marginBlock: "1rem",
          width: "350px",
          height: "auto",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            // border: "2px solid #727272",
            borderTop: "1.5px solid rgba(0, 0, 0, 0.1)",
            borderRadius: "1rem",
            paddingInlineEnd: "0.2rem",
            paddingInlineStart: "0.2rem",
            //   paddingBlock: "0.5rem",
          }}
        >
          <Grid container sx={{ display: "flex" }}>
            <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "3rem",
              }}
              xs={1}
            >
              <ShoppingCartIcon sx={{ fill: "#00A87C", fontSize: "2.5rem" }} />
            </Grid>
            <Grid item sx={{ display: "flex", alignItems: "center" }} xs={11}>
              <CustomSelect
                name="productCategories"
                value={filters?.productCategories}
                onChange={handleFilters}
                items={productCategories}
                label="Categorías"
              />

              <Container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "55%",
                }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={es}
                >
                  <MobileDatePicker
                    disableFuture
                    disableHighlightToday
                    slotProps={{
                      toolbar: { hidden: true },
                      actionBar: { actions: ["accept"] },
                      textField: {
                        InputProps: {
                          disableUnderline: true,
                          // endAdornment: null,
                          fullWidth: true,

                          sx: {
                            color: "#fff",
                            fontWeight: "bold",
                            ".MuiInput-input": {
                              width: "6.5rem",
                              textAlign: "center",
                              backgroundColor: theme.palette.divider.main,
                              borderRadius: "0.4rem",
                              height: "1rem",
                            },
                            // ".MuiInputBase-input": {
                            //   width: "6.5rem",
                            //   textAlign: "center",
                            //   backgroundColor: theme.palette.divider.main,
                            //   borderRadius: "0.4rem",
                            //   height: "1rem",
                            // },
                          },
                        },
                        variant: "standard",
                      },

                      layout: {
                        sx: {
                          ".MuiDateCalendar-root": {
                            color: `${theme.palette.divider.main} !important`,
                          },
                          ".MuiPickersCalendarHeader-labelContainer": {
                            color: `${theme.palette.divider.main} !important`,
                            fontSize: "1.5rem",
                            fontWeight: "bold",
                          },
                          ".css-4alkd5-MuiPickersCalendarHeader-labelContainer":
                            {
                              color: `${theme.palette.divider.main} !important`,
                              fontSize: "1.5rem",
                              fontWeight: "bold",
                            },
                          ".MuiButtonBase-root-MuiButton-root": {
                            color: `${theme.palette.divider.main} !important`,
                          },
                          ".MuiButton-root": {
                            color: theme.palette.divider.main,
                          },
                          ".css-kskhi8-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover":
                            {
                              backgroundColor: `${theme.palette.divider.main} !important`,
                            },

                          ".css-kskhi8-MuiButtonBase-root-MuiPickersDay-root.Mui-selected":
                            {
                              backgroundColor: `${theme.palette.divider.main} !important`,
                            },
                          ".Mui-selected": {
                            backgroundColor: `${theme.palette.divider.main} !important`,
                          },
                          ".MuiPickersDay-root.Mui-selected": {
                            backgroundColor: `${theme.palette.divider.main} !important`,
                          },
                          ".css-eu953l": {
                            color: `${theme.palette.divider.main} !important`,
                          },
                          ".MuiDatePickerToolbar-title": {
                            color: `${theme.palette.title.main} !important`,
                          },
                        },
                      },
                    }}
                    value={(date && new Date(date)) || null}
                    onChange={(newValue) =>
                      setDate(
                        moment(newValue).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                      )
                    }
                  />
                </LocalizationProvider>
              </Container>
              <Typography
                sx={{
                  color: theme.palette.title.main,
                  width: "30%",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "1.1rem",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Ventas</span> &nbsp;
                <span>(Un)</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            xs={12}
          >
            {list && Array.isArray(list) && list.length > 0 && (
              <ol
                style={{
                  paddingLeft: "0rem",
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                  width: "100%",
                  listStyleType: "none",
                  marginBlockStart: 0,
                }}
              >
                {list.map((element, index) => (
                  <li
                    key={index}
                    style={{
                      backgroundColor:
                        index % 2 === 0
                          ? theme.palette.table.primary
                          : theme.palette.table.secondary,
                    }}
                  >
                    <Grid item xs={12} sx={{ display: "flex" }}>
                      <Grid
                        item
                        xs={9}
                        sx={{
                          fontWeight: "bold",
                          display: "flex",
                        }}
                      >
                        <Typography>{element.name}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{
                          fontWeight: "bold",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Typography>{element.total}</Typography>
                      </Grid>
                    </Grid>
                  </li>
                ))}
              </ol>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default MediumCardVariantF;
