import { AgCharts } from "ag-charts-react";
import React, { useEffect, useState } from "react";
import {
  createChartSeries,
  extractYKeys,
  processExperiments,
} from "../../../../utils/experiments-impact-chart";
import { filterByDateRange } from "../../../../utils/filterByDateRange";
import { Grid, Paper } from "@mui/material";

const PerformanceChart = ({ experiments, dateFilterValues }) => {
  const [filteredExperimentsByDate, setFilteredExperimentsByDate] = useState(
    []
  );

  const [data, setData] = useState([]);
  const [series, setSeries] = useState([]);
  const [keys, setKeys] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    // Filtra los experimentos por el rango de fechas
    const filteredData = filterByDateRange(
      experiments,
      dateFilterValues[0]?.toDate(),
      dateFilterValues[1]?.toDate()
    );
    setFilteredExperimentsByDate(filteredData);
  }, [experiments, dateFilterValues]);

  useEffect(() => {
    // Procesa los datos cuando se actualiza filteredExperimentsByDate
    const processedData = processExperiments(filteredExperimentsByDate);
    setData(processedData);
  }, [filteredExperimentsByDate]);

  useEffect(() => {
    // Crea la serie y extrae las claves cuando se actualizan los datos
    const chartSeries = createChartSeries(data);
    setSeries(chartSeries);
    const chartKeys = extractYKeys(chartSeries);
    setKeys(chartKeys);
  }, [data]);

  useEffect(() => {
    // Configura las opciones del gráfico cuando se actualizan series y claves
    setOptions({
      data: data,
      // title: {
      //   // text: "Ventas",
      // },
      series: series,
      axes: [
        {
          type: "category",
          position: "bottom",
        },
        {
          type: "number",
          position: "left",
          keys: keys,
          title: {
            text: "Δ €",
            fontSize: 15,
          },
          label: {
            formatter: function (params) {
              return params.value + " €";
            },
          },
        },
      ],
      legend: {
        position: "right",
      },
    });
  }, [data, series, keys]);

  return (
    <Grid
      component={Paper}
      elevation={2}
      container
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: "0.5rem",
        ".ag-charts-canvas-container": {
          borderRadius: "4px",
          overflow: "hidden",
        },
      }}
    >
      <AgCharts style={{ width: "50vw", height: "60vh" }} options={options} />
    </Grid>
  );
};

export default PerformanceChart;
