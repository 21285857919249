import { Grid, Paper, Typography, useTheme } from "@mui/material";
import { ReactComponent as ArrowMediumRed } from "../../assets/images/arrow-red-big.svg";
import { ReactComponent as ArrowMediumGreen } from "../../assets/images/arrow-green-big.svg";

import React from "react";

const MediumCardVariantD = ({ type, variant, arrow, product, total }) => {
  const theme = useTheme();
  const variants = {
    positive: {
      arrow: <ArrowMediumGreen fill={theme.palette.alerts.good.main} />,
      color: theme.palette.alerts.good.main,
    },
    negative: {
      arrow: <ArrowMediumRed fill={theme.palette.alerts.error.main} />,
      color: theme.palette.alerts.error.main,
    },
    neutral: {
      arrow: null,
      color: "#000",
    },
  };
  return (
    <Grid container>
      <Grid
        item
        sx={{
          marginInline: "1rem",
          marginBlockEnd: "1rem",
          height: "8rem",
          width: "350px",
        }}
        xs={12}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            height: "100%",
            // border: "2px solid #727272",
            borderRadius: "1rem",
            paddingInlineEnd: "0.2rem",
            paddingInlineStart: "0.2rem",
            paddingBlock: "0.5rem",
            borderTop: "1.5px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              maxHeight: 1 / 3,
              height: 1 / 3,
              justifyContent: "center",
            }}
          >
            {/* <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              xs={1}
            ></Grid> */}
            <Grid item sx={{ display: "flex", alignItems: "center" }} xs={11}>
              <Typography
                sx={{
                  color: variants[variant].color,
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                }}
              >
                {type}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              display: "flex",
              height: 2 / 3,
              maxHeight: 2 / 3,
              justifyContent: "center",
            }}
          >
            {/* <Grid
              item
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              xs={1}
            >
              {arrow !== false && variants[variant].arrow}
            </Grid> */}
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "start",
                paddingBlock: "0.5rem",
              }}
              xs={11}
            >
              {variant === "positive" ? (
                <Typography lineHeight="1.1rem" fontSize="1.1rem">
                  Este mes el Producto más vendido es{" "}
                  <span style={{ fontWeight: "bold" }}>{product}</span> con{" "}
                  <span style={{ fontWeight: "bold" }}>{total}</span> Venta/s.
                </Typography>
              ) : (
                <Typography lineHeight="1.1rem" fontSize="1.1rem">
                  Este mes el Producto menos vendido es{" "}
                  <span style={{ fontWeight: "bold" }}>{product}</span> con solo{" "}
                  <span style={{ fontWeight: "bold" }}>{total}</span> Venta/s.
                </Typography>
              )}
            </Grid>{" "}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default MediumCardVariantD;
