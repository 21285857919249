import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const SingleItem = ({ element, index, activeOptionId }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <ListItem
      onClick={() => navigate(element.url)}
      sx={{
        backgroundColor:
          index === activeOptionId
            ? // ? "rgba(0, 0, 0, 0.1)"
              theme.palette.action.selected
            : "transparent",
      }}
      disablePadding
    >
      <ListItemButton>
        <ListItemIcon>{element.icon}</ListItemIcon>
        <ListItemText primary={element.label} />
      </ListItemButton>
    </ListItem>
  );
};

export default SingleItem;
