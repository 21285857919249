import React, { useState, useRef, useEffect } from "react";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "./table-styles.css"; // Optional Theme applied to the grid
import { Grid, Paper, useTheme } from "@mui/material";

import { idFilterParams, tableFilterDefaultParams } from "./table-filters";
import { generateFakeRowData } from "./fakeRowData";
import PercentageRender from "./cell-renders/percentage.component";
import TableHeader from "./table-header/table-header.component";
import { formatDates } from "../../../../utils/formatDate";

// eslint-disable-next-line
function createRowData(experimentsData, userInfo, finishedExperiments) {
  let experimentTypes = {
    promo_precio_unitario: "Promo Prec. Unit.",
    promo_combo_simple: "Promo Comb. Simpl.",
    promo_combo_combinado: "Promo Comb. Combi.",
    reemplazo_producto: "Reemplazo Prod.",
    ajuste_planograma: "Ajuste Planograma",
    baja_precio_sugerido: "Baja Prec. Suger.",
    alza_precio_sugerido: "Alza Prec. Suger.",
  };

  let experimentStatus = {
    running: "Iniciado",
    pending: "Pendiente",
    finished: "Finalizado",
  };

  let result = [];

  let allExperiments = experimentsData?.concat(finishedExperiments);
  // eslint-disable-next-line
  allExperiments?.map((data) => {
    let object = {
      "Id Experiment": data?.id,
      "Client Id": userInfo?.customer_name,
      "Experiment Status": experimentStatus[data?.status?.status],
      "Experiment Type": experimentTypes[data.experimentType.name],
      // "Store Id": `${data?.store?.id} - ${data?.store?.alias}`,
      "Store Id": `${data?.store?.alias}`,
      // "Old Product Id": `${data?.product?.id} - ${data?.product?.name}`,
      "Old Product Id": `${data?.product?.name}`,
      "New Product Id": data?.experimentType?.newProduct
        ? `${data?.experimentType?.newProduct?.name}`
        : data?.experimentType?.firstProduct &&
          data?.experimentType?.secondProduct
        ? `${data?.experimentType?.firstProduct?.name} + ${data?.experimentType?.secondProduct?.name}`
        : "N/A",
      Period: formatDates(data?.period?.start, data?.period?.end),
      "P-1 Units": data?.problem?.unitsSold,
      "P Units": data?.status?.unitsSold,
      "Δ Un": data?.status?.unitsSold - data?.problem?.unitsSold,
      "Δ Un(%)": `${parseFloat(
        ((data?.status?.unitsSold - data?.problem?.unitsSold) /
          data?.problem?.unitsSold) *
          100
      ).toFixed(0)}%`,
      "P-1 Total": `${parseFloat(
        data?.problem?.unitsSold * data?.status?.avgPrice
      )
        .toFixed(2)
        .replace(".", ",")}€`,
      "P Total": `${parseFloat(data?.status?.totalSold)
        .toFixed(2)
        .replace(".", ",")}€`,
      "Δ €": `${parseFloat(
        data?.status?.totalSold -
          data?.problem?.unitsSold * data?.status?.avgPrice
      )
        .toFixed(2)
        .replace(".", ",")}€`,
      "Δ €(%)": `${parseFloat(
        ((data?.status?.totalSold -
          data?.problem?.unitsSold * data?.status?.avgPrice) /
          (data?.problem?.unitsSold * data?.status?.avgPrice)) *
          100
      ).toFixed(0)}%`,
    };
    result.push(object);
  });

  return result;
}

const ReportingProfitabilityPriceTableView = ({
  experimentsData,
  userInfo,
  finishedExperimentsData,
}) => {
  const gridRef = useRef();

  // eslint-disable-next-line
  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState([]);

  // useEffect(
  //   () => {
  //     setRowData(
  //       createRowData(experimentsData, userInfo, finishedExperimentsData)
  //     );
  //   },
  //   // eslint-disable-next-line
  //   [experimentsData, finishedExperimentsData]
  // );

  const fakeRowData = generateFakeRowData(15, 20);

  // eslint-disable-next-line
  useEffect(
    () => {
      // Obtener el número de tiendas a partir de la primera fila de datos
      const storeColumnsCount = Object.keys(fakeRowData[0]).filter((key) =>
        key.startsWith("store")
      ).length;

      // Generar dinámicamente las columnas de tiendas
      const storeColumns = [];
      for (let i = 1; i <= storeColumnsCount; i++) {
        storeColumns.push({
          field: `store${i}`,
          headerName: `Maq ${i}`,
        });
      }
      // Definiciones de columnas incluyendo las estáticas y dinámicas
      const columns = [
        {
          field: "id",
          headerName: "Precio",
          minWidth: 200,
          filter: true,
          filterParams: idFilterParams, // Asume que idFilterParams está definido
        },
        {
          field: "share%",
          headerName: "SHARE %",
          minWidth: 100,
          cellRenderer: PercentageRender,
          valueFormatter: (params) => params.value,
        },
        ...storeColumns, // Agrega las columnas de tiendas dinámicamente
      ];
      setColDefs(columns);
    },
    // eslint-disable-next-line
    []
  );

  const theme = useTheme();
  const themeMode = theme.palette.mode;

  return (
    <Grid
      container
      sx={{
        marginBlock: "1rem",
        display: "flex",
        justifyContent: "center",
        paddingInline: "1rem",
      }}
    >
      <Grid
        component={Paper}
        elevation={2}
        item
        xs={12}
        sx={{ height: "60vh" }}
        className={
          themeMode === "dark" ? "ag-theme-alpine-dark" : "ag-theme-alpine"
        }
      >
        <TableHeader
          colDefs={colDefs}
          setColDefs={setColDefs}
          gridRef={gridRef}
          fileName="reporting-profitability-price-table"
        />
        <AgGridReact
          ref={gridRef}
          // pagination={pagination}
          // paginationPageSize={paginationPageSize}
          // paginationPageSizeSelector={paginationPageSizeSelector}
          rowData={fakeRowData}
          columnDefs={colDefs}
          defaultColDef={{
            flex: 1,
            filterParams: tableFilterDefaultParams,
          }}
          suppressMenuHide={true}
          // domLayout="autoHeight"
        />
      </Grid>
    </Grid>
  );
};

export default ReportingProfitabilityPriceTableView;
