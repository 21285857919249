import React from "react";
import { Grid, Paper, Typography, useTheme } from "@mui/material";
// import { ReactComponent as ArrowSmallRed } from "../../../assets/images/arrow-red-small.svg";
// import { ReactComponent as AlertsIcon } from "../../../assets/images/notifications-icon.svg";
import { ReactComponent as WarningAmberRoundedIcon } from "../../assets/images/warning-rounded-icon.svg";
import { ReactComponent as ErrorRoundedIcon } from "../../assets/images/error-rounded-icon.svg";
import { ReactComponent as InfoRoundedIcon } from "../../assets/images/info-rounded-icon.svg";
// import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
// import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
// import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import CloseIcon from "@mui/icons-material/Close";

const alertsCategoryEquivalency = {
  info: {
    title: "Información",
    icon: (color) => <InfoRoundedIcon style={{ height: "80%", fill: color }} />,
  },
  warning: {
    title: "Advertencia",
    icon: (color) => (
      <WarningAmberRoundedIcon style={{ height: "80%", fill: color }} />
    ),
  },
  error: {
    title: "Error",
    icon: (color) => (
      <ErrorRoundedIcon style={{ height: "80%", fill: color }} />
    ),
  },
};

const formatDatesRange = (dateString) => {
  const formatDate = (date) => {
    if (!date) return "-/-";
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year.slice(2)}`;
  };
  const [datePart] = dateString.split("T");
  const formattedDate = formatDate(datePart);

  return formattedDate;
};

function cleanString(text) {
  var pattern = /[^a-zA-Z0-9\s]/g;
  var cleanedText = text.replace(pattern, "");
  return cleanedText;
}

const AlertSmallCard = ({
  setConfirmDeleteDialogOpen,
  setAlertIdToDelete,
  category,
  dStore,
  dateCreated,
  id,
  message,
}) => {
  const theme = useTheme();
  return (
    <Grid container>
      <Paper
        elevation={3}
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          marginInline: "1rem",
          marginBlockEnd: "1rem",
          // border: "2px solid #727272",
          borderRadius: "1rem",
          padding: "0.5rem",
          borderTop: "1.5px solid rgba(0, 0, 0, 0.1)",
          minHeight: "8rem",
        }}
      >
        <CloseIcon
          onClick={() => {
            setConfirmDeleteDialogOpen(true);
            setAlertIdToDelete(id);
          }}
          sx={{
            position: "absolute",
            right: "0.4rem",
            top: "0.4rem",
            width: "1.2rem",
            height: "1.2rem",
            fill: "#663B73",
            // outline: "1px solid #663B73",
            borderRadius: "50%",
            backgroundColor: "#F8F8F8",
          }}
        />
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
          item
          xs={1}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              maxHeight: "1.5rem",
              width: "100%",
            }}
          >
            {/* <AlertsIcon style={{ height: "80%", fill: "#FF0707" }} /> */}
            {alertsCategoryEquivalency[category].icon(
              theme.palette.alerts[category].main
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {/* <ArrowSmallRed /> */}
          </Grid>
        </Grid>
        <Grid
          item
          sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
          xs={11}
        >
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              maxHeight: "1.5rem",
              marginInlineStart: "0.2rem",
            }}
            xs={12}
          >
            <Typography
              sx={{
                color: theme.palette.alerts[category].main,
                fontWeight: "bold",
                display: "flex",
              }}
            >
              {alertsCategoryEquivalency[category].title}
            </Typography>
            <Typography
              sx={{
                color: theme.palette.title.main,
                fontWeight: "bold",
                display: "flex",
                marginInlineStart: "1rem",
                fontSize: "0.85rem",
              }}
            >
              {formatDatesRange(dateCreated)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "start",
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                display: "flex",
                lineHeight: "1.1rem",
              }}
            >
              {message}
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              maxHeight: "1rem",
            }}
            xs={12}
          >
            <Typography
              sx={{
                fontSize: "0.8rem",
                fontWeight: "bold",
                overflow: "hidden",
                lineHeight: "0.8rem",
                color: theme.palette.title.main,
              }}
            >
              {dStore ? (
                <span>
                  {/* Máquina: {dStore.storeClusterId} {dStore.storeId?.slice(1)} */}
                  Máquina: {cleanString(dStore?.alias)}
                </span>
              ) : (
                <span>Notificación Global</span>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default AlertSmallCard;
