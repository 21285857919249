import { MenuItem, Select, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import customSelectStyles from "./customSelectStyles";

export const CustomPeriodSelector = ({
  name,
  value,
  onChange,
  items,
  label,
  disabled,
}) => {
  const theme = useTheme();

  return (
    <Select
      name={name}
      IconComponent={() => (
        <KeyboardArrowDownIcon sx={{ fontSize: "0.7rem", color: "#999" }} />
      )}
      MenuProps={{ sx: { width: "auto" } }}
      sx={customSelectStyles(
        theme.palette.title.main,
        theme.palette.background.default,
        theme.palette.divider.main
      )}
      value={value}
      onChange={onChange}
      disabled={disabled}
      inputProps={{ MenuProps: { disableScrollLock: true } }}
    >
      <MenuItem
        sx={{
          fontSize: "1rem",
          justifyContent: "center",
          color: theme.palette.divider.main,
        }}
        value="default"
      >
        {label}
      </MenuItem>
      {items?.map((element, i) => {
        return (
          <MenuItem
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            key={i}
            value={element.value}
          >
            {element.label}
          </MenuItem>
        );
      })}
    </Select>
  );
};
