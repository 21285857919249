export const fakeRowData = [
  {
    id: "Top 10 Totals",
    "Ene_€": "10596€",
    // Ene_U: 1846,
    "Ene_Share % €": 80,
    // "Ene_Δ PY €": 5,
    "Feb_€": "3000€",
    // Feb_U: 2200,
    "Feb_Share % €": 80,
    // "Feb_Δ PY €": 4,
    "Mar_€": "4000€",
    // Mar_U: 2100,
    "Mar_Share % €": 80,
    // "Mar_Δ PY €": 6,
    "Abr_€": "5000€",
    // Abr_U: 2000,
    "Abr_Share % €": 80,
    // "Abr_Δ PY €": 5,
    "May_€": "6000€",
    // May_U: 1900,
    "May_Share % €": 80,
    // "May_Δ PY €": 8,
    "Jun_€": "7000€",
    // Jun_U: 1800,
    "Jun_Share % €": 80,
    // "Jun_Δ PY €": 9,
    "Jul_€": "8000€",
    // Jul_U: 1700,
    "Jul_Share % €": 80,
    // "Jul_Δ PY €": 10,
    "Ago_€": "9000€",
    // Ago_U: 1600,
    "Ago_Share % €": 80,
    // "Ago_Δ PY €": 11,
    "Sep_€": "10000€",
    // Sep_U: 1500,
    "Sep_Share % €": 80,
    // "Sep_Δ PY €": 12,
    "Oct_€": "11000€",
    // Oct_U: 1400,
    "Oct_Share % €": 80,
    // "Oct_Δ PY €": 13,
    "Nov_€": "12000€",
    // Nov_U: 1300,
    "Nov_Share % €": 80,
    // "Nov_Δ PY €": 14,
    "Dic_€": "13000€",
    // Dic_U: 1200,
    "Dic_Share % €": 80,
    // "Dic_Δ PY €": 15,
  },
  {
    id: "Producto 1 - Agua",
    "Ene_€": "11000€",
    Ene_U: 1950,
    "Ene_Share % €": 11,
    "Ene_Δ PY €": 6,
    "Feb_€": "3200€",
    Feb_U: 2300,
    "Feb_Share % €": 9,
    "Feb_Δ PY €": 5,
    "Mar_€": "4200€",
    Mar_U: 2200,
    "Mar_Share % €": 10,
    "Mar_Δ PY €": 7,
    "Abr_€": "5300€",
    Abr_U: 2100,
    "Abr_Share % €": 8,
    "Abr_Δ PY €": 6,
    "May_€": "6200€",
    May_U: 2000,
    "May_Share % €": 12,
    "May_Δ PY €": 9,
    "Jun_€": "7300€",
    Jun_U: 1900,
    "Jun_Share % €": 13,
    "Jun_Δ PY €": 10,
    "Jul_€": "8400€",
    Jul_U: 1800,
    "Jul_Share % €": 14,
    "Jul_Δ PY €": 11,
    "Ago_€": "9500€",
    Ago_U: 1700,
    "Ago_Share % €": 15,
    "Ago_Δ PY €": 12,
    "Sep_€": "10600€",
    Sep_U: 1600,
    "Sep_Share % €": 16,
    "Sep_Δ PY €": 13,
    "Oct_€": "11700€",
    Oct_U: 1500,
    "Oct_Share % €": 17,
    "Oct_Δ PY €": 14,
    "Nov_€": "12800€",
    Nov_U: 1400,
    "Nov_Share % €": 18,
    "Nov_Δ PY €": 15,
    "Dic_€": "13900€",
    Dic_U: 1300,
    "Dic_Share % €": 19,
    "Dic_Δ PY €": 16,
  },
  {
    id: "Producto 2 - Coca Cola Zero",
    "Ene_€": "9500€",
    Ene_U: 1700,
    "Ene_Share % €": 9,
    "Ene_Δ PY €": 4,
    "Feb_€": "2800€",
    Feb_U: 2100,
    "Feb_Share % €": 7,
    "Feb_Δ PY €": 3,
    "Mar_€": "3900€",
    Mar_U: 2000,
    "Mar_Share % €": 8,
    "Mar_Δ PY €": 5,
    "Abr_€": "4900€",
    Abr_U: 1900,
    "Abr_Share % €": 6,
    "Abr_Δ PY €": 4,
    "May_€": "5900€",
    May_U: 1800,
    "May_Share % €": 10,
    "May_Δ PY €": 7,
    "Jun_€": "6900€",
    Jun_U: 1700,
    "Jun_Share % €": 11,
    "Jun_Δ PY €": 8,
    "Jul_€": "8000€",
    Jul_U: 1600,
    "Jul_Share % €": 12,
    "Jul_Δ PY €": 9,
    "Ago_€": "9100€",
    Ago_U: 1500,
    "Ago_Share % €": 13,
    "Ago_Δ PY €": 10,
    "Sep_€": "10200€",
    Sep_U: 1400,
    "Sep_Share % €": 14,
    "Sep_Δ PY €": 11,
    "Oct_€": "11300€",
    Oct_U: 1300,
    "Oct_Share % €": 15,
    "Oct_Δ PY €": 12,
    "Nov_€": "12400€",
    Nov_U: 1200,
    "Nov_Share % €": 16,
    "Nov_Δ PY €": 13,
    "Dic_€": "13500€",
    Dic_U: 1100,
    "Dic_Share % €": 17,
    "Dic_Δ PY €": 14,
  },
  {
    id: "Producto 3",
    "Ene_€": "9000€",
    Ene_U: 1600,
    "Ene_Share % €": 8,
    "Ene_Δ PY €": 3,
    "Feb_€": "2700€",
    Feb_U: 2000,
    "Feb_Share % €": 6,
    "Feb_Δ PY €": 2,
    "Mar_€": "3800€",
    Mar_U: 1900,
    "Mar_Share % €": 7,
    "Mar_Δ PY €": 4,
    "Abr_€": "4700€",
    Abr_U: 1800,
    "Abr_Share % €": 5,
    "Abr_Δ PY €": 3,
    "May_€": "5700€",
    May_U: 1700,
    "May_Share % €": 9,
    "May_Δ PY €": 6,
    "Jun_€": "6700€",
    Jun_U: 1600,
    "Jun_Share % €": 10,
    "Jun_Δ PY €": 7,
    "Jul_€": "7800€",
    Jul_U: 1500,
    "Jul_Share % €": 11,
    "Jul_Δ PY €": 8,
    "Ago_€": "8900€",
    Ago_U: 1400,
    "Ago_Share % €": 12,
    "Ago_Δ PY €": 9,
    "Sep_€": "10000€",
    Sep_U: 1300,
    "Sep_Share % €": 13,
    "Sep_Δ PY €": 10,
    "Oct_€": "11100€",
    Oct_U: 1200,
    "Oct_Share % €": 14,
    "Oct_Δ PY €": 11,
    "Nov_€": "12200€",
    Nov_U: 1100,
    "Nov_Share % €": 15,
    "Nov_Δ PY €": 12,
    "Dic_€": "13300€",
    Dic_U: 1000,
    "Dic_Share % €": 16,
    "Dic_Δ PY €": 13,
  },
  {
    id: "Producto 4",
    "Ene_€": "8800€",
    Ene_U: 1500,
    "Ene_Share % €": 7,
    "Ene_Δ PY €": 2,
    "Feb_€": "2600€",
    Feb_U: 1900,
    "Feb_Share % €": 5,
    "Feb_Δ PY €": 1,
    "Mar_€": "3700€",
    Mar_U: 1800,
    "Mar_Share % €": 6,
    "Mar_Δ PY €": 3,
    "Abr_€": "4600€",
    Abr_U: 1700,
    "Abr_Share % €": 4,
    "Abr_Δ PY €": 2,
    "May_€": "5600€",
    May_U: 1600,
    "May_Share % €": 8,
    "May_Δ PY €": 5,
    "Jun_€": "6600€",
    Jun_U: 1500,
    "Jun_Share % €": 9,
    "Jun_Δ PY €": 6,
    "Jul_€": "7600€",
    Jul_U: 1400,
    "Jul_Share % €": 10,
    "Jul_Δ PY €": 7,
    "Ago_€": "8700€",
    Ago_U: 1300,
    "Ago_Share % €": 11,
    "Ago_Δ PY €": 8,
    "Sep_€": "9800€",
    Sep_U: 1200,
    "Sep_Share % €": 12,
    "Sep_Δ PY €": 9,
    "Oct_€": "10900€",
    Oct_U: 1100,
    "Oct_Share % €": 13,
    "Oct_Δ PY €": 10,
    "Nov_€": "12000€",
    Nov_U: 1000,
    "Nov_Share % €": 14,
    "Nov_Δ PY €": 11,
    "Dic_€": "13100€",
    Dic_U: 900,
    "Dic_Share % €": 15,
    "Dic_Δ PY €": 12,
  },
  {
    id: "Producto 5",
    "Ene_€": "8700€",
    Ene_U: 1400,
    "Ene_Share % €": 6,
    "Ene_Δ PY €": 1,
    "Feb_€": "2500€",
    Feb_U: 1800,
    "Feb_Share % €": 4,
    "Feb_Δ PY €": 0,
    "Mar_€": "3600€",
    Mar_U: 1700,
    "Mar_Share % €": 5,
    "Mar_Δ PY €": 2,
    "Abr_€": "4500€",
    Abr_U: 1600,
    "Abr_Share % €": 3,
    "Abr_Δ PY €": 1,
    "May_€": "5500€",
    May_U: 1500,
    "May_Share % €": 7,
    "May_Δ PY €": 4,
    "Jun_€": "6500€",
    Jun_U: 1400,
    "Jun_Share % €": 8,
    "Jun_Δ PY €": 5,
    "Jul_€": "7500€",
    Jul_U: 1300,
    "Jul_Share % €": 9,
    "Jul_Δ PY €": 6,
    "Ago_€": "8600€",
    Ago_U: 1200,
    "Ago_Share % €": 10,
    "Ago_Δ PY €": 7,
    "Sep_€": "9700€",
    Sep_U: 1100,
    "Sep_Share % €": 11,
    "Sep_Δ PY €": 8,
    "Oct_€": "10800€",
    Oct_U: 1000,
    "Oct_Share % €": 12,
    "Oct_Δ PY €": 9,
    "Nov_€": "11900€",
    Nov_U: 900,
    "Nov_Share % €": 13,
    "Nov_Δ PY €": 10,
    "Dic_€": "13000€",
    Dic_U: 800,
    "Dic_Share % €": 14,
    "Dic_Δ PY €": 11,
  },
  {
    id: "Producto 6",
    "Ene_€": "8500€",
    Ene_U: 1300,
    "Ene_Share % €": 5,
    "Ene_Δ PY €": 0,
    "Feb_€": "2400€",
    Feb_U: 1700,
    "Feb_Share % €": 3,
    "Feb_Δ PY €": -1,
    "Mar_€": "3500€",
    Mar_U: 1600,
    "Mar_Share % €": 4,
    "Mar_Δ PY €": 1,
    "Abr_€": "4400€",
    Abr_U: 1500,
    "Abr_Share % €": 2,
    "Abr_Δ PY €": 0,
    "May_€": "5400€",
    May_U: 1400,
    "May_Share % €": 6,
    "May_Δ PY €": 3,
    "Jun_€": "6400€",
    Jun_U: 1300,
    "Jun_Share % €": 7,
    "Jun_Δ PY €": 4,
    "Jul_€": "7400€",
    Jul_U: 1200,
    "Jul_Share % €": 8,
    "Jul_Δ PY €": 5,
    "Ago_€": "8500€",
    Ago_U: 1100,
    "Ago_Share % €": 9,
    "Ago_Δ PY €": 6,
    "Sep_€": "9600€",
    Sep_U: 1000,
    "Sep_Share % €": 10,
    "Sep_Δ PY €": 7,
    "Oct_€": "10700€",
    Oct_U: 900,
    "Oct_Share % €": 11,
    "Oct_Δ PY €": 8,
    "Nov_€": "11800€",
    Nov_U: 800,
    "Nov_Share % €": 12,
    "Nov_Δ PY €": 9,
    "Dic_€": "12900€",
    Dic_U: 700,
    "Dic_Share % €": 13,
    "Dic_Δ PY €": 10,
  },
  {
    id: "Producto 7",
    "Ene_€": "8400€",
    Ene_U: 1200,
    "Ene_Share % €": 4,
    "Ene_Δ PY €": -1,
    "Feb_€": "2300€",
    Feb_U: 1600,
    "Feb_Share % €": 2,
    "Feb_Δ PY €": -2,
    "Mar_€": "3400€",
    Mar_U: 1500,
    "Mar_Share % €": 3,
    "Mar_Δ PY €": 0,
    "Abr_€": "4300€",
    Abr_U: 1400,
    "Abr_Share % €": 1,
    "Abr_Δ PY €": -1,
    "May_€": "5300€",
    May_U: 1300,
    "May_Share % €": 5,
    "May_Δ PY €": 2,
    "Jun_€": "6300€",
    Jun_U: 1200,
    "Jun_Share % €": 6,
    "Jun_Δ PY €": 3,
    "Jul_€": "7300€",
    Jul_U: 1100,
    "Jul_Share % €": 7,
    "Jul_Δ PY €": 4,
    "Ago_€": "8400€",
    Ago_U: 1000,
    "Ago_Share % €": 8,
    "Ago_Δ PY €": 5,
    "Sep_€": "9500€",
    Sep_U: 900,
    "Sep_Share % €": 9,
    "Sep_Δ PY €": 6,
    "Oct_€": "10600€",
    Oct_U: 800,
    "Oct_Share % €": 10,
    "Oct_Δ PY €": 7,
    "Nov_€": "11700€",
    Nov_U: 700,
    "Nov_Share % €": 11,
    "Nov_Δ PY €": 8,
    "Dic_€": "12800€",
    Dic_U: 600,
    "Dic_Share % €": 12,
    "Dic_Δ PY €": 9,
  },
  {
    id: "Producto 8",
    "Ene_€": "8300€",
    Ene_U: 1100,
    "Ene_Share % €": 3,
    "Ene_Δ PY €": -2,
    "Feb_€": "2200€",
    Feb_U: 1500,
    "Feb_Share % €": 1,
    "Feb_Δ PY €": -3,
    "Mar_€": "3300€",
    Mar_U: 1400,
    "Mar_Share % €": 2,
    "Mar_Δ PY €": -1,
    "Abr_€": "4200€",
    Abr_U: 1300,
    "Abr_Share % €": 0,
    "Abr_Δ PY €": -2,
    "May_€": "5200€",
    May_U: 1200,
    "May_Share % €": 4,
    "May_Δ PY €": 1,
    "Jun_€": "6200€",
    Jun_U: 1100,
    "Jun_Share % €": 5,
    "Jun_Δ PY €": 2,
    "Jul_€": "7200€",
    Jul_U: 1000,
    "Jul_Share % €": 6,
    "Jul_Δ PY €": 3,
    "Ago_€": "8300€",
    Ago_U: 900,
    "Ago_Share % €": 7,
    "Ago_Δ PY €": 4,
    "Sep_€": "9400€",
    Sep_U: 800,
    "Sep_Share % €": 8,
    "Sep_Δ PY €": 5,
    "Oct_€": "10500€",
    Oct_U: 700,
    "Oct_Share % €": 9,
    "Oct_Δ PY €": 6,
    "Nov_€": "11600€",
    Nov_U: 600,
    "Nov_Share % €": 10,
    "Nov_Δ PY €": 7,
    "Dic_€": "12700€",
    Dic_U: 500,
    "Dic_Share % €": 11,
    "Dic_Δ PY €": 8,
  },
  {
    id: "Producto 9",
    "Ene_€": "8200€",
    Ene_U: 1000,
    "Ene_Share % €": 2,
    "Ene_Δ PY €": -3,
    "Feb_€": "2100€",
    Feb_U: 1400,
    "Feb_Share % €": 0,
    "Feb_Δ PY €": -4,
    "Mar_€": "3200€",
    Mar_U: 1300,
    "Mar_Share % €": 1,
    "Mar_Δ PY €": -2,
    "Abr_€": "4100€",
    Abr_U: 1200,
    "Abr_Share % €": -1,
    "Abr_Δ PY €": -3,
    "May_€": "5100€",
    May_U: 1100,
    "May_Share % €": 3,
    "May_Δ PY €": 0,
    "Jun_€": "6100€",
    Jun_U: 1000,
    "Jun_Share % €": 4,
    "Jun_Δ PY €": 1,
    "Jul_€": "7100€",
    Jul_U: 900,
    "Jul_Share % €": 5,
    "Jul_Δ PY €": 2,
    "Ago_€": "8200€",
    Ago_U: 800,
    "Ago_Share % €": 6,
    "Ago_Δ PY €": 3,
    "Sep_€": "9300€",
    Sep_U: 700,
    "Sep_Share % €": 7,
    "Sep_Δ PY €": 4,
    "Oct_€": "10400€",
    Oct_U: 600,
    "Oct_Share % €": 8,
    "Oct_Δ PY €": 5,
    "Nov_€": "11500€",
    Nov_U: 500,
    "Nov_Share % €": 9,
    "Nov_Δ PY €": 6,
    "Dic_€": "12600€",
    Dic_U: 400,
    "Dic_Share % €": 10,
    "Dic_Δ PY €": 7,
  },
  {
    id: "Producto 10",
    "Ene_€": "8100€",
    Ene_U: 900,
    "Ene_Share % €": 1,
    "Ene_Δ PY €": -4,
    "Feb_€": "2000€",
    Feb_U: 1300,
    "Feb_Share % €": -1,
    "Feb_Δ PY €": -5,
    "Mar_€": "3100€",
    Mar_U: 1200,
    "Mar_Share % €": 0,
    "Mar_Δ PY €": -3,
    "Abr_€": "4000€",
    Abr_U: 1100,
    "Abr_Share % €": -2,
    "Abr_Δ PY €": -4,
    "May_€": "5000€",
    May_U: 1000,
    "May_Share % €": 2,
    "May_Δ PY €": -1,
    "Jun_€": "6000€",
    Jun_U: 900,
    "Jun_Share % €": 3,
    "Jun_Δ PY €": 0,
    "Jul_€": "7000€",
    Jul_U: 800,
    "Jul_Share % €": 4,
    "Jul_Δ PY €": 1,
    "Ago_€": "8100€",
    Ago_U: 700,
    "Ago_Share % €": 5,
    "Ago_Δ PY €": 2,
    "Sep_€": "9200€",
    Sep_U: 600,
    "Sep_Share % €": 6,
    "Sep_Δ PY €": 3,
    "Oct_€": "10300€",
    Oct_U: 500,
    "Oct_Share % €": 7,
    "Oct_Δ PY €": 4,
    "Nov_€": "11400€",
    Nov_U: 400,
    "Nov_Share % €": 8,
    "Nov_Δ PY €": 5,
    "Dic_€": "12500€",
    Dic_U: 300,
    "Dic_Share % €": 9,
    "Dic_Δ PY €": 6,
  },
  //   {
  //     id: "Producto 11",
  //     "Ene_€": "8000€",
  //     Ene_U: 800,
  //     "Ene_Share % €": 0,
  //     "Ene_Δ PY €": -5,
  //     "Feb_€": "1900€",
  //     Feb_U: 1200,
  //     "Feb_Share % €": -2,
  //     "Feb_Δ PY €": -6,
  //     "Mar_€": "3000€",
  //     Mar_U: 1100,
  //     "Mar_Share % €": -1,
  //     "Mar_Δ PY €": -4,
  //     "Abr_€": "3900€",
  //     Abr_U: 1000,
  //     "Abr_Share % €": -3,
  //     "Abr_Δ PY €": -5,
  //     "May_€": "4900€",
  //     May_U: 900,
  //     "May_Share % €": 0,
  //     "May_Δ PY €": -2,
  //     "Jun_€": "5900€",
  //     Jun_U: 800,
  //     "Jun_Share % €": 1,
  //     "Jun_Δ PY €": -1,
  //     "Jul_€": "6900€",
  //     Jul_U: 700,
  //     "Jul_Share % €": 2,
  //     "Jul_Δ PY €": 0,
  //     "Ago_€": "8000€",
  //     Ago_U: 600,
  //     "Ago_Share % €": 3,
  //     "Ago_Δ PY €": 1,
  //     "Sep_€": "9100€",
  //     Sep_U: 500,
  //     "Sep_Share % €": 4,
  //     "Sep_Δ PY €": 2,
  //     "Oct_€": "10200€",
  //     Oct_U: 400,
  //     "Oct_Share % €": 5,
  //     "Oct_Δ PY €": 3,
  //     "Nov_€": "11300€",
  //     Nov_U: 300,
  //     "Nov_Share % €": 6,
  //     "Nov_Δ PY €": 4,
  //     "Dic_€": "12400€",
  //     Dic_U: 200,
  //     "Dic_Share % €": 7,
  //     "Dic_Δ PY €": 5,
  //   },
];
export const fakeRowData2 = [
  {
    id: "EXP Totals",
    "Ene_Δ€": "10596€",
    Ene_ΔU: 1846,
    // "Ene_Share % €": 80,
    // "Ene_Δ PY €": 5,
    "Feb_Δ€": "3000€",
    Feb_ΔU: 2200,
    // "Feb_Share % €": 80,
    // "Feb_Δ PY €": 4,
    "Mar_Δ€": "4000€",
    Mar_ΔU: 2100,
    // "Mar_Share % €": 80,
    // "Mar_Δ PY €": 6,
    "Abr_Δ€": "5000€",
    Abr_ΔU: 2000,
    // "Abr_Share % €": 80,
    // "Abr_Δ PY €": 5,
    "May_Δ€": "6000€",
    May_ΔU: 1900,
    // "May_Share % €": 80,
    // "May_Δ PY €": 8,
    "Jun_Δ€": "7000€",
    Jun_ΔU: 1800,
    // "Jun_Share % €": 80,
    // "Jun_Δ PY €": 9,
    "Jul_Δ€": "8000€",
    Jul_ΔU: 1700,
    // "Jul_Share % €": 80,
    // "Jul_Δ PY €": 10,
    "Ago_Δ€": "9000€",
    Ago_ΔU: 1600,
    // "Ago_Share % €": 80,
    // "Ago_Δ PY €": 11,
    "Sep_Δ€": "10000€",
    Sep_ΔU: 1500,
    // "Sep_Share % €": 80,
    // "Sep_Δ PY €": 12,
    "Oct_Δ€": "11000€",
    Oct_ΔU: 1400,
    // "Oct_Share % €": 80,
    // "Oct_Δ PY €": 13,
    "Nov_Δ€": "12000€",
    Nov_ΔU: 1300,
    // "Nov_Share % €": 80,
    // "Nov_Δ PY €": 14,
    "Dic_Δ€": "13000€",
    // Dic_U: 1200,
    // "Dic_Share % €": 80,
    // "Dic_Δ PY €": 15,
  },
  {
    id: "EXP - Agua + Sandwich <> COMBOS",
    "Ene_Δ€": "11000€",
    Ene_ΔU: 1950,
    "Ene_Share % €": 11,
    "Ene_Δ P-1": 6,
    "Feb_Δ€": "3200€",
    Feb_ΔU: 2300,
    "Feb_Share % €": 9,
    "Feb_Δ P-1": 5,
    "Mar_Δ€": "4200€",
    Mar_ΔU: 2200,
    "Mar_Share % €": 10,
    "Mar_Δ P-1": 7,
    "Abr_Δ€": "5300€",
    Abr_ΔU: 2100,
    "Abr_Share % €": 8,
    "Abr_Δ P-1": 6,
    "May_Δ€": "6200€",
    May_ΔU: 2000,
    "May_Share % €": 12,
    "May_Δ P-1": 9,
    "Jun_Δ€": "7300€",
    Jun_ΔU: 1900,
    "Jun_Share % €": 13,
    "Jun_Δ P-1": 10,
    "Jul_Δ€": "8400€",
    Jul_ΔU: 1800,
    "Jul_Share % €": 14,
    "Jul_Δ P-1": 11,
    "Ago_Δ€": "9500€",
    Ago_ΔU: 1700,
    "Ago_Share % €": 15,
    "Ago_Δ P-1": 12,
    "Sep_Δ€": "10600€",
    Sep_ΔU: 1600,
    "Sep_Share % €": 16,
    "Sep_Δ P-1": 13,
    "Oct_Δ€": "11700€",
    Oct_ΔU: 1500,
    "Oct_Share % €": 17,
    "Oct_Δ P-1": 14,
    "Nov_Δ€": "12800€",
    Nov_ΔU: 1400,
    "Nov_Share % €": 18,
    "Nov_Δ P-1": 15,
    "Dic_Δ€": "13900€",
    Dic_ΔU: 1300,
    "Dic_Share % €": 19,
    "Dic_Δ P-1": 16,
  },
  {
    id: "EXP - Cola Zero + Sandwich <> COMBOS",
    "Ene_Δ€": "11000€",
    Ene_ΔU: 1950,
    "Ene_Share % €": 11,
    "Ene_Δ P-1": 6,
    "Feb_Δ€": "3200€",
    Feb_ΔU: 2300,
    "Feb_Share % €": 9,
    "Feb_Δ P-1": 5,
    "Mar_Δ€": "4200€",
    Mar_ΔU: 2200,
    "Mar_Share % €": 10,
    "Mar_Δ P-1": 7,
    "Abr_Δ€": "5300€",
    Abr_ΔU: 2100,
    "Abr_Share % €": 8,
    "Abr_Δ P-1": 6,
    "May_Δ€": "6200€",
    May_ΔU: 2000,
    "May_Share % €": 12,
    "May_Δ P-1": 9,
    "Jun_Δ€": "7300€",
    Jun_ΔU: 1900,
    "Jun_Share % €": 13,
    "Jun_Δ P-1": 10,
    "Jul_Δ€": "8400€",
    Jul_ΔU: 1800,
    "Jul_Share % €": 14,
    "Jul_Δ P-1": 11,
    "Ago_Δ€": "9500€",
    Ago_ΔU: 1700,
    "Ago_Share % €": 15,
    "Ago_Δ P-1": 12,
    "Sep_Δ€": "10600€",
    Sep_ΔU: 1600,
    "Sep_Share % €": 16,
    "Sep_Δ P-1": 13,
    "Oct_Δ€": "11700€",
    Oct_ΔU: 1500,
    "Oct_Share % €": 17,
    "Oct_Δ P-1": 14,
    "Nov_Δ€": "12800€",
    Nov_ΔU: 1400,
    "Nov_Share % €": 18,
    "Nov_Δ P-1": 15,
    "Dic_Δ€": "13900€",
    Dic_ΔU: 1300,
    "Dic_Share % €": 19,
    "Dic_Δ P-1": 16,
  },
  {
    id: "EXP - Producto 3 <>  PRECIO PROMO",
    "Ene_Δ€": "11000€",
    Ene_ΔU: 1950,
    "Ene_Share % €": 11,
    "Ene_Δ P-1": 6,
    "Feb_Δ€": "3200€",
    Feb_ΔU: 2300,
    "Feb_Share % €": 9,
    "Feb_Δ P-1": 5,
    "Mar_Δ€": "4200€",
    Mar_ΔU: 2200,
    "Mar_Share % €": 10,
    "Mar_Δ P-1": 7,
    "Abr_Δ€": "5300€",
    Abr_ΔU: 2100,
    "Abr_Share % €": 8,
    "Abr_Δ P-1": 6,
    "May_Δ€": "6200€",
    May_ΔU: 2000,
    "May_Share % €": 12,
    "May_Δ P-1": 9,
    "Jun_Δ€": "7300€",
    Jun_ΔU: 1900,
    "Jun_Share % €": 13,
    "Jun_Δ P-1": 10,
    "Jul_Δ€": "8400€",
    Jul_ΔU: 1800,
    "Jul_Share % €": 14,
    "Jul_Δ P-1": 11,
    "Ago_Δ€": "9500€",
    Ago_ΔU: 1700,
    "Ago_Share % €": 15,
    "Ago_Δ P-1": 12,
    "Sep_Δ€": "10600€",
    Sep_ΔU: 1600,
    "Sep_Share % €": 16,
    "Sep_Δ P-1": 13,
    "Oct_Δ€": "11700€",
    Oct_ΔU: 1500,
    "Oct_Share % €": 17,
    "Oct_Δ P-1": 14,
    "Nov_Δ€": "12800€",
    Nov_ΔU: 1400,
    "Nov_Share % €": 18,
    "Nov_Δ P-1": 15,
    "Dic_Δ€": "13900€",
    Dic_ΔU: 1300,
    "Dic_Share % €": 19,
    "Dic_Δ P-1": 16,
  },
  {
    id: "EXP - Producto 4 <> COMBO",
    "Ene_Δ€": "11000€",
    Ene_ΔU: 1950,
    "Ene_Share % €": 11,
    "Ene_Δ P-1": 6,
    "Feb_Δ€": "3200€",
    Feb_ΔU: 2300,
    "Feb_Share % €": 9,
    "Feb_Δ P-1": 5,
    "Mar_Δ€": "4200€",
    Mar_ΔU: 2200,
    "Mar_Share % €": 10,
    "Mar_Δ P-1": 7,
    "Abr_Δ€": "5300€",
    Abr_ΔU: 2100,
    "Abr_Share % €": 8,
    "Abr_Δ P-1": 6,
    "May_Δ€": "6200€",
    May_ΔU: 2000,
    "May_Share % €": 12,
    "May_Δ P-1": 9,
    "Jun_Δ€": "7300€",
    Jun_ΔU: 1900,
    "Jun_Share % €": 13,
    "Jun_Δ P-1": 10,
    "Jul_Δ€": "8400€",
    Jul_ΔU: 1800,
    "Jul_Share % €": 14,
    "Jul_Δ P-1": 11,
    "Ago_Δ€": "9500€",
    Ago_ΔU: 1700,
    "Ago_Share % €": 15,
    "Ago_Δ P-1": 12,
    "Sep_Δ€": "10600€",
    Sep_ΔU: 1600,
    "Sep_Share % €": 16,
    "Sep_Δ P-1": 13,
    "Oct_Δ€": "11700€",
    Oct_ΔU: 1500,
    "Oct_Share % €": 17,
    "Oct_Δ P-1": 14,
    "Nov_Δ€": "12800€",
    Nov_ΔU: 1400,
    "Nov_Share % €": 18,
    "Nov_Δ P-1": 15,
    "Dic_Δ€": "13900€",
    Dic_ΔU: 1300,
    "Dic_Share % €": 19,
    "Dic_Δ P-1": 16,
  },
  {
    id: "EXP - Producto 5",
    "Ene_Δ€": "11000€",
    Ene_ΔU: 1950,
    "Ene_Share % €": 11,
    "Ene_Δ P-1": 6,
    "Feb_Δ€": "3200€",
    Feb_ΔU: 2300,
    "Feb_Share % €": 9,
    "Feb_Δ P-1": 5,
    "Mar_Δ€": "4200€",
    Mar_ΔU: 2200,
    "Mar_Share % €": 10,
    "Mar_Δ P-1": 7,
    "Abr_Δ€": "5300€",
    Abr_ΔU: 2100,
    "Abr_Share % €": 8,
    "Abr_Δ P-1": 6,
    "May_Δ€": "6200€",
    May_ΔU: 2000,
    "May_Share % €": 12,
    "May_Δ P-1": 9,
    "Jun_Δ€": "7300€",
    Jun_ΔU: 1900,
    "Jun_Share % €": 13,
    "Jun_Δ P-1": 10,
    "Jul_Δ€": "8400€",
    Jul_ΔU: 1800,
    "Jul_Share % €": 14,
    "Jul_Δ P-1": 11,
    "Ago_Δ€": "9500€",
    Ago_ΔU: 1700,
    "Ago_Share % €": 15,
    "Ago_Δ P-1": 12,
    "Sep_Δ€": "10600€",
    Sep_ΔU: 1600,
    "Sep_Share % €": 16,
    "Sep_Δ P-1": 13,
    "Oct_Δ€": "11700€",
    Oct_ΔU: 1500,
    "Oct_Share % €": 17,
    "Oct_Δ P-1": 14,
    "Nov_Δ€": "12800€",
    Nov_ΔU: 1400,
    "Nov_Share % €": 18,
    "Nov_Δ P-1": 15,
    "Dic_Δ€": "13900€",
    Dic_ΔU: 1300,
    "Dic_Share % €": 19,
    "Dic_Δ P-1": 16,
  },
  {
    id: "EXP - Producto 6",
    "Ene_Δ€": "11000€",
    Ene_ΔU: 1950,
    "Ene_Share % €": 11,
    "Ene_Δ P-1": 6,
    "Feb_Δ€": "3200€",
    Feb_ΔU: 2300,
    "Feb_Share % €": 9,
    "Feb_Δ P-1": 5,
    "Mar_Δ€": "4200€",
    Mar_ΔU: 2200,
    "Mar_Share % €": 10,
    "Mar_Δ P-1": 7,
    "Abr_Δ€": "5300€",
    Abr_ΔU: 2100,
    "Abr_Share % €": 8,
    "Abr_Δ P-1": 6,
    "May_Δ€": "6200€",
    May_ΔU: 2000,
    "May_Share % €": 12,
    "May_Δ P-1": 9,
    "Jun_Δ€": "7300€",
    Jun_ΔU: 1900,
    "Jun_Share % €": 13,
    "Jun_Δ P-1": 10,
    "Jul_Δ€": "8400€",
    Jul_ΔU: 1800,
    "Jul_Share % €": 14,
    "Jul_Δ P-1": 11,
    "Ago_Δ€": "9500€",
    Ago_ΔU: 1700,
    "Ago_Share % €": 15,
    "Ago_Δ P-1": 12,
    "Sep_Δ€": "10600€",
    Sep_ΔU: 1600,
    "Sep_Share % €": 16,
    "Sep_Δ P-1": 13,
    "Oct_Δ€": "11700€",
    Oct_ΔU: 1500,
    "Oct_Share % €": 17,
    "Oct_Δ P-1": 14,
    "Nov_Δ€": "12800€",
    Nov_ΔU: 1400,
    "Nov_Share % €": 18,
    "Nov_Δ P-1": 15,
    "Dic_Δ€": "13900€",
    Dic_ΔU: 1300,
    "Dic_Share % €": 19,
    "Dic_Δ P-1": 16,
  },
  {
    id: "EXP - Producto 7",
    "Ene_Δ€": "11000€",
    Ene_ΔU: 1950,
    "Ene_Share % €": 11,
    "Ene_Δ P-1": 6,
    "Feb_Δ€": "3200€",
    Feb_ΔU: 2300,
    "Feb_Share % €": 9,
    "Feb_Δ P-1": 5,
    "Mar_Δ€": "4200€",
    Mar_ΔU: 2200,
    "Mar_Share % €": 10,
    "Mar_Δ P-1": 7,
    "Abr_Δ€": "5300€",
    Abr_ΔU: 2100,
    "Abr_Share % €": 8,
    "Abr_Δ P-1": 6,
    "May_Δ€": "6200€",
    May_ΔU: 2000,
    "May_Share % €": 12,
    "May_Δ P-1": 9,
    "Jun_Δ€": "7300€",
    Jun_ΔU: 1900,
    "Jun_Share % €": 13,
    "Jun_Δ P-1": 10,
    "Jul_Δ€": "8400€",
    Jul_ΔU: 1800,
    "Jul_Share % €": 14,
    "Jul_Δ P-1": 11,
    "Ago_Δ€": "9500€",
    Ago_ΔU: 1700,
    "Ago_Share % €": 15,
    "Ago_Δ P-1": 12,
    "Sep_Δ€": "10600€",
    Sep_ΔU: 1600,
    "Sep_Share % €": 16,
    "Sep_Δ P-1": 13,
    "Oct_Δ€": "11700€",
    Oct_ΔU: 1500,
    "Oct_Share % €": 17,
    "Oct_Δ P-1": 14,
    "Nov_Δ€": "12800€",
    Nov_ΔU: 1400,
    "Nov_Share % €": 18,
    "Nov_Δ P-1": 15,
    "Dic_Δ€": "13900€",
    Dic_ΔU: 1300,
    "Dic_Share % €": 19,
    "Dic_Δ P-1": 16,
  },
  {
    id: "EXP - Producto 21 <> REEMPLAZO",
    "Ene_Δ€": "11000€",
    Ene_ΔU: 1950,
    "Ene_Share % €": 11,
    "Ene_Δ P-1": 6,
    "Feb_Δ€": "3200€",
    Feb_ΔU: 2300,
    "Feb_Share % €": 9,
    "Feb_Δ P-1": 5,
    "Mar_Δ€": "4200€",
    Mar_ΔU: 2200,
    "Mar_Share % €": 10,
    "Mar_Δ P-1": 7,
    "Abr_Δ€": "5300€",
    Abr_ΔU: 2100,
    "Abr_Share % €": 8,
    "Abr_Δ P-1": 6,
    "May_Δ€": "6200€",
    May_ΔU: 2000,
    "May_Share % €": 12,
    "May_Δ P-1": 9,
    "Jun_Δ€": "7300€",
    Jun_ΔU: 1900,
    "Jun_Share % €": 13,
    "Jun_Δ P-1": 10,
    "Jul_Δ€": "8400€",
    Jul_ΔU: 1800,
    "Jul_Share % €": 14,
    "Jul_Δ P-1": 11,
    "Ago_Δ€": "9500€",
    Ago_ΔU: 1700,
    "Ago_Share % €": 15,
    "Ago_Δ P-1": 12,
    "Sep_Δ€": "10600€",
    Sep_ΔU: 1600,
    "Sep_Share % €": 16,
    "Sep_Δ P-1": 13,
    "Oct_Δ€": "11700€",
    Oct_ΔU: 1500,
    "Oct_Share % €": 17,
    "Oct_Δ P-1": 14,
    "Nov_Δ€": "12800€",
    Nov_ΔU: 1400,
    "Nov_Share % €": 18,
    "Nov_Δ P-1": 15,
    "Dic_Δ€": "13900€",
    Dic_ΔU: 1300,
    "Dic_Share % €": 19,
    "Dic_Δ P-1": 16,
  },
  {
    id: "EXP - Producto 23 <> COMBO",
    "Ene_Δ€": "11000€",
    Ene_ΔU: 1950,
    "Ene_Share % €": 11,
    "Ene_Δ P-1": 6,
    "Feb_Δ€": "3200€",
    Feb_ΔU: 2300,
    "Feb_Share % €": 9,
    "Feb_Δ P-1": 5,
    "Mar_Δ€": "4200€",
    Mar_ΔU: 2200,
    "Mar_Share % €": 10,
    "Mar_Δ P-1": 7,
    "Abr_Δ€": "5300€",
    Abr_ΔU: 2100,
    "Abr_Share % €": 8,
    "Abr_Δ P-1": 6,
    "May_Δ€": "6200€",
    May_ΔU: 2000,
    "May_Share % €": 12,
    "May_Δ P-1": 9,
    "Jun_Δ€": "7300€",
    Jun_ΔU: 1900,
    "Jun_Share % €": 13,
    "Jun_Δ P-1": 10,
    "Jul_Δ€": "8400€",
    Jul_ΔU: 1800,
    "Jul_Share % €": 14,
    "Jul_Δ P-1": 11,
    "Ago_Δ€": "9500€",
    Ago_ΔU: 1700,
    "Ago_Share % €": 15,
    "Ago_Δ P-1": 12,
    "Sep_Δ€": "10600€",
    Sep_ΔU: 1600,
    "Sep_Share % €": 16,
    "Sep_Δ P-1": 13,
    "Oct_Δ€": "11700€",
    Oct_ΔU: 1500,
    "Oct_Share % €": 17,
    "Oct_Δ P-1": 14,
    "Nov_Δ€": "12800€",
    Nov_ΔU: 1400,
    "Nov_Share % €": 18,
    "Nov_Δ P-1": 15,
    "Dic_Δ€": "13900€",
    Dic_ΔU: 1300,
    "Dic_Share % €": 19,
    "Dic_Δ P-1": 16,
  },
  {
    id: "EXP - Producto 25 <> REEMPLAZO",
    "Ene_Δ€": "11000€",
    Ene_ΔU: 1950,
    "Ene_Share % €": 11,
    "Ene_Δ P-1": 6,
    "Feb_Δ€": "3200€",
    Feb_ΔU: 2300,
    "Feb_Share % €": 9,
    "Feb_Δ P-1": 5,
    "Mar_Δ€": "4200€",
    Mar_ΔU: 2200,
    "Mar_Share % €": 10,
    "Mar_Δ P-1": 7,
    "Abr_Δ€": "5300€",
    Abr_ΔU: 2100,
    "Abr_Share % €": 8,
    "Abr_Δ P-1": 6,
    "May_Δ€": "6200€",
    May_ΔU: 2000,
    "May_Share % €": 12,
    "May_Δ P-1": 9,
    "Jun_Δ€": "7300€",
    Jun_ΔU: 1900,
    "Jun_Share % €": 13,
    "Jun_Δ P-1": 10,
    "Jul_Δ€": "8400€",
    Jul_ΔU: 1800,
    "Jul_Share % €": 14,
    "Jul_Δ P-1": 11,
    "Ago_Δ€": "9500€",
    Ago_ΔU: 1700,
    "Ago_Share % €": 15,
    "Ago_Δ P-1": 12,
    "Sep_Δ€": "10600€",
    Sep_ΔU: 1600,
    "Sep_Share % €": 16,
    "Sep_Δ P-1": 13,
    "Oct_Δ€": "11700€",
    Oct_ΔU: 1500,
    "Oct_Share % €": 17,
    "Oct_Δ P-1": 14,
    "Nov_Δ€": "12800€",
    Nov_ΔU: 1400,
    "Nov_Share % €": 18,
    "Nov_Δ P-1": 15,
    "Dic_Δ€": "13900€",
    Dic_ΔU: 1300,
    "Dic_Share % €": 19,
    "Dic_Δ P-1": 16,
  },
];
